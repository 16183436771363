import { useFormik } from 'formik'
import React, { createContext, useContext, useMemo, useState } from 'react'
import { AccountPageContextTypes } from './AccountPage.context.types'

const AccountPageContext = createContext({} as AccountPageContextTypes)

export const useAccountPage = () => useContext(AccountPageContext)

export const AccountPageContextWrapper: React.FC = ({ children }) => {
  const formik = useFormik({
    initialValues: {
      name: 'Santiago Vicente Souza',
      email: 'santiago@eduplaytion.no',
      phone: '+55 81 999999999',
      city: 'Paulista',
      password: 'password'
    },
    enableReinitialize: false,

    onSubmit: (values) => console.log(values)
  })

  const [isEdition, setEditing] = useState(false)
  const [editPanel, setEditPanel] = useState('')

  const ctxValues = useMemo(
    () => ({ formik, isEdition, setEditing, editPanel, setEditPanel }),
    [formik, isEdition, setEditing, editPanel, setEditPanel]
  )

  return (
    <AccountPageContext.Provider value={ctxValues}>
      {children}
    </AccountPageContext.Provider>
  )
}
