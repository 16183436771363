import { Box } from '@mui/material'
import styled, { css } from 'styled-components'
import { VariantModeTypes } from './UserTable.types'

export const BoxStyles = styled(Box)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  min-height: 3rem;
`

export const UserRowStyles = styled(Box)<VariantModeTypes>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.4rem;
  overflow: hidden;

  padding: 1rem;
  box-shadow: 0 0 2rem #0000000a;

  h4 {
    font-size: ${({ theme }) => theme.typography.body1.fontSize};
  }

  .MuiAvatar-root {
    width: 4.2rem;
    height: 4.2rem;
    background-color: ${({ background, theme }) =>
      background || theme.palette.royalBlue.light};
    color: ${({ theme }) => theme.palette.royalBlue.dark};
    font-weight: ${({ theme }) => theme.typography.fontWeightBold};
    font-size: ${({ theme }) => theme.typography.subtitle1.fontSize};
  }

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `}

  ${({ variant }) =>
    variant === 'datalist' &&
    `
    margin-bottom: 0;
    padding: 0;
    box-shadow: none;
    .MuiAvatar-root {
      width: 3.6rem;
      height: 3.6rem;
    }
  `}

  ${({ variant, theme }) =>
    variant === 'mobile' &&
    css`
    padding: 0;
    box-shadow: none;
    .MuiCheckbox-root {
      color: ${theme.palette.info.main}
      svg {
        font-size: ${theme.typography.h6.fontSize}
      }
    }
    .MuiBox-root {
      display: flex;
      justify-content: center;
      position: relative;
      transform: translateY(20%);

      h4 {
        font-size: ${({ theme }) => theme.typography.body1.fontSize};
        font-weight: ${({ theme }) => theme.typography.fontWeightBold};
        color: ${({ theme }) => theme.palette.royalBlue.dark};
      }
      p {
        font-size: ${({ theme }) => theme.typography.subtitle1.fontSize};
        color: ${({ theme }) => theme.palette.common.black};
      }
      span {
        font-size: ${({ theme }) => theme.typography.subtitle1.fontSize};
        font-weight: ${({ theme }) => theme.typography.fontWeightBold};
        color: ${({ theme }) => theme.palette.common.black};
      }
    }
  }
  `}
`
