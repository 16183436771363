import { apiParent, ApiResponseTypes } from 'services'
import { PaymentDataReturnTypes, PaymentDataTypes } from './Payment.types'

export const getAgreement = async (paymentId: string) => {
  const { data: response } = await apiParent.get<[PaymentDataTypes]>(
    `/payment/agreement/${paymentId}`
  )
  return response
}

export const getPaymentDataByPinCode = async ({
  paymentId,
  pinCode
}: {
  paymentId: string
  pinCode?: string
}) => {
  const { data: response } = await apiParent.get<
    ApiResponseTypes<PaymentDataReturnTypes>
  >(`/payment/${paymentId}/pinCode/${pinCode}`)
  return response.data
}

export default {
  agreement: {
    get: getAgreement
  },
  getPaymentDataByPinCode
}
