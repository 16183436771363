import styled from 'styled-components'

export const PaymentMethodStyles = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.8rem;
  width: 100%;
  cursor: pointer;
  outline: none;
  background: none;

  border: 0.15rem solid ${({ theme }) => theme.palette.grey.light};
  border-radius: 0.4rem;
  font-weight: ${({ theme }) => theme.typography.fontWeightExtraBold};

  svg:not(.MuiSvgIcon-root) {
    width: 10rem;
    margin-left: -1rem;
  }
`
