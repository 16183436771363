import React from 'react'
import { NumetryLogoSVG as numetryLogoSrc } from '@eduplaytion/numetry-ui-kit'
import { LinearProgress, Link } from '@mui/material'

import { HeaderContentStyles, HeaderStyles } from './Header.styles'
import { HeaderPropTypes } from './Header.types'

export const HeaderComponent: React.FC<HeaderPropTypes> = ({
  logoSrc = numetryLogoSrc,
  progress
}) => (
  <HeaderStyles>
    <HeaderContentStyles>
      <Link href={process.env.REACT_APP_CHECKOUT_LOGO_REDIRECT || '#'}>
        <img alt='Numetry Logo' src={logoSrc} />
      </Link>
    </HeaderContentStyles>
    {!!progress && <LinearProgress value={progress} variant='determinate' />}
  </HeaderStyles>
)
