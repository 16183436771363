import { PagesLocaleTypes } from '../locales.types'

const pagesLocaleNn: PagesLocaleTypes = {
  login: {
    or: 'eller',
    aGameBy: 'Et spill av',
    form: {
      validation: {
        email: {
          required: 'E-post er påkrevd'
        },
        password: {
          required: 'Passord er påkrevd'
        }
      }
    }
  },
  kids: {
    title: 'Administrer barn',
    subtitle: 'Se og rediger informasjonen til barna dine.',
    table: {
      yourKids: 'Dine unger',
      addKid: 'Legg til barn',
      name: 'Navn',
      password: 'Passord',
      subscriptionType: 'Type abonnement',
      renewalDate: 'Fornyelsesdato',
      manageKids: 'Administrer barnas'
    }
  },
  account: {
    tab: {
      account: 'Regnskap',
      invoice: 'Betalingshistorikk'
    },
    editAccount: 'Rediger informasjon',
    editForm: {
      username: {
        title: 'Endre brukernavn',
        firstName: 'Fornavn',
        lastName: 'Etternavn'
      },
      email: {
        title: 'Endre e-posten din',
        currentEmail: 'Nåværende epost',
        newEmail: 'Ny epost',
        confirmation: 'Bekreft ny e-post'
      },
      phone: {
        title: 'Bytt mobilnummer',
        newPhone: 'Nytt mobilnummer'
      },
      address: {
        title: 'Endre adressen din',
        streetName: 'Gatenavn',
        zip: 'Postnummer',
        postOffice: 'Postadresse'
      },
      password: {
        title: 'Bytt passord',
        currentPassword: 'Nåværende passord',
        forgotPassword: 'Glemt passordet',
        newPassword: 'Nåværende passord',
        confirmPassword: 'Bekreft passord'
      }
    },
    accountOverview: {
      title: 'Konto-oversikt',
      subtitle: 'Rediger og administrer kontoopplysningene dine',
      form: {
        name: 'Navn',
        email: 'E-post',
        phone: 'Mobilnummer',
        city: 'By',
        password: 'Passord',
        save: 'Lagre informasjon'
      }
    },
    invoices: {
      title: 'Betalingshistorikk',
      subtitle: 'Se gjennom informasjonen du har oppgitt og prøv på nytt.'
    },
    insight: {
      title: 'Innsyn',
      insightText:
        'Du har rett til innsyn i personopplysninger lånekassen har registrert om deg. Ønsker du innsyn i personopplysninger, ber om det her.',
      aboutResponseInTime: 'Om svartid',
      aboutResponseInTimeText:
        'Meldingen om innsyn eller andre rettigheter knyttet til behandlingen av personopplysninger , skal du få svar på rakst, senest innen en måned. Dersom det går lengre tid, vil du få et foreløpig svar med opplysninger om årsaken til forsinkelsen.',
      youMust: 'Dette må du skrive i meldingen din',
      youMustText:
        'Skriv minst 60 tegn om hvad du ønsker innsyn i, for eksempel at det gjelder opplysninger knyttet til behandlingen av en bestemt søknad eller til en bestemt periode. Vennligst skriv så konkret som mulig hva du ønsker innsyn i.'
    },
    inspection: {
      title: 'Be om innsyn',
      message: 'Melding'
    },
    twoFactorPrompt: {
      title: 'Tofaktorautentisering',
      subtitle: 'Skriv inn autentiseringskoden for å fortsette'
    },
    cancelAccount: {
      title: 'Avslutt konto',
      deleteAccount: 'Slett kontoen min',
      cancelAccountText:
        'Ver å trykke på knappen nedenfor vil du bli logget ut, og personopplysninger om deg vil bli slettet etter <strong>30 dager.</strong>'
    }
  },
  checkout: {
    stepsCounter: 'Steg {{ index }} av {{ maxSteps }}',
    steps: {
      contactInformation: {
        title: 'Kontaktinformasjon',
        form: {
          name: {
            label: 'Ditt fulle navn',
            placeholder: 'Fornavn og etternavn'
          },
          email: {
            label: 'Din e-postadresse',
            placeholder: 'E-postadresse',
            validation: {
              shouldBeValidEmail: 'Du må legge inn en gyldig e-postadresse'
            }
          },
          emailConfirmation: {
            label: 'Bekreft e-postadressen din',
            placeholder: 'E-postadresse',
            validation: {
              shouldMatchEmail: 'E-postadressene stemmer ikke overens'
            }
          }
        },
        actionButtons: {
          next: 'Velg medlemskap'
        }
      },
      membershipSelection: {
        title: 'Velg medlemskap',
        cardPlan: {
          header: {
            discount: {
              title: 'Early Bird-tilbud!',
              percentageOfDiscount:
                '<title>{{ percentageOfDiscount }}</title><description>rabatt</description>'
            }
          },
          typeOfPlan: {
            monthly: 'månedlig',
            discount: {
              chip: 'Mest for pengene'
            },
            annual: 'årlig',
            halfannual: 'halvårlig'
          },
          priceOfPlan: {
            description: 'per barn <br />per måned'
          },
          howMuchIsPaid: '{{ price }} betales {{ recurrence, lowercase }}',
          howMuchYouWillSave: 'Du sparer {{ price }}',
          freeTrialText: '{{ days }} dager gratis',
          freeTrialChargeWarning: 'Du blir ikke belastet før <0>{{ date }}</0>',
          howMuchIsPaidFreeTrial: 'deretter {{ price }}/{{ recurrence }}'
        },
        emptySubscriptionPlans:
          'Ingen tilgjengelige planer, vennligst kontakt <strong>vårt salgsteam</strong>!',
        form: {
          total: {
            title: 'Totalsum'
          },
          promotionCode: {
            title: 'Legg til rabattkode',
            success: 'Rabattkoden er registrert',
            invalid: 'Ugyldig kampanjekode',
            input: {
              placeholder: 'Skriv inn din rabattkode'
            },
            button: 'Bruk'
          },
          payment: {
            title: 'Velg betalingsmetode'
          },
          terms: {
            description:
              'Ved å fortsette til betaling og registrere deg, aksepterer du <0>våre bruker- og avtalevilkår</0>, og at personopplysninger i tjenesten vil behandles i henhold til <1>vår personvernerklæring</1>.',
            checkbox: {
              label:
                'Jeg ønsker å motta nyheter, spilloppdateringer og eksklusive tilbud på e-post.'
            }
          }
        },
        onSubmit: {
          error: {
            generic:
              'En uventet feil har oppstått. Vennligst prøv igjen senere eller med en annen betalingsmetode.',
            stripe:
              '{{ error }}. Feil ved å fullføre betalingen, vennligst kontakt vår support-e-post.'
          }
        },
        actionButtons: {
          next: 'Til betaling',
          previous: 'Gå tilbake'
        },
        paymentConfirmation: {
          title: 'Snart ferdig!',
          subtitle: 'Betaling godkjent',
          description:
            'Siste steg! For å aktivere Numetry Premium må du koble til en bruker.',
          button: {
            error: 'Gå tilbake til handlevognen'
          }
        },
        kidAccountConfirmation: {
          title:
            'Er dette kontoen du vil koble Numetry Premium medlemskapet til?',
          username: 'Brukernavn',
          accountConnectButton: 'Ja, koble til denne kontoen',
          connectOtherAccountButton: 'Nei, koble til en annen',
          avatarName: 'Avatarnavn'
        },
        vippsErrorScreen: {
          title: 'Betaling mislyktes',
          subtitle: 'Se gjennom informasjonen du har oppgitt og prøv på nytt.',
          button: 'Prøv på nytt'
        },
        paymentLoading: {
          title: '{{ method }}-betalingen din behandles, vennligst vent.'
        }
      },
      childrenAccount: {
        title: 'Koble en bruker til medlemskapet',
        subViews: {
          doItLater: {
            success: 'Sjekk innboksen din!',
            title: 'Vil du fullføre registrering senere?',
            description:
              'Trykk på knappen nedenfor for å bekrefte at du ikke vil fullføre aktiveringen Du får da en e-post med informasjon om hvordan du kan aktivere Numetry Premium senere. Denne blir sendt til <strong>{{ email }}</strong> .',
            cardPlan: {
              description:
                'Merk at barnet ikke vil ha tilgang til Numetry Premium før du har koblet til en bruker.'
            },
            goBack: 'Gå tilbake',
            go: 'Ja, fullfør senere',
            subViews: {
              confirmation: {
                title: 'E-post sendt til {{ email }}',
                description:
                  'Vi har nå sendt den en e-post med informasjon om hvordan du kan fullføre aktiveringen.'
              }
            }
          },
          methods: {
            existingAccount: {
              title: 'Eksisterende bruker',
              actionButtons: {
                go: 'Koble til eksisterende bruker'
              },
              description:
                'Fyll inn brukernavn og passord for å koble en eksisterende bruker til Numetry Premium.',
              form: {
                username: {
                  label: 'Barnets brukernavn',
                  placeholder: 'Brukernavn'
                },
                password: {
                  label: 'Barnets passord',
                  placeholder: 'Passord'
                },
                showPassword: {
                  label: 'Vis passordet'
                }
              }
            },
            newAccount: {
              title: 'Ny bruker',
              actionButtons: {
                go: 'Koble til eksisterende bruker'
              },
              description:
                'Opprett en ny bruker ver å fylle inn brukernavn og passord. Den vil automatisk få tilgang til Numetry Premium.',
              form: {
                childFirstName: {
                  label: 'Barnets fornavn (valgfritt)',
                  placeholder: 'Navn'
                },
                loginInformationDivider: {
                  title: 'Påloggingsinformasjon',
                  description:
                    'Velg et brukernavn og passord som er lett for barnet å huske.'
                },
                username: {
                  label: 'Barnets brukernavn',
                  placeholder: 'Brukernavn'
                },
                password: {
                  label: 'Barnets passord',
                  placeholder: 'Passord',
                  validation: {
                    min: 'Min passordlengde'
                  }
                },
                passwordConfirmation: {
                  label: 'Gjenta barnets passord',
                  placeholder: 'Gjenta passord',
                  validation: {
                    shouldMatchPassword:
                      'Passordbekreftelse er ikke lik passord'
                  }
                },
                showPassword: {
                  label: 'Vis passordet'
                }
              },
              onSubmit: {
                usernameAlreadyTaken:
                  'Brukernavnet er allerede tatt. Forslag: {{ usernameSuggestion }}',
                invalidKidAccount: 'Ugyldig barnekonto'
              }
            }
          },
          gameChild: {
            title:
              'Er deter kontoen du vil koble Numetry Fritid-medlemspak til?',
            actionButtons: {
              connectToThisAccount: 'Ja, koble til denne kontoen',
              connectToAnotherOne: 'Nei, koble til en annen'
            }
          }
        },
        actionButtons: {
          doItLater: 'Gjør det senere',
          go: 'Koble til eksisterende bruker',
          goBack: 'Gå tilbake',
          next: 'Neste'
        },
        components: {
          confirmation: {
            title: 'Alt klart!',
            subtitle: 'Brukeren er nå koblet til Numetry Premium.',
            buttonMobile: 'Spill Numetry i iPad-appen',
            buttonDesktop: 'Spill nå',
            feedbackButton: `Vi er nysgjerrige og vil gjerne høre fra deg. <0>Har du et øyeblikk?</0>`,
            goBack: 'Tilbake til nettsiden',
            supportedPlatforms:
              'Numetry kan foreløpig kun spilles på iPad and PC.',
            getItFromAppStore: `Hvis du ikke har appen. <0>Få den fra appbutikken.</0>`
          },
          feedback: {
            title: 'Har du et øyeblikk?',
            subtitle: 'Vi er nysgjerrige og vil gjerne høre fra deg.',
            submitButton: 'Send inn',
            submitButtonSuccess: 'Takk for tilbakemeldingen din!',
            reasonChooseUs: {
              placeholder: 'Hva var hovedgrunnen til at du valgte oss',
              options: [
                'Lek og læring',
                'Bli bedre i matematikk',
                'Ekstra hjelpemiddel i matematikk',
                'Motivasjon for matematikk',
                'Positiv skjermtid',
                'Anbefalinger og omtaler'
              ]
            },
            hearAboutUs: {
              placeholder: 'Hvor har du hørt om oss?',
              options: [
                'Sosiale medier',
                'Barnet mitt',
                'Venner eller familie',
                'På skolen til barnet',
                'Nyhetsbrev',
                'Nettavis',
                'Fant det selv'
              ]
            }
          }
        }
      }
    },
    discountLandingPage: {
      header1: {
        title: 'Eksklusivt tilbud: {{ discount }} rabatt!',
        description:
          'Som en del av vår reise mot å levere den beste mattelæringen til de som trenger det, har vi for tiden et eksklusivt tilbud på Numetry Premium.'
      },
      whatIsNumetry: {
        title: 'Hva er Numetry?',
        description:
          'Numetry gjør læring til lek, og lærer barna matte gjennom spill. Mattespillet er elsket av tusenvis av elever og lærere på skoler over hele Norge.'
      },
      listItems: [
        'Barnet ditt kommer til å elske matte',
        'Hjelp underveis',
        'Alltid tilgjengelig',
        'Trygge omgivelser'
      ],
      cta: {
        title: 'La oss starte læringen!',
        button: 'BESTILL'
      },
      metrics: {
        title: 'Godkjent av den norske skolen',
        students: {
          title: 'elever',
          value: '150 000+'
        },
        teachers: {
          title: 'lærere',
          value: '10 000+'
        },
        schools: {
          title: 'skoler',
          value: '1800+'
        }
      },
      footer: {
        numetryColumn: {
          title: 'Numetry',
          playNumetry: 'Spill Numetry',
          aboutUs: 'Om oss',
          ourTeachers: 'Våre lærere',
          blog: 'Blogg',
          invest: 'Invester',
          jobs: 'Jobb'
        },
        helpColumn: {
          title: 'Hjelp',
          searchField: 'Bruk søkefeltet, eller let på <0>vår FAQ</0>',
          searchFieldPlaceholder: 'Søk i FAQ-en',
          contact: 'Kontakt oss via chat eller e-post <0>hei@numetry.no</0>'
        }
      }
    }
  },
  oneNok: {
    title: 'Prøv Numetry for kun 1 kr',
    subtitle:
      'Matematikkspillet Numetry gjør læring til lek for barn mellom 8 til 12 år.',
    cta: {
      button: 'Prøv 1 måned for 1 kr',
      description:
        'Første måned for kun 1 kr. Deretter 59 kr pr. mnd. Ingen binding.'
    },
    keyFeatures: {
      title: 'En helt ny måte å lære matte på',
      card: {
        title: 'Mattespill',
        description:
          'I Numetry tar barna del i en redningsaksjon i verdensrommet. Kun matteferdighetene kan redde dagen.'
      },
      items: [
        'Moderne læringsapp',
        'For barn 8 til 12 år',
        'Følger norsk pensum',
        'Positiv skjermtid',
        'Trygt og sikkert'
      ]
    },
    premiumFeatures: {
      title: 'Hvorfor velge Numetry Premium?',
      content: {
        title: 'Tilgang til alt innhold',
        description: 'Over 100 ulike matteøvelser'
      },
      items: {
        title: 'Eksklusive belønninger',
        description: 'Motiverende læring'
      },
      trophy: {
        title: 'Premium-prestasjoner',
        description: 'Utfordre mateferdighetene'
      },
      unlock: {
        title: 'Tilgang til nytt innhold',
        description: 'Månedlige spilloppdateringer'
      }
    },
    floatingBar: {
      text: 'Tilbud:',
      button: 'Prøv for 1 kr'
    }
  }
}

export default pagesLocaleNn
