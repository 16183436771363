import React, { useEffect } from 'react'
import { useMediaQuery, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Outlet, useSearchParams } from 'react-router-dom'

import { MenuOutlined, MenuTwoTone } from '@mui/icons-material'
import { useFeedback, useLayout } from '@context'
import { useDevice } from '@hooks'

import { FCWithChildren } from '@types'
import {
  HeaderComponent,
  AsideComponent,
  SkipLinkComponent,
  BottomNavigationComponent
} from '../../components'
import {
  LayoutContentStyles,
  LayoutMainStyles,
  LayoutStyles
} from './DashboardLayout.styles'
import { DashboardLayoutPropTypes } from './DashboardLayout.types'

export const DashboardLayout: FCWithChildren<DashboardLayoutPropTypes> = ({
  isAsideHidden = false,
  isHeaderHidden = false,
  navItems,
  user
}) => {
  const { t } = useTranslation()
  const { dialog } = useFeedback()
  const [urlParams] = useSearchParams()

  const status = urlParams.get('status')
  const message = urlParams.get('message') || undefined
  const action = urlParams.get('action')
  const actionLabel = urlParams.get('action_label')

  const hasAction = action && actionLabel

  const isMobileScreen = useMediaQuery(useTheme().breakpoints.down('md'))
  const isDesktopScreen = useMediaQuery(useTheme().breakpoints.up('md'))

  const { isMobileDevice } = useDevice()
  const { asideMenuHandlers } = useLayout()

  useEffect(() => {
    if (status === 'login_failed') {
      dialog.show({
        content: {
          title: t('general.status.error.somethingWentWrong'),
          subtitle: message || undefined,
          footer:
            (hasAction && {
              visible: true,
              align: 'flex-end',
              buttons: [
                {
                  value: actionLabel,
                  onClick: () => {
                    window.location.href = action
                  },
                  variant: 'contained'
                }
              ]
            }) ||
            undefined
        },
        status: 'error'
      })
    }
  }, [urlParams])

  return (
    <LayoutStyles>
      <SkipLinkComponent />
      {!isHeaderHidden && (
        <HeaderComponent
          user={user}
          items={[
            {
              id: 0,
              name: 'main-menu',
              title: {
                main: 'navigation.mainMenu.title'
              },
              to: null,
              onClick: asideMenuHandlers.onToggle,
              icon: MenuOutlined,
              filledIcon: MenuTwoTone,
              hideInLeftNavigation: true,
              hideElement: !isMobileDevice
            },
            ...navItems
          ]}
        />
      )}
      <LayoutContentStyles>
        {isDesktopScreen && !isAsideHidden && (
          <AsideComponent items={navItems} />
        )}
        <LayoutMainStyles>
          <Outlet />
        </LayoutMainStyles>
      </LayoutContentStyles>
      {isMobileScreen && <BottomNavigationComponent items={navItems} />}
    </LayoutStyles>
  )
}

export * from './DashboardLayout.types'
