import React from 'react'
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon
} from '@mui/material'
import { Logout, Menu } from '@mui/icons-material'

import { NumetrySkoleLogoSVG } from '@assets'
import { useDisclosure } from '@hooks'
import { ROUTES } from '@router'

import { FCWithChildren } from '@types'
import { LeftNavigationPropTypes } from './LeftNavigation.types'
import { LanguageSelectorComponent } from '../languageSelector/LanguageSelector'
import { LeftNavigationItem } from './components'

export const LeftNavigationComponent: FCWithChildren<
  LeftNavigationPropTypes
> = ({ items, isHeaderOverflow }) => {
  const { onToggle, isOpen, onClose } = useDisclosure()

  const TOP_ITEMS = items.filter(
    (item) => !item.hideElement && !item.hideInLeftNavigation
  )

  const renderMenuButton = () => (
    <IconButton onClick={onToggle}>
      <Menu />
    </IconButton>
  )

  return (
    <>
      {renderMenuButton()}
      <Drawer
        open={isOpen}
        onClose={onClose}
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: 250
          }
        }}
      >
        <Box
          role='presentation'
          display='flex'
          flexDirection='column'
          justifyContent='space-between'
          height='100%'
          paddingX={2}
        >
          <div>
            <List>
              <ListItem disablePadding>
                <ListItemButton onClick={onToggle}>
                  <ListItemIcon>
                    <Menu />
                  </ListItemIcon>
                  <img
                    src={NumetrySkoleLogoSVG}
                    alt='Numetry Logo'
                    width={80}
                    height='auto'
                  />
                </ListItemButton>
              </ListItem>
            </List>
            <Divider />
            <List>
              {TOP_ITEMS.map((item) => (
                <LeftNavigationItem
                  item={item}
                  key={item.id}
                  onClose={onClose}
                />
              ))}
            </List>
          </div>
          {isHeaderOverflow && (
            <List>
              <Divider />
              <List>
                <LanguageSelectorComponent
                  sx={{ justifyContent: 'flex-start', width: '100%' }}
                />
              </List>
              <Divider />
              <List>
                <LeftNavigationItem
                  item={{
                    to: `/${ROUTES.AUTH}/${ROUTES.LOGOUT}`,
                    icon: Logout,
                    title: {
                      main: 'auth.logout'
                    },
                    id: items.length + 1,
                    name: 'logout'
                  }}
                  onClose={onClose}
                />
              </List>
            </List>
          )}
        </Box>
      </Drawer>
    </>
  )
}
