import { AuthLocaleTypes } from '../locales.types'

const authLocaleBo: AuthLocaleTypes = {
  login: 'Logg inn',
  logout: 'Logg ut',
  signup: 'Registrer deg',
  signout: 'Logg ut',
  withFeide: 'Logg inn med Feide',
  email: 'E-post',
  emailPlaceholder: 'døme@email.com',
  password: 'Passord',
  forgotYourPassword: 'Glemt passordet?',
  enterYourEmail: 'Skriv inn email adressen din',
  sendPasswordResetLink: 'Send lenke for tilbakestilling av passord'
}

export default authLocaleBo
