import { api } from 'services'
import { ValidateUsernameResponseTypes } from './Fusionauth.types'

const validateUsername = async (username: string) => {
  const { data: response } = await api.get<ValidateUsernameResponseTypes>(
    `/fusionauth/validateUsername/${username}`
  )

  return response.data
}

export default validateUsername
