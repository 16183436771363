import React, { lazy } from 'react'
import { Route } from 'react-router-dom'
import { ROUTES } from 'router'

const OneNokPage = lazy(() => import('views/oneNok/OneNok'))

export const renderOneNokRoute = () => {
  const { ONENOK } = ROUTES

  return (
    <Route path={ONENOK}>
      <Route index element={<OneNokPage />} />
    </Route>
  )
}
