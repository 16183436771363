import styled from 'styled-components'
import { Box } from '@mui/material'

export const DualDropArrowStyles = styled(Box)`
  width: 0.8rem;
  height: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    font-size: ${({ theme }) => theme.typography.body2.fontSize};
    position: relative;
  }
`
