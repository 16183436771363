export const ROUTES = {
  // Base
  BASE_ROOT: '/',
  UNKNOWN: '*',

  // [Auth] - Views and subViews
  AUTH: 'auth',
  LOGIN: 'login',
  LOGOUT: 'logout',

  // Actions
  EDIT: 'edit',

  // Params
  ID: ':id',
  TYPE: ':type'
} as const

export type RouteTypes = (typeof ROUTES)[keyof typeof ROUTES] | 'default'
