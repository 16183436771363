export default {
  mainMenu: {
    title: 'Main Menu',
    titleMobile: 'More'
  },
  account: {
    title: 'Account'
  },
  kids: {
    title: 'Kids'
  },
  menu: {
    title: 'Menu'
  },
  createAssignment: {
    title: {
      normal: 'Create assignment',
      edit: 'Editing {{ name }} assignment'
    },
    titleMobile: 'Games',
    subtitle:
      'Select new missions to add to the assignment. Know more about each game by clicking on <“More Info”> on card. You can also start to create an assignment by selecting games below.',
    alternativeSubtitle: null
  },
  assignments: {
    title: 'Assignments',
    newTitle: 'Create assignment',
    newSubtitle:
      'Select the activities that will compose the assignment, then, press next. You can learn more about the games by clicking “more info” on the cards.'
  },
  library: {
    title: 'Library',
    subTitle: 'Here you can se our available games and read more about them'
  },
  reports: {
    title: 'Reports'
  },
  offlineContent: {
    title: 'Offline content',
    titleMobile: 'Content'
  },
  students: {
    title: 'Students',
    newTitle: 'My students'
  },
  license: {
    title: 'License',
    tabs: {
      manage: {
        title: 'Manage License'
      },
      buy: {
        title: 'Buy License'
      }
    }
  },
  helpAndSupport: {
    title: 'Help & Support'
  },
  settings: {
    title: 'Account settings'
  },
  customerOverview: {
    title: 'Customer Overview'
  },
  feedback: {
    title: 'Give us a feedback',
    popover: {
      title: 'What do you think of Numetry School?',
      input: {
        placeholder: 'Feel free to elaborate'
      },
      moods: {
        bad: 'Bad',
        dissatisfied: 'Dissatisfied',
        neutral: 'Neutral',
        satisfied: 'Satisfied',
        verySatisfied: 'Very Satisfied'
      },
      button: {
        text: 'Submit'
      }
    }
  }
}
