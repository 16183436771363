import styled from 'styled-components'

export const HeaderStyles = styled.nav`
  width: 100%;
  box-shadow: 0 0.2rem 0.6rem ${({ theme }) => theme.palette.common.black}29;
  position: sticky;
  top: 0;
  flex: 0 1 auto;
  z-index: ${({ theme }) => theme.zIndex.appBar};
`

export const HeaderContentStyles = styled.nav`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 2rem 0;
  background: ${({ theme }) => theme.palette.common.white};
`
