import React from 'react'
import { Typography } from '@mui/material'
import { Trans } from 'react-i18next'
import { FCWithChildren } from '@types'
import { PropsResponseTemplate } from '../../PopUpModal.types'
import { StyledImage, ResponseModalStyles } from './ResponseModal.styles'
import { PopUpContentStyles } from '../../PopUpModal.styles'

export const ResponseModalTemplate: FCWithChildren<PropsResponseTemplate> = ({
  ...data
}) => {
  return (
    <PopUpContentStyles>
      <Trans>
        <ResponseModalStyles
          transColor={data.transColor}
          textColor={data.textColor}
          align={data.align}
        >
          {Boolean(data.image) && (
            <StyledImage alt={data.title} src={data.image} />
          )}
          {Boolean(data.subtitle) && <Typography>{data.subtitle}</Typography>}
          {Boolean(data.content) && (
            <Typography fontSize='subtitle1' alignItems='flex-start' mb={1}>
              {data.content}
            </Typography>
          )}
        </ResponseModalStyles>
      </Trans>
    </PopUpContentStyles>
  )
}
