import styled, { css } from 'styled-components'
import { Tabs } from '@mui/material'
import { TabsPropTypes } from './Tabs.types'

export const TabsStyles = styled(Tabs)<TabsPropTypes>`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.common.white};
  cursor: pointer;

  ${({ variant }) =>
    variant === 'fullWidth' &&
    css`
      .MuiTabs-flexContainer {
        justify-content: space-evenly;
      }

      .MuiButtonBase-root {
        flex: 1;
      }
    `}

  ${({ isStickedOnTop }) =>
    isStickedOnTop &&
    css`
      position: sticky;
      top: 0;
      z-index: 4;
    `}
`
