import { Menu, Box } from '@mui/material'
import styled, { css } from 'styled-components'
import { MenuStylesPropTypes, PopoverModeTypes } from './Popover.types'

export const PopoverStyles = styled(Box)<{ mode: PopoverModeTypes }>`
  .MuiIconButton-root {
    border-radius: 0;
    display: flex;
    padding: 0.4rem 0.8rem;
    svg {
      font-size: ${({ theme }) => theme.typography.h5.fontSize};
    }
    ${({ mode }) =>
      mode === 'language' &&
      css`
        img {
          width: 2.4rem;
        }
      `}
  }
`

export const MenuStyles = styled(Menu)<MenuStylesPropTypes>`
  .MuiListItemIcon-root {
    svg {
      color: ${({ theme }) => theme.palette.royalBlue.dark};
      font-size: ${({ mode }) =>
        mode === 'default'
          ? ({ theme }) => theme.typography.body1.fontSize
          : '2.4rem'};
    }
  }
  .MuiListItemText-root {
    .MuiTypography-root {
      color: ${({ theme }) => theme.palette.royalBlue.dark};
      font-size: ${({ theme }) => theme.typography.body1.fontSize};
    }
  }

  ${({ mode }) =>
    mode === 'language' &&
    css`
      left: 2rem;
    `}

  ${({ hideElement }) =>
    hideElement &&
    css`
      display: 'none';
    `}


  ${({ mode, theme }) =>
    (mode === 'header' || mode === 'language') &&
    css`
      top: 2.6rem;
      .MuiListItemText-root {
        padding: 0.5rem 0.8rem 0 0;
        .MuiTypography-root {
          font-weight: ${mode === 'header'
            ? theme.typography.fontWeightBold
            : theme.typography.fontWeightNormal};
          font-size: 1.4rem;
        }
      }
      .MuiPaper-root {
        box-shadow: 0 0.18rem 0.37rem
          ${({ theme }) => theme.palette.common.black}29;
        border-radius: 1rem;

        img {
          width: 3rem;
          margin-right: 1rem;
        }
        .MuiSvgIcon-root {
          font-size: ${({ theme }) => theme.typography.h5.fontSize};
        }
      }
    `}
`
