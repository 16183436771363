import { RefObject, useEffect, useState } from 'react'

export const useOverflow = <T extends Element = Element>(
  elementRef: RefObject<T>,
  callback?: (hasOverflow?: boolean) => void
) => {
  const [isOverflow, setIsOverflow] = useState(false)
  const node = elementRef.current

  useEffect(() => {
    if (node) {
      const hasOverflow = node.scrollWidth > node.clientWidth

      setIsOverflow(hasOverflow)

      if (callback) callback(hasOverflow)
    }
  }, [callback, elementRef, window.innerWidth])

  return isOverflow
}
