import styled from 'styled-components'
import { Tab, TabProps } from '@mui/material'

export const TabStyles = styled(Tab)<TabProps>`
  padding: 1.6rem 4rem;

  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  text-decoration: none;
  text-transform: none;

  transition: all 0.2s;
`
