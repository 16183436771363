import { useFormik } from 'formik'
import React, { createContext, useContext, useMemo } from 'react'
import {
  CreateStepContextTypes,
  CreateStepFormTypes
} from './CreateStep.context.types'
import CreateSteps from '../steps'

const CreateStepContext = createContext({} as CreateStepContextTypes)

export const useCreateStep = () => useContext(CreateStepContext)

export const CreateStepContextProvider: React.FC = ({ children }) => {
  const formik = useFormik({
    initialValues: {
      user: {
        username: '',
        password: '',
        passwordConfirmation: ''
      },
      plan: undefined
    } as CreateStepFormTypes,
    onSubmit: (values) => console.log(values)
  })

  const steps = useMemo(() => {
    if (formik.values.type === 'link') {
      return CreateSteps.link
    }
    return CreateSteps.create
  }, [formik, CreateSteps])

  const ctxValue: CreateStepContextTypes = useMemo(
    () => ({
      formik,
      steps
    }),
    [formik, steps]
  )

  return (
    <CreateStepContext.Provider value={ctxValue}>
      {children}
    </CreateStepContext.Provider>
  )
}
