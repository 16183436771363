export const ROUTES = {
  // Base
  BASE_ROOT: '/',
  UNKNOWN: '*',

  // Auth
  AUTH: 'auth',
  LOGIN: 'login',
  LOGOUT: 'logout',
  TWO_FACTOR: '2fa',
  FORGOT_PASSWORD: 'forgot-password',

  // Checkout
  CHECKOUT: 'checkout',
  CHECKOUT_STEPS: 'steps',
  PAYMENT_CONFIRMATION: 'payment-confirmation', // -> can be only confirmation
  CONTACT_INFORMATION: 'contact-information',
  MEMBERSHIP_SELECTION: 'membership-selection',
  LINK_CHILDREN: 'link-children',
  GAME_CHILD: 'game-child',
  EXISTING_ACCOUNT: 'existing-account',
  NEW_ACCOUNT: 'new-account',
  DO_IT_LATER: 'do-it-later',
  CONFIRMATION: 'confirmation',
  METHODS: 'methods',
  FEEDBACK: 'feedback',
  ONENOK: 'onenok',

  // Pages
  ACCOUNT: 'account',
  KIDS: 'kids',
  DETAILS: 'details',

  // Actions
  EDIT: 'edit',
  ADD: 'add',

  // Params
  ID: ':id',
  TYPE: ':type'
} as const

export type RouteTypes = (typeof ROUTES)[keyof typeof ROUTES] | 'default'
