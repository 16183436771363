import styled from 'styled-components'

export const ItemStyles = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.8rem;
  cursor: pointer;
  outline: none;
  background: none;

  border: 0.15rem solid ${({ theme }) => theme.palette.grey.light};
  border-radius: 0.4rem;
`
