import subscriptionPlanIdMethods from './SubscriptionPlans.id'
import subscriptionPlanMethods from './SubscriptionPlans'
import subscriptionPromotionCodeIdMethods from './SubscriptionPlans.promotionCode.id'

export const SubscriptionPlanServices = {
  ...subscriptionPlanMethods,
  id: subscriptionPlanIdMethods,
  promotionCode: {
    id: subscriptionPromotionCodeIdMethods
  }
}

export * from './SubscriptionPlans.types'
