import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { from } from '@theme'

export const AsideItemStyles = styled(NavLink)<{
  isCollapsed?: boolean
}>``

export const AsideContainerStyles = styled.div<{
  isActive?: boolean
  isCollapsed?: boolean
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0;
  border: none;
  padding: 2rem;

  color: ${({ theme }) => theme.palette.text.primary};

  transition: all 0.2s ease-in-out;

  :hover {
    background-color: ${({ theme }) => theme.palette.grey.A400};
  }

  p {
    opacity: ${({ isCollapsed }) => (!isCollapsed ? 1 : 0)};
    text-align: center;
    width: max-content;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      color: ${({ theme }) => theme.palette.primary.main};
    `}

  ${from.md} {
    flex-direction: row;
    gap: 2rem;
    align-items: center;
    justify-content: flex-start;

    border-left: 0.3rem solid transparent;
    width: 100%;

    p {
      text-align: left;
    }

    ${({ isActive }) =>
      isActive &&
      css`
        border-color: currentColor;
      `}

    ${({ isCollapsed }) =>
      isCollapsed &&
      css`
        justify-content: center;
        gap: 0;
        p {
          display: none;
        }
      `};
  }
`
