import React, { useEffect } from 'react'
import { Box, LinearProgress, Stack, Typography } from '@mui/material'
import { Button, StepperComponent } from '@components'
import { useStepper } from '@context'
import { useTranslation } from 'react-i18next'
import { FCWithChildren } from '@types'
import { PropsStepperTemplateProps } from '../../PopUpModal.types'
import {
  FooterContainerStyles,
  ResponseModalStyles
} from './StepperModal.styles'
import { PopUpContentStyles } from '../../PopUpModal.styles'

export const StepperModalTemplate: FCWithChildren<
  PropsStepperTemplateProps
> = ({ open, preventGoBack, onClose }) => {
  const {
    actualStep,
    nextStep,
    maxSteps,
    canProceed,
    canGoBack,
    setStep,
    prevStep
  } = useStepper()

  const { t } = useTranslation()

  useEffect(() => {
    if (!open) setStep(0)
  }, [open])
  return (
    <>
      <PopUpContentStyles>
        <ResponseModalStyles>
          <StepperComponent />
        </ResponseModalStyles>
      </PopUpContentStyles>
      <FooterContainerStyles>
        <Box>
          <Typography>
            {t('components.stepper.modal.footer', {
              actual: actualStep + 1,
              max: maxSteps + 1
            })}
          </Typography>
          <LinearProgress
            value={(100 * (actualStep + 1)) / (maxSteps + 1)}
            color='primary'
            variant='determinate'
          />
        </Box>
        <Stack display='flex' direction='row' spacing={2}>
          {!preventGoBack ? (
            <Button
              variant='outlined'
              onClick={() => prevStep()}
              disabled={!canGoBack}
            >
              {t('general.actions.previous')}
            </Button>
          ) : (
            <Button variant='outlined' onClick={() => onClose()}>
              {t('general.actions.cancel')}
            </Button>
          )}
          <Button
            onClick={() => nextStep()}
            color='primary'
            variant='contained'
            disabled={!canProceed}
          >
            {t('general.actions.next')}
          </Button>
        </Stack>
      </FooterContainerStyles>
    </>
  )
}
