import { Dialog, DialogContent } from '@mui/material'
import styled from 'styled-components'

// Filters Bar Mobile
export const DialogStyles = styled(Dialog)<{ variant?: 'mid' }>`
  form {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  ${({ variant }) =>
    variant === 'mid' &&
    `
    z-index: 3;
    & .MuiDialog-container {
      max-width: none;
      align-items: flex-end;

      .MuiPaper-root {
        height: calc(100% - 14.5rem);
        width: 100%;
        margin: 0;
        border-radius: 0;

        .MuiDialogContent-root {
          margin: 0;
        }
      }
    }
  `}
`

export const DialogContentStyles = styled(DialogContent)`
  padding: 2rem;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  .MuiFormControl-root {
    margin-top: 1.6rem;
    max-width: 100%;
    .MuiSelect-select {
      width: 100%;
    }
  }
`
