import styled from 'styled-components'
import { Popover, Box } from '@mui/material'

export const PopoverStyles = styled(Popover)`
  display: flex;
  flex-direction: column;
  pointer-events: none;
  .MuiPaper-root {
    transform: translate(2rem);
  }
`

export const PopoverDescriptionStyles = styled(Box)`
  padding: 0 0.8rem;
  margin-bottom: 0.5rem;
  width: 21.5rem;

  p {
    color: ${({ theme }) => theme.palette.royalBlue.dark};
    font-size: 1rem;
    word-wrap: break-word;
  }
`

export const PopoverHeaderStyles = styled(Box)`
  width: 21.5rem;
  color: ${({ theme }) => theme.palette.info.main};
  padding: 0.8rem;

  svg {
    font-size: ${({ theme }) => theme.typography.subtitle1.fontSize};
  }
  p {
    font-size: ${({ theme }) => theme.typography.subtitle2.fontSize};
    font-weight: ${({ theme }) => theme.typography.fontWeightBold};
    margin-left: 0.5rem;
  }

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`
