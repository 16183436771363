import { ButtonBase } from '@mui/material'
import styled, { css } from 'styled-components'

const borderRadius = '1.6rem'

export const SubscriptionPlanStyles = styled(ButtonBase)<{
  isSelected?: boolean
}>`
  border: 0.2rem solid ${({ theme }) => theme.palette.primary[50]};
  display: flex;
  flex-direction: column;
  border-radius: ${borderRadius};
  position: relative;

  transition: all 0.1s ease;

  ${({ isSelected, theme }) =>
    isSelected &&
    css`
      border: 0.2rem solid ${theme.palette.primary.main};
    `};
`

export const SubscriptionPlanPromotionStyles = styled.div`
  display: flex;
  padding: 1.6rem;
  width: 100%;

  border-top-left-radius: calc(${borderRadius} - 0.2rem);
  border-top-right-radius: calc(${borderRadius} - 0.2rem);

  background-color: ${({ theme }) => theme.palette.secondary.main};
`

export const SubscriptionPlanDiscountStyles = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: auto;
  z-index: 1;

  transform: translate(-1.6rem, -1.6rem);

  aspect-ratio: 1;

  padding: 0.8rem;
  border-radius: 100%;

  display: flex;
  gap: 0.4rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: ${({ theme }) => theme.palette.secondary.main};

  box-shadow: ${({ theme }) => theme.shadows[6]};
`
