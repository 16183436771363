import styled from 'styled-components'

export const DialogHeaderStyles = styled.header`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const HeaderStyles = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 2.4rem;
  width: 100%;
`

export const SubtitleStyles = styled.div`
  padding: 1.6rem 2.4rem;
  strong {
    color: ${({ theme }) => theme.palette.secondary.main};
    font-size: inherit;
  }
`
