type AnyObject = Record<string, never>

export const hasSameKeys = (obj1: AnyObject, obj2: AnyObject): boolean => {
  if (obj1 === null || obj2 === null) {
    return false
  }

  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)

  // Check if both objects have the same number of keys
  if (keys1.length !== keys2.length) {
    return false
  }

  // Check if the keys in obj1 exist in obj2 and vice versa
  const areKeysEqual = (key: string) =>
    keys2.indexOf(key) !== -1 && obj2[key] !== undefined

  const areValuesEqual = (key: string) =>
    typeof obj1[key] === 'object' && typeof obj2[key] === 'object'
      ? hasSameKeys(obj1[key], obj2[key])
      : true

  return keys1.every(areKeysEqual) && keys1.every(areValuesEqual)
}
