import React, { useRef, useState } from 'react'
import {
  Box,
  Divider,
  IconButton,
  SvgIcon,
  Typography,
  ClickAwayListener,
  Popper
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Close, KeyboardArrowDownRounded } from '@mui/icons-material'

import { useActionState } from '@hooks'
import { SearchComponent, Button } from '@components'

import { capitalizeFirstLetter } from '@utils'

import { FCWithChildren } from '@types'
import { MenuItemComponent } from './components'

import {
  SelectStyles,
  RenderCustomValueStyles,
  PopperContentStyles,
  PopperListStyles,
  ButtonContainerStyles
} from './Select.styles'
import { SelectPropTypes, OptionTypes } from './Select.types'

export const SelectComponent: FCWithChildren<SelectPropTypes> = ({
  name,
  options,
  placeholder,
  onChange,
  defaultOption = { label: placeholder, value: '' },
  onClearClick,
  error,
  success,
  focused,
  hovered,
  enableArrowMark,
  enableSearch,
  mode = 'default',
  searchPlaceholder,
  fullWidth,
  ...rest
}) => {
  const { t } = useTranslation()

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const [searchedField, setSearchedField] = useState<string | null>(null)
  const [selected, setSelected] = useState<OptionTypes>(defaultOption)

  const buttonContainerRef = useRef<HTMLDivElement>(null)
  const selectContainerRef = useRef<HTMLDivElement | null>(null)
  const hoverRef = useRef<HTMLDivElement>(null)

  const { hasError, hasFocus, hasHover, hasSuccess } = useActionState(
    { error, focused, hovered, success },
    buttonContainerRef,
    hoverRef
  )

  const handleOpen = () => setAnchorEl(selectContainerRef.current)
  const handleClose = () => setAnchorEl(null)
  const handleToggle = () => (anchorEl ? handleClose() : handleOpen())

  const filteredList = options.filter((option) => {
    const label = option.label.toLowerCase()
    const search = (searchedField || '').toLowerCase()
    return label.indexOf(search) > -1
  })

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <SelectStyles
        ref={selectContainerRef}
        selectedLength={selected.label.length}
        clientWidth={Number(selectContainerRef.current?.offsetWidth)}
        fullWidth={fullWidth}
        data-testid='SelectComponent'
      >
        <ButtonContainerStyles
          error={hasError}
          success={hasSuccess}
          hovered={hasHover}
          focused={hasFocus}
          ref={buttonContainerRef}
          {...rest}
        >
          <Button
            variant='text'
            fullWidth
            sx={{ px: 1 }}
            color='grey'
            onClick={handleToggle}
            data-testid='SelectButton'
            endIcon={selected.value ? undefined : <KeyboardArrowDownRounded />}
          >
            <RenderCustomValueStyles
              textAlign='left'
              width='inherit'
              noWrap
              value={selected.value}
              mode={mode}
            >
              {capitalizeFirstLetter(selected.label || placeholder)}
            </RenderCustomValueStyles>
          </Button>
          {selected.value && (
            <IconButton
              sx={{ borderRadius: 0 }}
              onClick={(e) => {
                if (onClearClick) onClearClick(e)
                setSelected(defaultOption)
              }}
            >
              <SvgIcon color='inherit' fontSize='small' component={Close} />
            </IconButton>
          )}
        </ButtonContainerStyles>
        <Popper
          disablePortal
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          placement='bottom-start'
          sx={{
            zIndex: ({ zIndex }) => zIndex.mobileStepper,
            width: 'inherit',
            maxWidth: 'inherit'
          }}
        >
          <PopperContentStyles>
            {enableSearch && (
              <>
                <Box px='1.2rem' py={1}>
                  <SearchComponent
                    value={searchedField}
                    name={`search-${name}`}
                    onChange={(e) => setSearchedField(e.currentTarget.value)}
                    onClearClick={() => setSearchedField('')}
                    fullWidth
                    autoFocus
                    placeholder={
                      searchPlaceholder ||
                      t('components.search.placeholder.default')
                    }
                  />
                </Box>
                <Divider />
              </>
            )}
            <PopperListStyles>
              {filteredList.map((item, i) => (
                <MenuItemComponent
                  key={`${item.label}-${i.toString()}`}
                  enableArrowMark={enableArrowMark}
                  item={item}
                  isSelected={item.value === selected.value}
                  onClick={() => {
                    if (item.value !== selected.value) {
                      setSelected(item)
                      onChange({ ...item, name })
                    }
                    setSearchedField('')
                    handleClose()
                  }}
                />
              ))}
              {!filteredList.length && (
                <Typography textAlign='center' color='grey' py={1.5}>
                  {t('general.noMoreResults')}
                </Typography>
              )}
            </PopperListStyles>
          </PopperContentStyles>
        </Popper>
      </SelectStyles>
    </ClickAwayListener>
  )
}

export * from './Select.types'
