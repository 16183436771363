import { Box, ButtonBase, IconButton, Stack } from '@mui/material'
import styled, { css } from 'styled-components'

export const AccountTabContainerStyles = styled.div<{
  isEdition: boolean
  editPanel?: string
}>`
  display: flex;
  flex-wrap: wrap;
  flex-basis: 0;
  flex-direction: row;
  background: ${({ theme }) => theme.palette.common.white};

  .FormGroupItem-root {
    transition: all 0.2s ease;
  }
  .FormGroupItem-root[name='${({ editPanel }) => editPanel}'] {
    border-right: 0.2rem solid
      ${({ isEdition, theme: { palette } }) =>
        isEdition ? palette.primary.main : palette.common.white};
  }
`

export const ButtonGroupStyles = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding: 1.5rem;
  gap: 2rem;
  transition: all 0.25s ease;
`

export const TextAreaStyles = styled.textarea`
  width: 100%;
  height: 20rem;
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
  border-color: ${({ theme }) => theme.palette.grey.A700};

  ::placeholder {
    color: ${({ theme }) => theme.palette.grey.A700};
  }
`

export const DeleteButtonStyles = styled(ButtonBase)`
  color: ${({ theme }) => theme.palette.error.main};

  svg {
    margin-right: 0.5rem;
    color: ${({ theme }) => theme.palette.error.main};
  }
`

export const InputStackStyles = styled(Stack)<{ noMargin?: boolean }>`
  display: flex;
  width: 100%;
  gap: 2rem;

  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  .MuiInputBase-root {
    margin-top: 1.8rem;
    padding: 0.55rem;
  }

  ${({ noMargin }) =>
    noMargin &&
    css`
      margin: 0;
    `}
`

export const EditionIconStyles = styled(IconButton)`
  transition: all 0.25s ease;
  margin-right: -3rem;
  svg {
    margin-right: 0;
  }
`

export const InformationPanelStyles = styled(Box)<{ disablePadding?: boolean }>`
  flex: ${({ flex }) => (flex ? flex.toString() : '50%')};
  padding: ${({ disablePadding }) => (!disablePadding ? 2 : 0)}rem;
  border-top: none;
`

export const EditionPanelStyles = styled(InformationPanelStyles)<{
  isOpen?: boolean
}>`
  padding: 0;
  & > div {
    opacity: ${({ isOpen }) => Number(isOpen)};
  }

  p {
    color: ${({ theme }) => theme.palette.common.black};
    font-size: ${({ theme }) => theme.typography.body1.fontSize};
    font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  }

  h6 {
    margin: 0.5rem 1rem;
    font-size: ${({ theme }) => theme.typography.subtitle2.fontSize};
    cursor: pointer;
    color: ${({ theme }) => theme.palette.info.main};
  }

  flex: 40%;
  max-width: 50%;
  transition: all 0.25s ease;
`
