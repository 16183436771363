import {
  CheckCircleOutlineRounded,
  ErrorOutlineRounded
} from '@mui/icons-material'
import { useActionState } from '@hooks'
import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import {
  HelperTextStyles,
  InputAdornmentStyles,
  InputStyles,
  InputLabelStyles,
  FormControlStyles
} from './Input.styles'
import { InputAdornmentTypes, InputPropTypes } from './Input.types'

export const Input = forwardRef<HTMLInputElement, InputPropTypes>(
  (
    {
      name,
      label,
      inputProps,
      helperText,
      error = false,
      success = false,
      focused = false,
      hovered = false,
      ...props
    },
    ref
  ) => {
    const inputElementRef = useRef<HTMLInputElement | null>(null)
    const hoverElementRef = useRef<HTMLDivElement | null>(null)

    useImperativeHandle(ref, () => {
      if (!inputElementRef.current) {
        throw new Error('Input element ref is not set')
      }

      return inputElementRef.current
    })

    const { hasError, hasFocus, hasHover, hasSuccess } = useActionState(
      { error, focused, success, hovered },
      inputElementRef,
      hoverElementRef
    )

    const renderAdornment = (
      position: 'start' | 'end',
      adornment?: InputAdornmentTypes
    ) => {
      const { type } = props

      if (type === 'date') return null

      return (
        <InputAdornmentStyles position={position}>
          {adornment}
          {position === 'end' && (
            <>
              {hasError && <ErrorOutlineRounded color='error' />}
              {hasSuccess && <CheckCircleOutlineRounded color='success' />}
            </>
          )}
        </InputAdornmentStyles>
      )
    }

    return (
      <FormControlStyles
        fullWidth={props.fullWidth}
        variant='standard'
        ref={hoverElementRef}
      >
        {label && (
          <InputLabelStyles
            shrink
            htmlFor={name}
            required={props.required}
            error={hasError}
            success={hasSuccess}
            focused={hasFocus}
          >
            {label}
          </InputLabelStyles>
        )}
        <InputStyles
          {...props}
          inputProps={{ 'data-testid': 'inputComponent', ...inputProps }}
          id={name}
          name={name}
          type={props.type || 'text'}
          inputRef={inputElementRef}
          error={hasError}
          success={hasSuccess}
          focused={hasFocus}
          hovered={hasHover}
          startAdornment={renderAdornment('start', props.startAdornment)}
          endAdornment={renderAdornment('end', props.endAdornment)}
        />
        {Boolean(helperText) && (
          <HelperTextStyles
            as='span'
            id={name}
            error={hasError}
            success={hasSuccess}
          >
            {helperText}
          </HelperTextStyles>
        )}
      </FormControlStyles>
    )
  }
)

export * from './Input.types'
export {
  HelperTextStyles as HelperTextComponent,
  InputLabelStyles as InputLabelComponent
} from './Input.styles'
