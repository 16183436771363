import styled from 'styled-components'
import { from } from '@eduplaytion/numetry-ui-kit'

export const FooterContainerStyles = styled.div<{
  visible: boolean
  zIndex?: number
}>`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${({ zIndex }) => zIndex};

  min-height: 6.8rem;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.common.white};
  padding: 1rem 0;

  .MuiButtonBase-root {
    height: 4rem;
    min-width: 20rem;
    padding-left: 2rem;
    padding-right: 2rem;
    white-space: nowrap;
  }

  transition: all ease-in-out 0.25s;

  box-shadow: 0 -0.4rem 2rem ${({ theme }) => theme.palette.common.black}15;

  display: none;
  ${from.md} {
    display: flex;
  }

  ${({ visible }) => {
    if (!visible)
      return `
    transform: translateY(100%);
    opacity: 0;`
    return `
    transform: translateY(0%);
    opacity: 1;`
  }}
`
