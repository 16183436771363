import React, { useMemo } from 'react'
import { Container } from '@mui/material'
import { AnimateSharedLayout } from 'framer-motion'
import { useStepper } from '@context'
import { StepperAdornmentRenderProps, StepperPropTypes } from './Stepper.types'
import { AnimatedLayoutStyles } from './Stepper.styles'

export const StepperComponent = ({
  container,
  header,
  footer
}: StepperPropTypes) => {
  const stepper = useStepper()

  const adornmentData: StepperAdornmentRenderProps = useMemo(
    () => ({
      navigator: {
        ...stepper
      },
      actualStep: {
        index: stepper.actualStep,
        name: stepper.steps[stepper.actualStep]?.name
      }
    }),
    [stepper]
  )
  return (
    <Container component={container || 'div'} maxWidth={false}>
      <AnimateSharedLayout>
        <AnimatedLayoutStyles
          layout
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          key={stepper.steps[stepper.actualStep]?.name}
        >
          {header && typeof header === 'function'
            ? header(adornmentData)
            : header}
          {stepper.steps[stepper.actualStep]?.component}
          {footer && typeof footer === 'function'
            ? footer(adornmentData)
            : footer}
        </AnimatedLayoutStyles>
      </AnimateSharedLayout>
    </Container>
  )
}

export * from './Stepper.types'
