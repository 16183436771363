import React from 'react'
import { IconButton, Typography } from '@mui/material'
import { CancelSharp } from '@mui/icons-material'
import { FCWithChildren } from '@types'
import { ModalComponent } from '..'
import { PopUpPropTypes } from './PopUpModal.types'
import { PopUpContainerStyles, PopUpHeaderStyles } from './PopUpModal.styles'
import { renderFooter, resolveTemplate } from './utils'

export const PopUpComponent: FCWithChildren<PopUpPropTypes> = ({
  ...props
}) => {
  const {
    title,
    content,
    onClose,
    open,
    footer = {
      visible: true,
      align: 'flex-end'
    },
    variant,
    zIndexPriority
  } = props

  return (
    <ModalComponent
      scroll={props.scroll}
      open={open}
      onClose={onClose}
      zIndexPriority={zIndexPriority}
    >
      <PopUpContainerStyles>
        {variant !== 'noHeader' && (
          <PopUpHeaderStyles variant={variant}>
            <Typography
              variant='h6'
              fontWeight={({ typography }) => typography.fontWeightBold}
            >
              {title}
            </Typography>
            {onClose && (
              <IconButton sx={{ padding: 0 }} onClick={onClose} color='primary'>
                <CancelSharp color='primary' fontSize='large' />
              </IconButton>
            )}
          </PopUpHeaderStyles>
        )}
        {typeof content === 'string' || !content ? (
          resolveTemplate({ ...props, footer })
        ) : (
          <>
            {content}
            {renderFooter(props)}
          </>
        )}
      </PopUpContainerStyles>
    </ModalComponent>
  )
}

export * from './PopUpModal.types'
export * from './templates'
