import { apiParent, ApiResponseTypes } from 'services'
import { LinkParentToKidDirectPayloadTypes, KidAccountTypes } from './Kid.types'

export const postParentToLinkKid = async (
  payload: LinkParentToKidDirectPayloadTypes
) => {
  const { data: response } = await apiParent.post<
    ApiResponseTypes<{ newKidData: KidAccountTypes }>
  >('/v2/parent/linkKid', payload)

  return response.data
}

export default {
  post: postParentToLinkKid
}
