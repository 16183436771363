import { css } from 'styled-components'

const inputHovered = css`
  background-color: ${({ theme }) => theme.palette.grey.A200};
`

const inputFocused = css`
  border-color: ${({ theme }) => theme.palette.secondary.main};
`

const inputSuccess = css`
  border-color: ${({ theme }) => theme.palette.success.main};
`

const inputError = css`
  border-color: ${({ theme }) => theme.palette.error.main};
`

export const inputState = {
  hovered: (hover?: boolean) => hover && inputHovered,
  focused: (focus?: boolean) => focus && inputFocused,
  success: (success?: boolean) => success && inputSuccess,
  error: (error?: boolean) => error && inputError
}
