import React from 'react'
import {
  AccountCircleOutlined,
  CardMembershipOutlined,
  ChevronRight,
  HeartBrokenOutlined,
  VpnKeyOutlined
} from '@mui/icons-material'
import { Button, Input } from '@eduplaytion/numetry-ui-kit'
import { FormGroupComponent } from 'components'
import { useEdition } from '../../context/EditionModal.context'
import {
  InfoCancelSubscriptionStyles,
  InfoSectionStyles,
  StyledSelectComponent
} from './Info.styles'

const PLANS = [
  {
    id: 3,
    title: 'Numetry Månedlig',
    description: `per barn, per måned \n\n*199 kr faktureres månedlig`,
    value: '199 kr'
  },
  {
    id: 1,
    title: 'Numetry Halvårlig',
    description: `per barn, per måned \n\n*899 kr faktureres halvårlig`,
    value: '150 kr'
  },
  {
    id: 2,
    title: 'Numetry Årlig',
    description: `per barn, per måned \n\n*1 499 kr faktureres årlig`,
    value: '125 kr'
  }
]

export const InfoSectionComponent = () => {
  const { setCurrentView, formik } = useEdition()
  return (
    <InfoSectionStyles>
      <FormGroupComponent
        title='Username'
        icon={AccountCircleOutlined}
        content={
          <Input
            name='name'
            value={formik.values.username}
            onChange={formik.handleChange}
          />
        }
      />
      <FormGroupComponent
        title='Password'
        icon={VpnKeyOutlined}
        content={
          <Input
            name='password'
            type='password'
            value='**********'
            onClick={() => setCurrentView('password')}
            endAdornment={<ChevronRight />}
            readOnly
          />
        }
      />
      <FormGroupComponent
        title='#!Plan'
        icon={CardMembershipOutlined}
        content={
          <StyledSelectComponent
            name='plan'
            placeholder='#!Select your plan'
            options={PLANS.map((plan) => ({
              label: plan.title,
              value: plan.id.toString()
            }))}
            onChange={(plan) =>
              formik.setFieldValue(
                'plan',
                PLANS.find((where) => where.id.toString() === plan.value)
              )
            }
          />
        }
        noDivisor
      />
      <InfoCancelSubscriptionStyles>
        <Button variant='text' color='error'>
          <HeartBrokenOutlined /> Cancel subscription
        </Button>
      </InfoCancelSubscriptionStyles>
    </InfoSectionStyles>
  )
}
