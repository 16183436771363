import { Button } from '@eduplaytion/numetry-ui-kit'
import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { InformationPanelStyles, TextAreaStyles } from '../Account.tab.styles'

export const InsightPanel = () => {
  const { t } = useTranslation()
  return (
    <InformationPanelStyles
      border={({ palette }) => `0.1rem solid ${palette.grey.A700}`}
      borderRight='none'
      flex='100%'
      display='flex'
      flexDirection='row'
      gap={2}
    >
      <Stack spacing={3} flex='50%'>
        <Typography
          variant='h6'
          fontWeight={({ typography }) => typography.fontWeightBold}
        >
          {t('pages.account.insight.title')}
        </Typography>
        <Typography>{t('pages.account.insight.insightText')}</Typography>
        <Typography
          variant='h6'
          fontWeight={({ typography }) => typography.fontWeightBold}
        >
          {t('pages.account.insight.aboutResponseInTime')}
        </Typography>
        <Typography>
          {t('pages.account.insight.aboutResponseInTimeText')}
        </Typography>
        <Typography
          variant='h6'
          fontWeight={({ typography }) => typography.fontWeightBold}
        >
          {t('pages.account.insight.youMust')}
        </Typography>
        <Typography>{t('pages.account.insight.youMustText')}</Typography>
      </Stack>
      <Box mt={8} flex='50%'>
        <Typography
          variant='h6'
          fontWeight={({ typography }) => typography.fontWeightBold}
        >
          {t('pages.account.inspection.title')}
        </Typography>
        <TextAreaStyles placeholder={t('pages.account.inspection.message')} />

        <Box display='flex' justifyContent='flex-end'>
          <Button variant='contained' color='primary'>
            {t('general.actions.send')}
          </Button>
        </Box>
      </Box>
    </InformationPanelStyles>
  )
}
