import { apiParent, ApiResponseTypes } from 'services'
import { LinkParentToKidPayloadTypes, KidAccountTypes } from './Kid.types'

export const postParentToNewKidAccount = async (
  payload: LinkParentToKidPayloadTypes
) => {
  const { data: response } = await apiParent.post<
    ApiResponseTypes<KidAccountTypes>
  >('/v2/parent/createKid', payload)

  return response.data
}

export default {
  post: postParentToNewKidAccount
}
