import {
  AccountCircleOutlined,
  Password,
  VpnKeyOutlined
} from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import { FormGroupComponent } from 'components'
import { Input, useStepper } from '@eduplaytion/numetry-ui-kit'
import React, { useEffect } from 'react'
import { useCreateStep } from '../../context/CreateStep.context'
import { FormContainerStyles } from './KidDetails.step.styles'

export const KidDetailsComponent = () => {
  const {
    formik: { values, ...formik }
  } = useCreateStep()
  const { setBlock } = useStepper()

  useEffect(() => {
    if (
      (values.user.username && values.user.password,
      values.user.passwordConfirmation)
    )
      setBlock(true)
  }, [values])
  return (
    <Box>
      <Box mb={4}>
        <Typography color='grey'>
          This will be the username and password your child logs in with in
          Numetry. Your child will be able to choose their own in-game avatar
          name.
        </Typography>
      </Box>
      <FormContainerStyles>
        <FormGroupComponent
          icon={AccountCircleOutlined}
          variant='row'
          title='Username'
          content={
            <Input
              name='user.username'
              onChange={formik.handleChange}
              value={values?.user?.username}
              placeholder='Create an username'
            />
          }
        />
        <FormGroupComponent
          icon={VpnKeyOutlined}
          variant='row'
          title='Password'
          content={
            <Input
              type='password'
              name='user.password'
              onChange={formik.handleChange}
              value={values?.user?.password}
              placeholder='Create a password'
            />
          }
        />
        <FormGroupComponent
          icon={Password}
          variant='row'
          title='Confirm password'
          noDivisor
          content={
            <Input
              type='password'
              name='user.passwordConfirmation'
              onChange={formik.handleChange}
              value={values?.user?.passwordConfirmation}
              placeholder='Confirm password'
            />
          }
        />
      </FormContainerStyles>
    </Box>
  )
}
