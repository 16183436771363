import { apiParent, SubscriptionPlanTypes, ApiResponseTypes } from 'services'

export const getAllSubscriptionPlans = async () => {
  const { data: response } = await apiParent.get<
    ApiResponseTypes<SubscriptionPlanTypes[]>
  >('/subscriptionPlans')

  return response.data
}

export default {
  get: getAllSubscriptionPlans
}
