import {
  FormHelperText,
  InputAdornment,
  InputAdornmentProps,
  InputBase,
  InputLabel,
  FormControl
} from '@mui/material'
import styled, { css } from 'styled-components'
import { inputState } from '@styles'
import {
  InputHelperTypes,
  InputLabelTypes,
  InputPropTypes
} from './Input.types'

export const FormControlStyles = styled(FormControl)``

export const InputLabelStyles = styled(InputLabel)<InputLabelTypes>`
  ${({ success }) =>
    success &&
    css`
      color: ${({ theme }) => theme.palette.success.main};
    `}
`

export const InputStyles = styled(InputBase)<InputPropTypes>`
  width: 100%;
  min-height: 3.2rem;

  border-radius: 0.4rem;
  border: 0.1rem solid ${({ theme }) => theme.palette.grey.A700};

  transition: all 0.2s;

  ${({ margin, label }) => margin !== 'none' && label && `margin-top: 0rem`};

  // CSS fallback
  :hover {
    ${inputState.hovered(true)}
  }
  :focus {
    ${inputState.focused(true)}
  }

  ${({ error }) => inputState.error(error)}
  ${({ success }) => inputState.success(success)}
  ${({ focused }) => inputState.focused(focused)}
  ${({ hovered }) => inputState.hovered(hovered)}
`

export const InputAdornmentStyles = styled(InputAdornment)<InputAdornmentProps>`
  ${({ position }) => {
    if (position === 'start') {
      return css`
        margin-left: 0.5rem;
      `
    }

    return css`
      margin-right: 0.5rem;
    `
  }}
  > svg {
    font-size: 2rem;
  }
`

export const HelperTextStyles = styled(FormHelperText)<InputHelperTypes>`
  font-size: ${({ theme }) => theme.typography.body1.fontSize};

  color: ${({ theme }) => theme.palette.grey.light};
  transition: all 0.2s;
  margin: 0;

  ${({ error }) =>
    error &&
    css`
      color: ${({ theme }) => theme.palette.error.main};
    `}

  ${({ success }) =>
    success &&
    css`
      color: ${({ theme }) => theme.palette.success.main};
    `}
`
