import styled from 'styled-components'
import { SelectComponent } from '@eduplaytion/numetry-ui-kit'

export const InfoSectionStyles = styled.div`
  display: flex;
  flex-direction: column;
  .MuiInputBase-root {
    margin-top: 0;
  }
`

export const InfoCancelSubscriptionStyles = styled.div`
  display: flex;
  padding: 1rem;
  justify-content: flex-end;

  border-color: ${({ theme }) => theme.palette.common.white};
  border-width: 0.1rem;
  border-top-style: solid;
  border-bottom-style: solid;
  svg {
    margin-right: 1rem;
  }
`

export const StyledSelectComponent = styled(SelectComponent)`
  width: 100%;
`
