import { Popover } from '@mui/material'
import styled from 'styled-components'
import { COLORS } from '@styles'

export const AsideFeedbackPopoverStyles = styled(Popover)`
  .MuiPopover-paper {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    box-shadow: 0 0 2rem ${COLORS.black}30;
    background: ${COLORS.white};
  }
`

export const AsideFeedbackContainerStyles = styled.form`
  display: flex;
  flex-direction: column;
`

export const AsideFeedbackHeaderStyles = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: 1.6rem 0.8rem 0;

  h6 {
    font-weight: ${({ theme }) => theme.typography.fontWeightBold};
    color: ${COLORS.dark};
  }

  .MuiSvgIcon-root {
    cursor: pointer;
    color: ${COLORS.dark};
  }
`

export const AsideFeedbackMoodStyles = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  padding: 0.8rem;
`

export const AsideFeedbackMessageStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: flex-end;

  border-top: 0.1rem solid ${COLORS.greyBg};
  padding: 0.8rem;

  .MuiLoadingButton-root {
    max-width: max-content;
  }
`
