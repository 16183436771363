import React from 'react'
import { Navigate, Route } from 'react-router-dom'

import { ProtectedStateRoute, ROUTES as PATH_ROUTES } from 'router'

import {
  LinkConfirmation,
  Checkout,
  KidAccountSelection,
  ContactInformation,
  DoItLater,
  ExistingAccount,
  LinkChildren,
  PaymentConfirmation,
  MembershipSelection,
  NewAccount,
  DoItLaterConfirmation,
  DiscountLanding,
  Feedback
} from './view.imports'

const useProtectedStateRoute = (requires: unknown) => {
  return <ProtectedStateRoute redirectPath='/' requires={requires} />
}

export const renderCheckoutRoutes = () => {
  const {
    CHECKOUT,
    PAYMENT_CONFIRMATION,
    CHECKOUT_STEPS,
    CONTACT_INFORMATION,
    MEMBERSHIP_SELECTION,
    LINK_CHILDREN,
    EXISTING_ACCOUNT,
    NEW_ACCOUNT,
    DO_IT_LATER,
    CONFIRMATION,
    GAME_CHILD,
    METHODS,
    FEEDBACK
  } = PATH_ROUTES

  const doItLaterRoutes = () => (
    <Route path={DO_IT_LATER}>
      <Route index Component={DoItLater} />
      <Route path={CONFIRMATION} Component={DoItLaterConfirmation} />
    </Route>
  )

  const linkConfirmationRoutes = () => (
    <Route path={CONFIRMATION}>
      <Route index Component={LinkConfirmation} />
    </Route>
  )

  return (
    <Route path={CHECKOUT} Component={Checkout}>
      <Route index Component={DiscountLanding} />
      <Route path={CHECKOUT_STEPS}>
        <Route index element={<Navigate to={CONTACT_INFORMATION} />} />

        <Route path={CONTACT_INFORMATION}>
          <Route index Component={ContactInformation} />
        </Route>

        <Route path={MEMBERSHIP_SELECTION}>
          <Route index Component={MembershipSelection} />
          <Route path={PAYMENT_CONFIRMATION} Component={PaymentConfirmation} />
        </Route>

        <Route path={LINK_CHILDREN}>
          <Route index element={<Navigate to={METHODS} />} />
          <Route path={FEEDBACK} Component={Feedback} />

          <Route path={GAME_CHILD}>
            <Route index Component={KidAccountSelection} />
            {linkConfirmationRoutes()}
            {doItLaterRoutes()}
          </Route>

          <Route path={METHODS}>
            <Route index Component={LinkChildren} />

            <Route
              path={EXISTING_ACCOUNT}
              element={useProtectedStateRoute({
                parentEmail: '',
                subscriptionPinCode: ''
              })}
            >
              <Route index Component={ExistingAccount} />
              {linkConfirmationRoutes()}
            </Route>

            <Route
              path={NEW_ACCOUNT}
              element={useProtectedStateRoute({
                parentEmail: '',
                subscriptionPinCode: ''
              })}
            >
              <Route index Component={NewAccount} />
              {linkConfirmationRoutes()}
            </Route>

            {doItLaterRoutes()}
          </Route>
        </Route>
      </Route>
    </Route>
  )
}
