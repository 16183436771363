import styled from 'styled-components'
import { from } from '@eduplaytion/numetry-ui-kit'

export const LayoutHeaderStyles = styled.div`
  display: none;
  margin-top: 3rem;

  p {
    margin-top: 1rem;
    font-size: ${({ theme }) => theme.typography.body1.fontSize};
    color: ${({ theme }) => theme.palette.common.black};
  }

  ${from.md} {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    h2 {
      font-size: ${({ theme }) => theme.typography.h5.fontSize};
      font-weight: ${({ theme }) => theme.typography.fontWeightBold};
      color: ${({ theme }) => theme.palette.royalBlue.dark};
    }
  }
`
