import { DeleteOutlined } from '@mui/icons-material'
import { Typography } from '@mui/material'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
  InformationPanelStyles,
  DeleteButtonStyles
} from '../Account.tab.styles'

export const CancelAccountPanel = () => {
  const { t } = useTranslation()
  return (
    <InformationPanelStyles
      border={({ palette }) => `0.1rem solid ${palette.grey.A700}`}
    >
      <Trans>
        <Typography
          variant='h6'
          fontWeight={({ typography }) => typography.fontWeightBold}
          mb={2}
        >
          {t('pages.account.cancelAccount.title')}
        </Typography>
        <Typography mb={2} width='50%'>
          {t('pages.account.cancelAccount.cancelAccountText')}
        </Typography>
        <DeleteButtonStyles>
          <DeleteOutlined />
          {t('pages.account.cancelAccount.deleteAccount')}
        </DeleteButtonStyles>
      </Trans>
    </InformationPanelStyles>
  )
}
