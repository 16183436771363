import { css } from 'styled-components'

export const genericReset = () => css`
  * {
    scrollbar-width: thin;
    scrollbar-color: ${({ theme }) =>
      `${theme.palette.grey.A700} ${theme.palette.common.white}`};
  }

  *::-webkit-scrollbar {
    width: 1.7rem;
  }

  *::-webkit-scrollbar-thumb {
    border-radius: 1.2rem;
    border: 0.4rem solid transparent;
    background-color: ${({ theme }) => theme.palette.grey.A700};
    background-clip: content-box;
    transition: 0.3s;
  }

  html,
  body {
    min-height: 100%;
    height: auto;
  }

  * {
    &,
    &:before,
    &:after {
      box-sizing: border-box;
      outline: none;
      text-transform: none;
      font-family: ${({ theme }) => theme.typography.fontFamily};
      font-size: ${({ theme }) => theme.typography.body1.fontSize};
    }
  }

  *:not(
      input,
      button,
      .MuiButtonBase-root,
      .MuiAccordion-root,
      .MuiSelect-select
    ):focus {
    outline: 0.2rem solid ${({ theme }) => theme.palette.info.main} !important;
    outline-offset: -0.2rem;
    box-shadow: none;
  }

  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  blockquote,
  p,
  pre,
  dl,
  dd,
  ol,
  ul,
  figure,
  hr,
  fieldset,
  legend {
    margin: 0;
    padding: 0;
  }

  html,
  body,
  #root {
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: 62.5%;
  }

  body {
    background-color: ${({ theme }) => theme.palette.background?.default};
  }

  li > {
    ol,
    ul {
      margin-bottom: 0;
    }
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  fieldset {
    min-width: 0;
    border: 0;
  }

  button {
    cursor: pointer;
  }

  a,
  li {
    text-decoration: none;
  }

  a {
    color: inherit;
  }

  img {
    width: 100%;
    max-width: 100%;
  }

  /* should remove these css classes */
  .success-text {
    color: ${({ theme }) => theme.palette.success.main};
    font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  }

  .MuiCalendarPicker-root {
    .MuiTypography-root {
      font-size: 1.4rem;
    }
  }

  button.MuiButtonBase-root.MuiPickersDay-root {
    font-size: 1.4rem;
  }
`
