export default {
  minute: 'minute',
  minutes: 'minutes',
  min: 'min',
  showingOf: 'Showing {{ visibleRows }} of {{ totalDataRows }}',
  showLess: 'Show less',
  showMore: 'Show more',
  fileSelect: 'Select a file',
  userNotFound: 'User not found',
  noMoreResults: 'No more results',
  teacher: 'Teacher',
  feideFetchStatus: {
    title: 'Feide synchronization...',
    fetching: 'Updating database with feide informantion',
    error: 'An error ocourred when trying to fetch information from Feide',
    success: 'Student update from Feide was successful'
  },
  filters: {
    text: {
      filterBy: 'Filter by:',
      filterResults: 'Filter Results',
      clearFilters: 'Clear filters',
      selectItem: 'Select a {{ topic }}'
    },
    status: {
      title: 'Status',
      item: {
        allAssignments: 'all assignments',
        progress: 'active',
        upcoming: 'planned',
        finished: 'completed'
      }
    },
    topics: {
      title: 'Topics'
    },
    assigned: {
      title: 'Assigned to',
      item: {
        students: 'students',
        groups: 'groups'
      }
    },
    planets: {
      title: 'Planets'
    },
    skills: {
      title: 'Skills'
    }
  },
  imageAlt: {
    login: {
      gameCharacters: 'Game characters'
    }
  },
  status: {
    error: {
      somethingWentWrong: 'Something went wrong',
      required: 'This input is required',
      shouldBeAnEmail: 'Should be an email',
      emailDoesntMatch: "Email doesn't match",
      passwordDoesntMatch: "Password doesn't match",
      notFound: {
        title: "Woops! <br/> We couldn't find the page you are looking for.",
        description:
          'The site might not exist anymore, or the contents have been moved. <customLink>Go to the main page</customLink> or try to choose a page from the main menu.'
      },
      cannotUndoThisAction: 'You <strong>cannot</strong> undo this action.'
    }
  },
  state: {
    nrOfModesSelected_0: 'No modes selected',
    nrOfModesSelected: '{{ count  }} Mode selected',
    nrOfModesSelected_plural: '{{ count }} Modes selected',
    selected: 'Selected',
    notSelected: 'Not Selected',
    selectedNumber: '{{ selectedNumber }} selected',
    removed: 'Removed',
    deleted: 'Deleted',
    modified: 'Modified',
    started: 'Started',
    ended: 'Ended',
    comingSoon: 'Coming soon',
    quitEditing: 'Quit editing?',
    searchResultsFor: 'Search results for {{ value }}',
    loading: 'Loading...',
    studentAdded: 'Student Added',
    ok: 'Ok'
  },
  selectAll: 'Select all',
  actions: {
    addAndClose: 'Add and close',
    addAndContinue: 'Add and continue',
    addNewTopic: 'Add new topic',
    edit: 'Edit',
    delete: 'Delete',
    remove: 'Remove',
    send: 'Send',
    next: 'Next',
    back: 'Back',
    save: 'Save {{ addOn }}',
    update: 'Update {{ addOn }}',
    select: 'Select',
    confirm: 'Confirm',
    cancel: 'Cancel',
    report: 'Report',
    editParentsName: 'Edit parents name',
    moveToGroup: 'Move to a group',
    revoke: 'Revoke',
    moreOptions: 'More Options',
    saveCustomer: 'Save customer',
    close: 'Close',
    return: 'Return',
    submit: 'Submit',
    add: 'Add',
    view: 'View',
    createGroup: 'Create group',
    saveGroup: 'Save group',
    saveChanges: 'Save changes',
    goToArticle: 'Go to article',
    keepEditing: 'Keep editing',
    quit: 'Yes, quit',
    show: 'Show',
    hide: 'Hide',
    unHide: 'Unhide',
    previous: 'Previous'
  },
  user: {
    accountSettings: 'Account settings'
  },
  errors: {
    assignmentTitleIsRequired: 'Please, type an assignment name',
    invalidDate: 'Invalid period',
    invalidTime: 'Invalid Time',
    shouldNotBeEmpty: 'Should not be empty',
    shouldNotBeEmptyAddon: '{{ key }} should not be empty',
    unexpectedError: 'Unexpected Error',
    notSaved: "Changes you've made so far will <strong>not</strong> be saved."
  },
  loadMoreResults: 'Load more results',
  entry_one: {
    one: 'Entry',
    other: 'Entries'
  },
  validation: {
    required: 'This is a required field'
  }
}
