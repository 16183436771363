import React from 'react'
import { Check } from '@mui/icons-material'
import { SvgIcon, Typography } from '@mui/material'

import { capitalizeFirstLetter } from '@utils'

import { FCWithChildren } from '@types'
import { MenuItemPropTypes } from './MenuItem.types'
import { MenuItemStyles, MenuItemContentStyles } from './MenuItem.styles'

export const MenuItemComponent: FCWithChildren<MenuItemPropTypes> = ({
  enableArrowMark,
  item,
  isSelected,
  ...rest
}) => {
  return (
    <MenuItemStyles
      {...rest}
      value={item.value}
      selected={isSelected}
      tabIndex={0}
    >
      <MenuItemContentStyles>
        {enableArrowMark && (
          <SvgIcon fontSize='small'>{isSelected && <Check />}</SvgIcon>
        )}
        <Typography noWrap>{capitalizeFirstLetter(item.label)}</Typography>
      </MenuItemContentStyles>
    </MenuItemStyles>
  )
}
