import { Link } from '@mui/material'
import { Input } from '@eduplaytion/numetry-ui-kit'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ROUTES } from 'router'
import { useEdition } from '../../context/EditionModal.context'
import { PasswordSectionStyles } from './Password.styles'

export const PasswordSectionComponent = () => {
  const { formik } = useEdition()
  const { t } = useTranslation()

  return (
    <PasswordSectionStyles>
      <Input
        type='password'
        name='currentPassword'
        onChange={formik.handleChange}
        placeholder='Current password'
        value={formik.values.currentPassword}
      />
      <Link href={`/${ROUTES.AUTH}/${ROUTES.LOGIN}/${ROUTES.FORGOT_PASSWORD}`}>
        {t('auth.forgotYourPassword')}
      </Link>
      <Input
        type='password'
        name='newPassword'
        onChange={formik.handleChange}
        value={formik.values.newPassword}
        placeholder='New password with at least 8 characters'
      />
      <Input
        type='password'
        name='passwordConfirmation'
        onChange={formik.handleChange}
        value={formik.values.passwordConfirmation}
        placeholder='Confirm new password'
      />
    </PasswordSectionStyles>
  )
}
