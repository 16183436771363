import styled from 'styled-components'
import { Paper, Popper } from '@mui/material'

import { COLORS } from '@styles'

const sizeOfArrow = '1.2rem'

export const PopperStyles = styled(Popper)`
  z-index: ${({ theme }) => theme.zIndex.tooltip};
  position: relative;
  max-width: 30rem;
`
export const PopperContainerStyles = styled(Paper)`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  margin: 0 1.2rem;
  padding: 1.2rem;

  background-color: ${COLORS.white};

  box-shadow: -0.5rem 0 2rem ${COLORS.black}29;
  border-radius: 0.5rem;

  ::before {
    content: '';
    background-color: ${COLORS.white};
    position: absolute;
    width: ${sizeOfArrow};
    height: ${sizeOfArrow};
    top: calc(${sizeOfArrow} * 2);
    left: calc(${sizeOfArrow} / 2);
    transform: rotate(45deg);
  }
`

export const PopperHeaderStyles = styled.div`
  display: flex;

  p {
    color: ${COLORS.info};
    font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  }

  svg {
    margin-right: 1rem;
    color: ${COLORS.info};
  }
`

export const PopperBodyStyles = styled.div`
  p {
    text-transform: capitalize;
  }
`

export const PopperFooterStyles = styled.div`
  background-color: ${COLORS.ghostWhite};
  border-radius: 0.5rem;
  padding: 1rem;
`
