import { apiParent } from 'services'
import { DoItLaterPayloadTypes } from './DoItLater.types'

export const postDoItLater = async (payload: DoItLaterPayloadTypes) => {
  const { data: response } = await apiParent.post(
    '/v2/parent/doItLater',
    payload
  )

  return response.data
}

export default {
  post: postDoItLater
}
