/* eslint-disable react/no-unstable-nested-components */
import React, { useMemo, useState } from 'react'
import {
  SortingState,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  getFilteredRowModel,
  flexRender,
  getExpandedRowModel,
  ExpandedState,
  ColumnFiltersState,
  getFacetedRowModel,
  SortingColumn
} from '@tanstack/react-table'
import {
  Box,
  Skeleton,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { DualDropArrowComponent, PaginationComponent } from '@components'

import { InfiniteButtonComponent } from './components'

import { TablePropTypes } from './Table.types'
import { TableStyles } from './Table.styles'

type TDataObjectTypes<TData> = object & { subRows?: TData[] }

const SortingHeader = <TData extends object>({
  sortDirection,
  onClick,
  headerContent
}: {
  onClick: SortingColumn<TData>['getToggleSortingHandler']
  headerContent: React.ReactNode
  sortDirection: SortingColumn<TData>['getIsSorted']
}) => {
  return (
    <Box
      role='presentation'
      display='flex'
      alignItems='center'
      justifyContent='space-between'
      sx={{
        cursor: 'pointer',
        userSelect: 'none'
      }}
      onClick={onClick()}
    >
      {headerContent}
      <DualDropArrowComponent activeDir={sortDirection() || 'both'} />
    </Box>
  )
}

export const TableComponent = <TData extends TDataObjectTypes<TData>>({
  data,
  columns,
  isLoading = false,
  footer,
  renderSubComponent,
  infiniteScroll,
  ...rest
}: TablePropTypes<TData>) => {
  const { t } = useTranslation()
  const [expanded, setExpanded] = useState<ExpandedState>({})
  const [sorting, setSorting] = useState<SortingState>([])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])

  const tableData = useMemo(() => {
    if (isLoading) return Array<TData>(15).fill({} as TData)

    return data
  }, [isLoading, data])

  const tableColumns = useMemo(() => {
    if (isLoading) {
      return columns.map((column) => ({
        ...column,
        cell: () => <Skeleton />
      }))
    }

    return columns
  }, [isLoading, columns])

  const table = useReactTable({
    data: tableData,
    columns: tableColumns,
    getRowCanExpand: renderSubComponent?.getRowCanExpand,
    state: {
      columnFilters,
      expanded,
      sorting
    },
    onColumnFiltersChange: setColumnFilters,
    onSortingChange: setSorting,
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    manualPagination: true
  })

  return (
    <TableContainer>
      <TableStyles {...rest}>
        {rest['aria-label'] && <caption>{rest['aria-label']}</caption>}
        <TableHead>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                const headerContent = header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )

                const isSortable = header.column.getCanSort()

                return (
                  <TableCell
                    key={header.id}
                    colSpan={header.colSpan}
                    width={header.getSize()}
                  >
                    {isSortable ? (
                      <SortingHeader
                        onClick={header.column.getToggleSortingHandler}
                        headerContent={headerContent}
                        sortDirection={header.column.getIsSorted}
                      />
                    ) : (
                      headerContent
                    )}
                  </TableCell>
                )
              })}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {table.getRowModel().rows.map((row, rowId) => (
            <>
              <TableRow key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  const { meta } = cell.column.columnDef

                  return (
                    <TableCell
                      key={cell.id}
                      data-cell={meta && meta.dataCell.concat(':')}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  )
                })}
              </TableRow>
              {renderSubComponent && row.getIsExpanded() && (
                <TableRow key={row.id.concat(rowId.toString())}>
                  <TableCell colSpan={row.getVisibleCells().length}>
                    {renderSubComponent.subComponent({ row })}
                  </TableCell>
                </TableRow>
              )}
            </>
          ))}
        </TableBody>
        {/* this button can be refactored with infinite table scroll from @tanstack/react-table */}
        {infiniteScroll && <InfiniteButtonComponent {...infiniteScroll} />}
        {footer && (
          <TableFooter>
            <TableRow>
              <TableCell colSpan={table.getAllColumns().length}>
                <Box
                  display='flex'
                  justifyContent={
                    footer.pagination ? 'space-between' : 'flex-end'
                  }
                  alignItems='center'
                  gap={2}
                  flexWrap='wrap'
                >
                  {footer.pagination && (
                    <PaginationComponent
                      sx={{ minWidth: 'max-content' }}
                      disabled={isLoading}
                      count={table.getPageCount()}
                      onChange={(_, page) => {
                        table.setPagination({
                          pageIndex: page,
                          pageSize: 1
                        })
                      }}
                    />
                  )}
                  <Typography>
                    {t('general.showingOf', {
                      visibleRows: table.getRowModel().rows.length,
                      totalDataRows: footer.totalDataCount
                    })}
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
          </TableFooter>
        )}
      </TableStyles>
    </TableContainer>
  )
}

export * from './components'
export * from './Table.types'
