import { motion } from 'framer-motion'
import React from 'react'

export const withTransition = (children: JSX.Element) => (
  <motion.main
    style={{ display: 'flex', flexDirection: 'row' }}
    initial={{
      opacity: 0
    }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 0.5 }}
  >
    {children}
  </motion.main>
)
