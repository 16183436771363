import React, { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { Backdrop, ClickAwayListener } from '@mui/material'

import { useDevice } from '@hooks'
import { useLayout } from '@context'

import { FCWithChildren } from '@types'
import { AsideStyles } from './Aside.styles'
import { AsideItemComponent } from './components'

import { AsidePropTypes } from './Aside.types'

export const AsideComponent: FCWithChildren<AsidePropTypes> = ({
  items,
  ...rest
}) => {
  const location = useLocation()
  const asideRef = useRef<HTMLDivElement | null>(null)
  const { asideMenuHandlers } = useLayout()
  const { isDesktopDevice } = useDevice()

  const isCollapsed = !asideMenuHandlers.isOpen

  useEffect(() => {
    asideMenuHandlers.onClose()
  }, [location])

  return (
    <>
      <Backdrop
        open={asideMenuHandlers.isOpen}
        sx={{
          zIndex: ({ zIndex }) => zIndex.fab
        }}
        onClick={asideMenuHandlers.onClose}
      />
      <ClickAwayListener onClickAway={asideMenuHandlers.onClose}>
        <AsideStyles
          ref={asideRef}
          onMouseEnter={() => {
            if (isDesktopDevice) asideMenuHandlers.onOpen()
          }}
          onMouseLeave={() => {
            if (isDesktopDevice) asideMenuHandlers.onClose()
          }}
          id='aside'
          {...rest}
        >
          {items.map((item) => (
            <AsideItemComponent
              isCollapsed={isCollapsed}
              item={item}
              key={item.id}
            />
          ))}
        </AsideStyles>
      </ClickAwayListener>
    </>
  )
}
