import { Box, Typography } from '@mui/material'
import styled, { css } from 'styled-components'
import { COLORS, inputState } from '@styles'
import {
  StatusTypes,
  SelectModeTypes,
  OptionTypes,
  SelectActionTypes
} from './Select.types'

const defaultColorStyles = css`
  background-color: ${({ theme }) => theme.palette.secondary.A100};
  border: 0.1rem solid ${({ theme }) => theme.palette.secondary[100]};

  color: ${COLORS.dark};
  border-radius: 0.4rem;
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  overflow: hidden;
`

export const RenderCustomValueStyles = styled(Typography)<{
  mode: SelectModeTypes
  value: OptionTypes['value']
}>`
  ${({ mode, value }) =>
    mode === 'reports' &&
    value === '' &&
    css`
      ${defaultColorStyles}
      text-align: center;
    `}

  ${({ mode }) =>
    mode === 'status' &&
    css`
      border-radius: 0.3rem;
      height: 2.2rem;
      line-height: 2rem;
      text-align: center;
    `}

  ${({ mode, value }) => {
    if (mode === 'status') {
      if (value === '') return defaultColorStyles

      if (value === StatusTypes.ACTIVE) {
        return css`
          background-color: ${COLORS.progressGreen};
          border: 0.1rem solid ${COLORS.successDark};
          color: ${COLORS.successDark};
        `
      }
      if (value === StatusTypes.PLANNED) {
        return css`
          background-color: ${COLORS.warningLight};
          border: 0.1rem solid ${COLORS.warningDark};
          color: ${COLORS.warningDark};
        `
      }
      if (value === StatusTypes.COMPLETED) {
        return css`
          background-color: ${COLORS.dangerLight};
          border: 0.1rem solid ${COLORS.dangerDark};
          color: ${COLORS.dangerDark};
        `
      }
      return ``
    }
    return ``
  }}
`

export const SelectStyles = styled.div<{
  clientWidth: number
  selectedLength?: number
  fullWidth?: boolean
}>`
  .MuiPopper-root {
    max-width: ${({ clientWidth }) => clientWidth}px;
    width: 100%;
  }

  ${({ fullWidth, clientWidth, selectedLength }) => {
    const maxWidth = clientWidth + (selectedLength || 1) * 12

    if (fullWidth) {
      return css`
        width: fit-content;
        max-width: ${maxWidth}px;
      `
    }
    return css`
      max-width: ${maxWidth}px;
      width: 100%;
    `
  }}
`

export const SelectButtonStyles = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3.4rem;
  padding-left: 1.6rem;
  padding-right: 1rem;
  border: 0.1rem solid ${({ theme }) => theme.palette.grey.A700};
  border-radius: 0.4rem;
  overflow: hidden;
  background: ${({ theme }) => theme.palette.common.white};
`

export const ButtonContainerStyles = styled.div<SelectActionTypes>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3.4rem;
  border-radius: 0.4rem;
  overflow: hidden;
  border: 0.1rem solid ${({ theme }) => theme.palette.grey.A700};
  background: ${({ theme }) => theme.palette.common.white};

  ${({ hovered }) => inputState.hovered(hovered)}
  ${({ error }) => inputState.error(error)}
  ${({ success }) => inputState.success(success)} 
  ${({ focused }) => inputState.focused(focused)}
`

export const PopperContentStyles = styled(Box)`
  border: 1px solid ${({ theme }) => theme.palette.grey.A700};
  box-shadow: 0 0.3rem 0.6rem ${({ theme }) => theme.palette.common.black}29;
  background: ${({ theme }) => theme.palette.common.white};
  border-radius: 0.4rem;
  overflow: hidden;
`
export const PopperListStyles = styled.div`
  overflow-y: auto;
  max-height: 30rem;
`
