import { AxiosError } from 'axios'
import { useMutation } from 'react-query'
import { ApiResponseTypes, ParentServices } from 'services'
import { useFeedback } from '@eduplaytion/numetry-ui-kit'

export const useLinkAccount = () => {
  const { alert } = useFeedback()

  const linkParentToExistingKidQuery = useMutation(ParentServices.kid.post, {
    onError(error: AxiosError<ApiResponseTypes<unknown>>) {
      const message = String(error.response?.data.message || error.message)

      if (
        ['username', 'password', 'name', 'Kid'].some((sub) =>
          message.includes(sub)
        )
      ) {
        return
      }
      alert.showError(message)
    }
  })

  const linkParentToNewKidQuery = useMutation(ParentServices.createKid.post, {
    onError: (error: AxiosError<ApiResponseTypes<unknown>>) => {
      const message = String(error.response?.data.message || error.message)

      if (
        ['username', 'password', 'name'].some((sub) => message.includes(sub))
      ) {
        return
      }

      alert.showError(message)
    }
  })
  const linkParentToKidFromGameQuery = useMutation(
    ParentServices.kid.link.post,
    {
      onError(error: AxiosError<ApiResponseTypes<unknown>>) {
        const message = error.response?.data.message || error.message
        alert.showError(message)
      }
    }
  )

  return {
    linkParentToExistingKidQuery,
    linkParentToKidFromGameQuery,
    linkParentToNewKidQuery
  }
}
