import React, { useState } from 'react'
import { VisibilityOutlined, VisibilityOffOutlined } from '@mui/icons-material'
import { IconButton, Typography } from '@mui/material'
import { AnimatePresence } from 'framer-motion'

import { SecretDisplayStyles } from './SecretDisplay.styles'
import { SecretDisplayPropTypes } from './SecretDisplay.types'

export const SecretDisplayComponent: React.FC<SecretDisplayPropTypes> = ({
  value,
  isVisible: visible
}) => {
  const [isVisible, setVisible] = useState(visible || false)

  return (
    <SecretDisplayStyles
      isVisible={isVisible}
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <AnimatePresence>
        <Typography>{value}</Typography>
        <span>{value.toString().replace(/./g, '*')}</span>
      </AnimatePresence>
      <IconButton onClick={() => setVisible((cur) => !cur)}>
        {isVisible ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
      </IconButton>
    </SecretDisplayStyles>
  )
}
