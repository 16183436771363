import styled from 'styled-components'
import { Pagination } from '@mui/material'

export const PaginationStyles = styled(Pagination)`
  .MuiPagination-ul {
    flex-wrap: nowrap;

    .Mui-selected {
      color: ${({ theme }) => theme.palette.common.white};
    }

    button {
      font-size: ${({ theme }) => theme.typography.body1.fontSize};
      border-radius: 0;
      margin: 0;
      padding: 0 1.3rem;
      color: ${({ theme }) => theme.palette.royalBlue.dark};
    }

    li {
      border: 0.1rem solid ${({ theme }) => theme.palette.royalBlue.light};
    }
  }
`
