import React from 'react'
import {
  Navigate,
  Outlet,
  useLocation,
  useOutletContext
} from 'react-router-dom'
import { hasSameKeys } from 'utils/hasSameKeys'
import { ProtectedStateRoutePropTypes } from './ProtectedStateRoute.types'

export const ProtectedStateRoute: React.FC<ProtectedStateRoutePropTypes> = ({
  requires,
  redirectPath
}) => {
  const location = useLocation()

  if (!location.state || !hasSameKeys(location.state, requires as never)) {
    return <Navigate to={redirectPath} replace />
  }

  return <Outlet context={useOutletContext()} />
}
