import { Stack, Typography } from '@mui/material'
import { FormGroupComponent } from 'components'
import { Input } from '@eduplaytion/numetry-ui-kit'
import { FormikProps } from 'formik'
import { TFunction } from 'i18next'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'router'
import { InputStackStyles } from './Account.tab.styles'

type formTab = (
  formik: FormikProps<any>,
  t: TFunction
) => {
  [key: string]: React.ReactNode
}

export const formTabs: formTab = (formik, t) => {
  const navigate = useNavigate()
  return {
    name: (
      <FormGroupComponent
        autoGrow
        variant='column'
        title={t('pages.account.editForm.username.title')}
        noDivisor
        content={
          <InputStackStyles direction='row'>
            <Input
              name='name'
              label={t('pages.account.editForm.username.firstName')}
              placeholder={t('pages.account.editForm.username.firstName')}
              onChange={formik.handleChange}
              fullWidth
            />
            <Input
              name='lastName'
              label={t('pages.account.editForm.username.lastName')}
              placeholder={t('pages.account.editForm.username.lastName')}
              onChange={() => console.log('!TODO: Handle last name edition')}
              fullWidth
            />
          </InputStackStyles>
        }
      />
    ),
    email: (
      <FormGroupComponent
        autoGrow
        variant='column'
        title={t('pages.account.editForm.email.title')}
        noDivisor
        content={
          <InputStackStyles>
            <Stack direction='row' spacing={4}>
              <Input
                name='currentEmail'
                label={t('pages.account.editForm.email.currentEmail')}
                placeholder={t('pages.account.editForm.email.currentEmail')}
                onChange={() => console.log('!TODO: Handle current email')}
                fullWidth
              />
              <Input name='.' style={{ visibility: 'hidden' }} />
            </Stack>
            <Stack direction='row' spacing={4}>
              <Input
                name='newEmail'
                label={t('pages.account.editForm.email.newEmail')}
                placeholder={t('pages.account.editForm.email.newEmail')}
                onChange={() => console.log('!TODO: Handle new email')}
                fullWidth
              />
              <Input
                name='confirmNewEmail'
                label={t('pages.account.editForm.email.confirmation')}
                placeholder={t('pages.account.editForm.email.confirmation')}
                onChange={() => console.log('!TODO: Handle confirm new email')}
                fullWidth
              />
            </Stack>
          </InputStackStyles>
        }
      />
    ),
    phone: (
      <FormGroupComponent
        autoGrow
        variant='column'
        title={t('pages.account.editForm.phone.title')}
        noDivisor
        content={
          <InputStackStyles>
            <Input
              name='phone'
              placeholder={t('pages.account.editForm.phone.newPhone')}
              onChange={() => console.log('!TODO: Handle mobile number')}
              fullWidth
            />
          </InputStackStyles>
        }
      />
    ),
    city: (
      <FormGroupComponent
        autoGrow
        variant='column'
        title={t('pages.account.editForm.address.title')}
        noDivisor
        content={
          <InputStackStyles>
            <Input
              name='streetName'
              label={t('pages.account.editForm.address.streetName')}
              placeholder={t('pages.account.editForm.address.streetName')}
              onChange={() => console.log('!TODO: Handle street name')}
              fullWidth
            />
            <Stack direction='row' spacing={4}>
              <Input
                name='zip'
                label={t('pages.account.editForm.address.zip')}
                placeholder={t('pages.account.editForm.address.zip')}
                onChange={() => console.log('!TODO: Handle postal code')}
                fullWidth
              />
              <Input
                name='postOffice'
                label={t('pages.account.editForm.address.postOffice')}
                placeholder={t('pages.account.editForm.address.postOffice')}
                onChange={() => console.log('!TODO: Handle post office')}
                fullWidth
              />
            </Stack>
          </InputStackStyles>
        }
      />
    ),
    password: (
      <FormGroupComponent
        variant='column'
        title={t('pages.account.editForm.password.title')}
        noDivisor
        noMargin
        footer={
          <Typography
            component='h6'
            onClick={() =>
              navigate(
                `/${ROUTES.AUTH}/${ROUTES.LOGIN}/${ROUTES.FORGOT_PASSWORD}`
              )
            }
          >
            {t('pages.account.editForm.password.forgotPassword')}
          </Typography>
        }
        content={
          <InputStackStyles>
            <Input
              name='currentPassword'
              label={t('pages.account.editForm.password.currentPassword')}
              placeholder={t('pages.account.editForm.password.currentPassword')}
              onChange={() => console.log('!TODO: Handle current password')}
              fullWidth
            />
            <Stack direction='row' spacing={4}>
              <Input
                name='newPassword'
                onChange={() => console.log('!TODO: Handle new password')}
                label={t('pages.account.editForm.password.newPassword')}
                placeholder={t('pages.account.editForm.password.newPassword')}
                fullWidth
              />
              <Input
                name='confirmNewPassword'
                onChange={() =>
                  console.log('!TODO: Handle confirm new password')
                }
                label={t('pages.account.editForm.password.confirmPassword')}
                placeholder={t(
                  'pages.account.editForm.password.confirmPassword'
                )}
                fullWidth
              />
            </Stack>
          </InputStackStyles>
        }
      />
    )
  }
}
