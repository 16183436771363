import { apiParent, ApiResponseTypes } from 'services'
import { SubscriptionPlanTypes } from './SubscriptionPlans.types'

export const getSubscriptionPlan = async (subscriptionId: string) => {
  const { data: response } = await apiParent.get<
    ApiResponseTypes<SubscriptionPlanTypes[]>
  >(`/subscriptionPlans/${subscriptionId}`)

  return response.data
}

export default {
  get: getSubscriptionPlan
}
