import { RefObject } from 'react'
import { useFocus, useHover } from './actions'

interface UseActionStatesTypes {
  error?: boolean
  focused?: boolean
  success?: boolean
  hovered?: boolean
}

export const useActionState = <T extends HTMLElement>(
  states: UseActionStatesTypes,
  focusInputRef: RefObject<T>,
  hoverElementRef?: RefObject<T>
) => {
  const { isElementFocused } = useFocus(focusInputRef)
  const { isElementHovered } = useHover(hoverElementRef || focusInputRef)

  const { error, focused, success, hovered } = states

  const hasSuccess = (focused && success) || success
  const hasError = ((focused && error) || error) && !hasSuccess
  const hasFocus = (focused || isElementFocused) && !hasError && !hasSuccess
  const hasHover = (isElementHovered && !hasFocus) || hovered

  return {
    hasSuccess: Boolean(hasSuccess),
    hasError: Boolean(hasError),
    hasHover: Boolean(hasHover),
    hasFocus
  }
}

export * from './actions'
