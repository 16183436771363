import { NavItemTypes, useLayout, useDevice } from '@eduplaytion/numetry-ui-kit'
import {
  AccountCircle,
  AccountCircleOutlined,
  EmojiEmotions,
  EmojiEmotionsOutlined,
  MenuOutlined,
  MenuTwoTone
} from '@mui/icons-material'

export const getNavItems = (): NavItemTypes[] => {
  const { asideMenuHandlers } = useLayout()
  const { isMobileDevice } = useDevice()

  return [
    {
      id: 0,
      name: 'main-menu',
      title: {
        main: 'navigation.mainMenu.title'
      },
      to: null,
      onClick: asideMenuHandlers.onToggle,
      icon: MenuOutlined,
      filledIcon: MenuTwoTone,
      hideInLeftNavigation: true,
      hideElement: !isMobileDevice
    },
    {
      id: 1,
      name: 'account',
      title: {
        main: 'navigation.account.title'
      },
      icon: AccountCircleOutlined,
      filledIcon: AccountCircle,
      to: '/account'
    },
    {
      id: 2,
      name: 'kids',
      title: {
        main: 'navigation.kids.title'
      },
      icon: EmojiEmotionsOutlined,
      filledIcon: EmojiEmotions,
      to: '/kids'
    }
  ]
}
