import { TabBarComponent } from '@eduplaytion/numetry-ui-kit'
import { LayoutComponent } from 'components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { AccountTabComponent } from './tabs/account/Account.tab'
import { InvoiceTabComponent } from './tabs/invoice/Invoice.tab'

export default () => {
  const { t } = useTranslation()
  return (
    <LayoutComponent
      header={{
        title: t('pages.account.accountOverview.title'),
        subtitle: t('pages.account.accountOverview.subtitle')
      }}
    >
      <TabBarComponent
        tabsProps={{
          sx: {
            border: (theme) => `0.1rem solid ${theme.palette.grey.A700}`
          }
        }}
        tabs={[
          {
            label: t('pages.account.tab.account'),
            element: <AccountTabComponent />
          },
          {
            label: t('pages.account.tab.invoice'),
            element: <InvoiceTabComponent />
          }
        ]}
      />
    </LayoutComponent>
  )
}

export * from './context/AccountPage.context'
