import React from 'react'
import { useAccountPage } from '../../context/AccountPage.context'
import { AccountInfoPanel } from './panels/AccountInfoPanel'
import { EditPanel } from './panels/EditPanel'
import { InsightPanel } from './panels/InsighPanel'
import { CancelAccountPanel } from './panels/CancelAccountPanel'
import { AccountTabContainerStyles } from './Account.tab.styles'

export const AccountTabComponent = () => {
  const { editPanel, isEdition } = useAccountPage()

  return (
    <AccountTabContainerStyles editPanel={editPanel} isEdition={isEdition}>
      <AccountInfoPanel />
      <EditPanel />
      <InsightPanel />
      <CancelAccountPanel />
    </AccountTabContainerStyles>
  )
}
