import * as yup from 'yup'
import i18n, { TFunction } from 'i18next'
import languageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import { translationEn } from './locales/en'
import { translationNn } from './locales/nn'
import { translationNb } from './locales/nb'
import { helperProcessor } from './postProcessor'

export function buildYupLocale(_: unknown, t: TFunction): void {
  yup.setLocale({
    mixed: {
      required: t('general.status.error.required')
    }
  })
}
const resources = {
  en: translationEn,
  nn: translationNn,
  nb: translationNb
}

declare module 'react-i18next' {
  interface CustomTypeOptions {
    defaultNS: 'nb'
    resources: typeof resources
  }
}

i18n.on('languageChanged', (language) => {
  document.documentElement.setAttribute('lang', language)
})

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .use(helperProcessor)
  .init({
    lng: 'nb',
    fallbackLng: 'nb',
    interpolation: {
      escapeValue: false
    },
    react: {
      transSupportBasicHtmlNodes: true
    },
    postProcess:
      process.env.REACT_APP_ENV === 'development'
        ? 'helperProcessor'
        : undefined,
    debug: process.env.REACT_APP_ENV === 'development',
    resources: {
      en: { translation: translationEn },
      nn: { translation: translationNn },
      nb: { translation: translationNb }
    }
  })

i18n.services.formatter?.add('lowercase', (value) => {
  return value.toLowerCase()
})
export { i18n }
