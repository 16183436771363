import styled from 'styled-components'
import { from } from '@eduplaytion/numetry-ui-kit'
import { AppBar, AppBarProps } from '@mui/material'

export const HeaderNumetryStyles = styled(AppBar)<AppBarProps>`
  background-color: ${({ theme }) => theme.palette.common.white};
  height: 5.5rem;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    height: 5rem;
  }

  ${from.md} {
    height: 7.7rem;
  }
`
