import { lazy } from 'react'

export const Checkout = lazy(() => import('views/checkout/Checkout'))

export const ContactInformation = lazy(
  () => import('views/checkout/subViews/contact/Contact')
)

export const MembershipSelection = lazy(
  () => import('views/checkout/subViews/membership/Membership')
)

export const LinkChildren = lazy(
  () => import('views/checkout/subViews/childrenAccount/ChildrenAccount')
)

export const ExistingAccount = lazy(
  () =>
    import(
      'views/checkout/subViews/childrenAccount/subViews/methods/_methods/existingAccount/ExistingAccount'
    )
)

export const NewAccount = lazy(
  () =>
    import(
      'views/checkout/subViews/childrenAccount/subViews/methods/_methods/newAccount/NewAccount'
    )
)

export const DoItLater = lazy(
  () =>
    import(
      'views/checkout/subViews/childrenAccount/subViews/doItLater/DoItLater'
    )
)

export const DoItLaterConfirmation = lazy(
  () =>
    import(
      'views/checkout/subViews/childrenAccount/subViews/doItLater/subViews/confirmation/Confirmation'
    )
)
export const LinkConfirmation = lazy(
  () =>
    import(
      'views/checkout/subViews/childrenAccount/subViews/methods/subViews/confirmation/Confirmation'
    )
)

export const Feedback = lazy(
  () =>
    import(
      'views/checkout/subViews/childrenAccount/subViews/methods/subViews/feedback/Feedback'
    )
)
export const KidAccountSelection = lazy(
  () =>
    import(
      'views/checkout/subViews/childrenAccount/subViews/gameChild/GameChild'
    )
)

export const PaymentConfirmation = lazy(
  () =>
    import(
      'views/checkout/subViews/membership/subViews/confirmation/Confirmation'
    )
)

export const DiscountLanding = lazy(
  () => import('views/checkout/subViews/discountLanding/DiscountLanding')
)
