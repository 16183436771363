import React, { useEffect, useRef } from 'react'
import { CircularProgress } from '@mui/material'
import { useInView } from 'framer-motion'

import { FCWithoutChildren } from '@types'
import { InfiniteButtonPropTypes } from './InfiniteButton.types'
import { InfiniteButtonStyles } from './InfiniteButton.styles'

export const InfiniteButtonComponent: FCWithoutChildren<
  InfiniteButtonPropTypes
> = ({ isFetchingNextPage, fetchNextPage, hasNextPage }) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const isInView = useInView(ref)

  useEffect(() => {
    if (isInView && hasNextPage) fetchNextPage()
  }, [isInView, hasNextPage])

  return (
    <InfiniteButtonStyles ref={ref}>
      {isFetchingNextPage && (
        <CircularProgress color='primary' size='3rem' sx={{ my: 2, mx: 3 }} />
      )}
    </InfiniteButtonStyles>
  )
}

export * from './InfiniteButton.types'
