import { apiParent, SubscriptionPlanTypes, ApiResponseTypes } from 'services'
import { makeQueryParams } from 'utils/makeQueryParams'

type SubscriptionPlansWithPromotionCodeParamTypes = {
  params: {
    promotionCode: 'none' | string
    language?: string
    currency?: string
  }
}

export const getSubscriptionPlansWithPromotionCode = async ({
  params: { currency, language, promotionCode }
}: SubscriptionPlansWithPromotionCodeParamTypes) => {
  const { data: response } = await apiParent.get<
    ApiResponseTypes<SubscriptionPlanTypes[]>
  >(
    `/subscriptionPlans/promotionCode/${promotionCode}${makeQueryParams({
      currency,
      language
    })}`
  )

  return response.data
}

export default {
  get: getSubscriptionPlansWithPromotionCode
}
