import styled, { css } from 'styled-components'
import { DialogTitle } from '@mui/material'

import { COLORS } from '@styles'
import { ModalHeaderPropTypes } from '@components'

export const ModalHeaderStyles = styled(DialogTitle)<ModalHeaderPropTypes>`
  padding: 3rem;
  display: flex;
  gap: 1.2rem;

  svg {
    font-size: 3rem;
    color: ${COLORS.dark};
  }

  ${({ sticky, backgroundColor }) =>
    sticky === 'top' &&
    css`
      background-color: ${backgroundColor};
      position: sticky;
      top: 0;
      z-index: 1;
    `}
`

export const ModalHeaderTitleStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`
