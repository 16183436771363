import React from 'react'
import { PopUpPropTypes } from '../PopUpModal.types'
import { PopUpFooterStyles } from '../PopUpModal.styles'
import { ActionGroupComponent } from '../components'

export const renderFooter = (popupData: PopUpPropTypes) => {
  const { footer } = popupData
  return (
    footer?.visible !== false && (
      <PopUpFooterStyles
        disablePadding={popupData.disablePadding}
        justifyContent={footer?.align}
      >
        {footer?.content
          ? footer.content(
              <ActionGroupComponent
                onConfirm={popupData.onConfirm}
                onClose={popupData.onClose}
                footer={footer}
              />
            )
          : footer && (
              <ActionGroupComponent
                onConfirm={popupData.onConfirm}
                onClose={popupData.onClose}
                footer={footer}
              />
            )}
      </PopUpFooterStyles>
    )
  )
}
