/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import { PostProcessorModule } from 'i18next'

interface ReactPostProcessorModule
  extends Omit<PostProcessorModule, 'process'> {
  process: (value: string, key: string) => JSX.Element | string
}

export const helperProcessor = {
  type: 'postProcessor',
  name: 'helperProcessor',
  process: (value, key) => {
    if (!sessionStorage.getItem('DEV_MODE')) return value

    if (
      /<\/?[\d\w]*>/gim.test(value) ||
      (key[0].includes('form') && key[0].includes('placeholder'))
    ) {
      return value
    }

    return (
      <span
        style={{
          outline: '2px solid grey'
        }}
        title={key}
        onClick={() => {
          window.open(
            `${process.env.REACT_APP_POEDITOR_DEFAULT_LIST_URL}&search=${key}`,
            '_blank'
          )
        }}
      >
        {value}
      </span>
    )
  }
} as ReactPostProcessorModule
