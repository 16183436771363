import { Box } from '@mui/material'
import styled, { css } from 'styled-components'
import { ColorKeyTypes, COLORS } from '@styles'
import { AlignTypes } from '../../PopUpModal.types'

export const StyledImage = styled.img`
  width: 16rem;
  height: 16rem;
  margin-bottom: 1.6rem;
  object-fit: cover;
`

export const ResponseModalStyles = styled(Box)<{
  transColor?: ColorKeyTypes
  align?: AlignTypes
  textColor?: ColorKeyTypes
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ${({ align }) =>
    align &&
    css`
      align-items: ${align};
    `}

  ${({ transColor = 'info' }) =>
    css`
      p {
        strong {
          font-weight: ${({ theme }) => theme.typography.fontWeightBold};
          color: ${COLORS[transColor]};
        }
      }
    `}
`
