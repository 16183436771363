import { StepperContextProvider } from '@context'
import React from 'react'
import { PopUpContentStyles } from '../PopUpModal.styles'
import { PopUpPropTypes } from '../PopUpModal.types'
import { ResponseModalTemplate, StepperModalTemplate } from '../templates'
import { renderFooter } from './renderFooter'

export const resolveTemplate = (data: PopUpPropTypes) => {
  if (!data.template)
    return (
      <>
        <PopUpContentStyles disablePadding={data.disablePadding}>
          {data.content}
        </PopUpContentStyles>
        {renderFooter(data)}
      </>
    )

  if (data.template === 'stepper')
    return (
      <StepperContextProvider steps={data.steps}>
        <StepperModalTemplate {...data} />
      </StepperContextProvider>
    )

  if (data.template === 'response')
    return (
      <>
        <ResponseModalTemplate {...data} />
        {renderFooter(data)}
      </>
    )
  return <div />
}
