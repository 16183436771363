import { apiParent } from 'services'
import {
  PromotionCodePayloadTypes,
  PromotionCodeResponseTypes
} from './PromotionCode.types'

export const postVerifyValidPromotionCode = async (
  payload: PromotionCodePayloadTypes
) => {
  const response = await apiParent.post<PromotionCodeResponseTypes>(
    '/promotionCode/verifyValidPromotionCode',
    payload
  )

  return response.status === 200
}

export default {
  post: postVerifyValidPromotionCode
}
