import { useMutation } from 'react-query'

import { PromotionCodePayloadTypes, PromotionCodeServices } from 'services'

type VerifyValidPromotionCodePayloadTypes = {
  promotionCode: PromotionCodePayloadTypes['promotionCode'] | null
}

export const useVerifyPromoCodeQuery = (
  payload: VerifyValidPromotionCodePayloadTypes
) => {
  const { data, ...query } = useMutation(
    ['/promotionCode/verifyValidPromotionCode', payload],
    () =>
      PromotionCodeServices.verifyValidPromotionCode.post({
        promotionCode: payload.promotionCode?.toLowerCase() || 'none'
      })
  )

  return {
    isValid: data || false,
    ...query
  }
}
