import React, { forwardRef } from 'react'
import { Typography } from '@mui/material'
import { LinkProps as RouterLinkProps, Link } from 'react-router-dom'

import { FCWithChildren } from '@types'

import { TabStyles } from './Tab.styles'
import { TabPropTypes } from './Tab.types'

const RouterLinkComponent = forwardRef<HTMLAnchorElement, RouterLinkProps>(
  (props, ref) => <Link ref={ref} tabIndex={-1} {...props} />
)

const a11yProps = (index: number) => {
  return {
    id: `tabs-router-${index}`,
    'aria-controls': `tabs-router-${index}`
  }
}

export const TabComponent: FCWithChildren<TabPropTypes> = ({
  label,
  to,
  index,
  ...rest
}) => {
  const Tab = (
    <TabStyles
      {...rest}
      tabIndex={0}
      label={<Typography>{label}</Typography>}
      {...(index && a11yProps(index))}
    />
  )

  return to ? <RouterLinkComponent to={to}>{Tab}</RouterLinkComponent> : Tab
}

export * from './Tab.types'
