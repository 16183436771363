import { AxiosError } from 'axios'
import { useMutation } from 'react-query'
import { ApiResponseTypes, ParentServices } from 'services'
import { useFeedback } from '@eduplaytion/numetry-ui-kit'

export const useDoItLater = () => {
  const { alert } = useFeedback()

  const doItLaterMutation = useMutation(ParentServices.doItLater.post, {
    onError(error: AxiosError<ApiResponseTypes<unknown>>) {
      alert.showError(error.response?.data.message || error.message)
    }
  })

  return doItLaterMutation
}
