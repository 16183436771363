import styled, { css } from 'styled-components'
import { StatusTypes } from '@eduplaytion/numetry-ui-kit'
import { StatusIndicatorPropTypes } from './StatusIndicator.types'

export const StatusIndicatorStyles = styled.div<StatusIndicatorPropTypes>`
  display: inline-block;
  min-width: 1.6rem;
  min-height: 1.6rem;
  border-radius: 50%;
  box-sizing: content-box;
  white-space: nowrap;

  ${({ status, theme }) => {
    if (status === StatusTypes.ACTIVE) {
      return css`
        background: ${theme.palette.success.main};
        border: 0.1rem solid ${theme.palette.success.dark};
      `
    }
    if (status === StatusTypes.PLANNED) {
      return css`
        background: ${theme.palette.warning.light};
        border: 0.1rem solid ${theme.palette.warning.dark};
      `
    }
    if (status === StatusTypes.COMPLETED) {
      return css`
        background: ${theme.palette.error.light};
        border: 0.1rem solid ${theme.palette.error.dark};
      `
    }
    return ''
  }}
`
