import React, { useCallback } from 'react'
import { PopUpComponent } from '@eduplaytion/numetry-ui-kit'
import { UseDisclosureTypes } from 'hooks'
import { AnimatePresence } from 'framer-motion'
import { InfoSectionComponent } from './views/info/Info'
import { PasswordSectionComponent } from './views/password/Password'
import { useEdition } from './context/EditionModal.context'
import { EditionModalContainerStyles } from './Edition.modal.styles'

export const EditionModalComponent: React.FC<UseDisclosureTypes> = ({
  isOpen,
  onClose
}) => {
  const { current, setCurrentView } = useEdition()
  const handleOnClose = useCallback(() => {
    if (current === 'info') return onClose()

    return setCurrentView('info')
  }, [current, setCurrentView, onClose])
  return (
    <PopUpComponent
      onClose={handleOnClose}
      open={isOpen}
      title='Manage kid'
      disablePadding
      content={
        <AnimatePresence exitBeforeEnter>
          <EditionModalContainerStyles
            key={current}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ type: 'spring', bounce: 3, damping: 4 }}
          >
            {current === 'info' ? (
              <InfoSectionComponent />
            ) : (
              <PasswordSectionComponent />
            )}
          </EditionModalContainerStyles>
        </AnimatePresence>
      }
    />
  )
}

export * from './context/EditionModal.context'
