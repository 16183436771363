import styled, { css } from 'styled-components'
import { Box } from '@mui/material'
import { COLORS } from '@styles'

import { HeaderProps } from './PopUpModal.types'

export const PopUpContainerStyles = styled(Box)`
  display: block;
  box-shadow: 0 -0.4rem 2rem ${COLORS.black}29;
  height: max-content;
`
export const PopUpHeaderStyles = styled(Box)<HeaderProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1.8rem;
  color: ${({ theme }) => theme.palette.common.black};
  background: ${({ theme }) => theme.palette.common.white};
  border-bottom: 0.2rem solid ${({ theme }) => theme.palette.grey.A700};
`
export const PopUpContentStyles = styled(Box)<{ disablePadding?: boolean }>`
  padding: ${({ disablePadding }) => (!disablePadding ? 2.8 : 0)}rem;
  background: ${COLORS.white};
`
export const PopUpFooterStyles = styled(Box)<{ disablePadding?: boolean }>`
  background: ${COLORS.white};
  display: flex;
  min-width: 100%;
  ${({ disablePadding }) =>
    !disablePadding &&
    css`
      padding: 2.8rem;
      padding-top: 0;
    `}
`
