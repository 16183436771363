import React from 'react'

import { COLORS } from '@styles'

import { DualDropArrowStyles } from './DualDropArrow.styles'
import { DualDropArrowPropTypes } from './DualDropArrow.types'

export const DualDropArrowComponent = ({
  activeDir = 'both'
}: DualDropArrowPropTypes) => {
  const disabledOpacity = 0.2

  return (
    <DualDropArrowStyles>
      <svg
        version='1.1'
        id='Layer_1'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        x='0px'
        y='0px'
        viewBox='0 0 9 12'
        xmlSpace='preserve'
      >
        <g>
          <path
            id='arrow-up'
            fill={COLORS.dark}
            d='M4.42,12l-4-5h8L4.42,12z'
            opacity={activeDir === 'asc' ? disabledOpacity : 1}
          />
          <path
            id='arrow-down'
            fill={COLORS.dark}
            d='M4.42,0l4,5h-8L4.42,0z'
            opacity={activeDir === 'desc' ? disabledOpacity : 1}
          />
        </g>
      </svg>
    </DualDropArrowStyles>
  )
}

export * from './DualDropArrow.types'
