import styled from 'styled-components'

export const InvoiceTableHeaderStyles = styled.div`
  border-bottom: 0.1rem solid ${({ theme }) => theme.palette.grey.A700};
  padding: 2rem;
`

export const InvoiceTableContainerStyles = styled.div`
  min-height: 60vh;
  background: ${({ theme }) => theme.palette.common.white};
  border: 0.1rem solid ${({ theme }) => theme.palette.grey.A700};
`
