import axios from 'axios'

import { serviceInterceptors } from 'services/utils'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL
})

serviceInterceptors(api)

const apiParent = axios.create({
  baseURL: process.env.REACT_APP_API_PARENT_URL
})

serviceInterceptors(apiParent)

export { apiParent, api }
export * from './lib'
