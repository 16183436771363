import styled, { css } from 'styled-components'

export const ChipListContainerStyles = styled.div<{ isMoreActive?: boolean }>`
  display: flex;
  flex-flow: row nowrap;

  gap: 0.5rem;
  padding: 0 1.6rem 1.6rem;

  .MuiChip-root:not(:last-child) {
    max-width: calc(50% - 0.5rem) !important;
  }

  ${({ isMoreActive }) =>
    isMoreActive &&
    css`
      .MuiChip-root:not(:last-child) {
        max-width: calc(50% - 2.5rem) !important;
      }
    `};
`
