import React from 'react'
import { motion } from 'framer-motion'
import { BasicTransitionOptions } from '../transitions.types'

export const fadeInUpTransition = {
  initial: {
    opacity: 0,
    translateY: 10
  },
  animate: {
    opacity: 1,
    translateY: 0
  },
  exit: {
    opacity: 0,
    translateY: 10
  }
}

export const FadeInUpTransition: React.FC<BasicTransitionOptions> = ({
  children,
  delay
}) => (
  <motion.span
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      gap: '3.2rem',
      height: '100%'
    }}
    transition={{
      delay
    }}
    {...fadeInUpTransition}
  >
    {children}
  </motion.span>
)
