import React, { createContext, useContext, useMemo } from 'react'
import { FCWithChildren } from '@types'
import { DialogPropTypes } from '../Dialog.types'

const DialogContext = createContext<DialogPropTypes>({} as DialogPropTypes)

export const DialogContextWrapper: FCWithChildren<DialogPropTypes> = (
  props
) => {
  const { children } = props
  const value = useMemo(() => props, [props])

  return (
    <DialogContext.Provider value={value}>{children}</DialogContext.Provider>
  )
}

export const useDialogContext = () => {
  const context = useContext(DialogContext)
  if (!context) {
    throw new Error('undefined context')
  }
  return context
}
