import { RefObject, useEffect, useState } from 'react'

export const useHover = <T extends HTMLElement>(ref: RefObject<T>) => {
  const [isElementHovered, setIsElementHovered] = useState(false)

  const node = ref.current
  const hasNode = node && 'addEventListener' in node

  const onMouseOver = () => setIsElementHovered(true)
  const onMouseOut = () => setIsElementHovered(false)

  useEffect(() => {
    if (hasNode) {
      node.addEventListener('mouseover', onMouseOver)
      node.addEventListener('mouseout', onMouseOut)

      return () => {
        node.removeEventListener('mouseover', onMouseOver)
        node.removeEventListener('mouseout', onMouseOut)
      }
    }

    return undefined
  }, [ref.current])

  return { isElementHovered }
}
