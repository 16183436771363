import React, { ReactElement } from 'react'

import { HighlightTextStyles } from './HighlightText.styles'

import { HighlightTextPropTypes } from './HighlightText.types'

export const HighlightTextComponent = ({
  text = '',
  highlight = '',
  color
}: HighlightTextPropTypes): ReactElement | string => {
  const cleanedText = text.replaceAll('<', '').replaceAll('>', '')

  if (!highlight.trim()) {
    return cleanedText
  }
  const regex = new RegExp(`(${highlight})`, 'gi')
  const parts = cleanedText.split(regex)

  return (
    <>
      {parts.filter(String).map((part) => {
        return regex.test(part) ? (
          <HighlightTextStyles color={color} key={part}>
            {part}
          </HighlightTextStyles>
        ) : (
          part
        )
      })}
    </>
  )
}
