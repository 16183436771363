import { useStepper } from '@eduplaytion/numetry-ui-kit'
import { Stack } from '@mui/material'
import React, { useEffect } from 'react'
import { useCreateStep } from '../../context/CreateStep.context'
import { PlanComponent } from './components/plan/Plan'

const PLANS = [
  {
    id: '3',
    title: 'Numetry Månedlig',
    description: `per barn, per måned \n\n*199 kr faktureres månedlig`,
    value: '199 kr'
  },
  {
    id: '2',
    title: 'Numetry Halvårlig',
    description: `per barn, per måned \n\n*899 kr faktureres halvårlig`,
    value: '150 kr'
  },
  {
    id: '1',
    title: 'Numetry Årlig',
    description: `per barn, per måned \n\n*1 499 kr faktureres årlig`,
    value: '125 kr'
  }
]

export const PlanStepComponent = () => {
  const { formik } = useCreateStep()
  const { setBlock } = useStepper()

  useEffect(() => {
    if (formik.values.plan) setBlock(true)
  }, [formik.values.plan])
  return (
    <Stack spacing={2}>
      {PLANS.map((data) => (
        <PlanComponent
          key={`plan-list-item${data.id}`}
          onClick={() => formik.setFieldValue('plan', data)}
          checked={formik.values.plan === data}
          {...data}
        />
      ))}
    </Stack>
  )
}
