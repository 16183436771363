import { Button } from '@eduplaytion/numetry-ui-kit'
import {
  AccountBalanceOutlined,
  AccountCircleOutlined,
  ChevronRight,
  MailOutlined,
  PhoneIphoneOutlined,
  VpnKeyOutlined
} from '@mui/icons-material'
import { InputBase, Tooltip } from '@mui/material'
import { FormGroupComponent, FormGroupItemPropTypes } from 'components'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useAccountPage } from 'views/account/Account'
import {
  EditionIconStyles,
  InformationPanelStyles,
  ButtonGroupStyles
} from '../Account.tab.styles'

export const AccountInfoPanel = () => {
  const { t } = useTranslation()
  const { formik, isEdition, setEditPanel, setEditing } = useAccountPage()
  const readyOnlyAccountData: FormGroupItemPropTypes[] = useMemo(
    () => [
      {
        icon: AccountCircleOutlined,
        title: t('pages.account.accountOverview.form.name'),
        name: 'name'
      },
      {
        icon: MailOutlined,
        title: t('pages.account.accountOverview.form.email'),
        name: 'email'
      },
      {
        icon: PhoneIphoneOutlined,
        title: t('pages.account.accountOverview.form.phone'),
        name: 'phone'
      },
      {
        icon: AccountBalanceOutlined,
        title: t('pages.account.accountOverview.form.city'),
        name: 'city'
      },
      {
        icon: VpnKeyOutlined,
        title: t('pages.account.accountOverview.form.password'),
        name: 'password'
      }
    ],
    [t]
  )
  return (
    <InformationPanelStyles
      disablePadding
      maxWidth='50%'
      border={({ palette }) => `0.1rem solid ${palette.grey.A700}`}
      borderRight='none'
    >
      {readyOnlyAccountData.map(({ name = '_default', ...settings }, index) => {
        const type = name !== 'password' ? 'text' : 'password'
        return (
          <FormGroupComponent
            key={`form-group-${name}-${index.toString()}`}
            name={name}
            variant='column'
            rightAdornment={
              isEdition ? (
                <Tooltip
                  title={`${t('general.actions.edit')} ${settings.title}`}
                  leaveDelay={200}
                  arrow
                  placement='left'
                >
                  <EditionIconStyles
                    style={{ opacity: isEdition ? 1 : 0 }}
                    onClick={() => setEditPanel(name)}
                    size='large'
                    color='primary'
                  >
                    <ChevronRight />
                  </EditionIconStyles>
                </Tooltip>
              ) : (
                <div />
              )
            }
            content={
              <InputBase
                type={type}
                name={name}
                fullWidth
                disabled
                readOnly
                defaultValue={formik.values[name as keyof typeof formik.values]}
              />
            }
            {...settings}
          />
        )
      })}

      <ButtonGroupStyles>
        <Button
          onClick={() => {
            setEditPanel('name')
            setEditing((cur) => !cur)
          }}
          disabled={isEdition}
          variant='contained'
          color='primary'
        >
          {t('pages.account.editAccount')}
        </Button>
      </ButtonGroupStyles>
    </InformationPanelStyles>
  )
}
