import React, { forwardRef } from 'react'
import { TransitionProps } from '@mui/material/transitions'
import { Slide } from '@mui/material'

import { FCWithChildren } from '@types'
import { ModalPropTypes } from './Modal.types'
import { ModalStyles, ModalContentStyles } from './Modal.styles'

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />
})

export const ModalComponent: FCWithChildren<ModalPropTypes> = ({
  children,
  zIndexPriority = 'low',
  ...modalProps
}) => {
  return (
    <ModalStyles
      {...modalProps}
      zIndexPriority={zIndexPriority}
      open={modalProps.open}
      TransitionComponent={Transition}
      closeAfterTransition
      keepMounted
    >
      <ModalContentStyles sx={{ padding: 0 }}>{children}</ModalContentStyles>
    </ModalStyles>
  )
}

export * from './Modal.types'
export * from './components'
