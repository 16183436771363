import React from 'react'
import { NavLink } from 'react-router-dom'
import { SvgIcon, Typography, useMediaQuery, useTheme } from '@mui/material'

import { useTranslation } from 'react-i18next'
import { FCWithChildren } from '@types'
import { AsideItemPropTypes } from './AsideItem.types'
import { AsideContainerStyles } from './AsideItem.styles'

export const AsideItemComponent: FCWithChildren<AsideItemPropTypes> = ({
  item,
  isCollapsed = false
}) => {
  const { icon, title, filledIcon, hideElement } = item
  const { t } = useTranslation()

  const isMobileScreen = useMediaQuery(useTheme().breakpoints.down('md'))

  if (hideElement) {
    return null
  }

  const isMobileCollapsed = isMobileScreen ? false : isCollapsed

  return (
    <NavLink
      to={item.to || '#'}
      onClick={(e) => {
        if (!item.to) {
          e.preventDefault()
        }
      }}
    >
      {({ isActive }) => (
        <AsideContainerStyles
          isActive={item.to ? isActive : false}
          isCollapsed={isMobileCollapsed}
          onClick={() => {
            if (!item.to && item.onClick) {
              item.onClick()
            }
          }}
        >
          <SvgIcon
            component={filledIcon && isActive ? filledIcon : icon}
            color='inherit'
            fontSize='medium'
          />
          <Typography color='inherit' fontWeight='bold'>
            {isMobileScreen && title.mobile ? t(title.mobile) : t(title.main)}
          </Typography>
        </AsideContainerStyles>
      )}
    </NavLink>
  )
}

export * from './AsideItem.types'
