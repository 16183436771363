import { LocaleBaseType } from '@eduplaytion/numetry-ui-kit'
import { POObjectFormatTypes } from './convertToPO.types'

const parseGroup = (
  group: string | 'auth' | 'general' | 'components' | 'navigation' | 'pages'
) => {
  if (group === 'auth') {
    return 'Term related with the authentication flow'
  }
  if (group === 'general') {
    return 'General use term'
  }
  if (group === 'components') {
    return 'Term used in specifc component'
  }
  if (group === 'navigation') {
    return 'Term used on the navigation menus and page titles'
  }
  if (group === 'pages') {
    return 'Term used inside specifc page'
  }

  return `Term from ${group}`
}
const loopKeys = (
  group: string,
  parent: Record<string, string | object>,
  sectionKeys: string[]
): POObjectFormatTypes[] =>
  sectionKeys.map((key) => {
    const value = parent[key]
    const keyType = typeof value

    if (keyType === 'object' && value !== null) {
      return loopKeys(
        `${group}.${key}`,
        parent[key] as unknown as Record<string, string>,
        Object.keys(parent[key])
      ).flat()
    }

    const hasPlural = key.includes('_one')
    const isPlural = key.includes('_other')

    if (isPlural) return undefined

    const POObject: POObjectFormatTypes = {
      term: key,
      definition: !hasPlural
        ? (value as string)
        : {
            one: value as string,
            other: parent[key.replace('_one', '_other')] as string
          },
      context: `${group}.${key}`,
      reference: parseGroup(group),
      comment: ''
    }

    return POObject
  }) as POObjectFormatTypes[]

export const convertToPO = (json: LocaleBaseType) => {
  if (!json) return undefined

  const sections = Object.keys(json) as (keyof LocaleBaseType)[]

  const parsedArr = sections
    .map((section) => {
      const sectionKeys =
        typeof json[section] === 'object' ? Object.keys(json[section]) : []

      return loopKeys(section as string, json[section], sectionKeys).flat()
    })
    .flat()

  return parsedArr
}
