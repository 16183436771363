import { useMemo } from 'react'
import { useMutation } from 'react-query'
import { ParentServices, PaymentServices } from 'services'

export const usePaymentQuery = () => {
  const postParentQuery = useMutation(ParentServices.post, {
    mutationKey: 'payment/parent'
  })

  const agreementQuery = useMutation(PaymentServices.agreement.get, {
    mutationKey: 'payment/agreement'
  })

  const getPaymentDataByPinCode = useMutation(
    PaymentServices.getPaymentDataByPinCode,
    {
      mutationKey: 'payment/data/pinCode'
    }
  )

  return useMemo(
    () => ({
      postParentQuery,
      agreementQuery,
      getPaymentDataByPinCode
    }),
    [postParentQuery, agreementQuery, getPaymentDataByPinCode]
  )
}
