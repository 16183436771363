import { Box, Radio, Typography } from '@mui/material'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { HighlightStyles, PlanStyles } from './Plan.styles'
import { PlanItemPropTypes } from './Plan.types'

export const PlanComponent: React.FC<PlanItemPropTypes> = ({
  title,
  description,
  value,
  highlight,
  disableBorders,
  ...rest
}) => (
  <PlanStyles
    className='PlanComponent-root'
    disableBorders={disableBorders}
    selected={rest.checked}
    onClick={() => rest.onClick && rest.onClick()}
  >
    <Box className='Left'>
      <Radio color='primary' {...rest} />
      <Typography className='Title'>{title}</Typography>
      {highlight && <HighlightStyles>Mest for pengene</HighlightStyles>}
    </Box>
    <Typography className='Description'>
      <ReactMarkdown remarkPlugins={[remarkGfm]}>{description}</ReactMarkdown>
    </Typography>
    <Box className='Right'>
      <Typography className='Value'>{value}</Typography>
    </Box>
  </PlanStyles>
)
