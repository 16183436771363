import styled from 'styled-components'

export const PlanStyles = styled.div<{
  selected?: boolean
  disableBorders?: boolean
}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  width: 100%;
  cursor: pointer;
  outline: none;
  background: ${({ theme }) => theme.palette.common.white};

  ${({ disableBorders, theme }) =>
    !disableBorders && `border: 0.15rem solid ${theme.palette.grey.light};`}
  border-bottom: 0.15rem solid ${({ theme }) => theme.palette.grey.light};
  border-left: 0.2rem solid
    ${({ selected, disableBorders, theme }) => {
      if (selected) {
        return theme.palette.secondary.main
      }

      if (disableBorders) {
        return theme.palette.common.white
      }
      return theme.palette.grey.A700
    }};
  border-radius: 0.4rem;

  .Description {
    text-align: left;
    margin-right: 2rem;
    margin-left: 2rem;
  }
  .MuiBox-root {
    display: flex;
    flex-direction: row;
    align-items: center;

    .Title {
      font-weight: ${({ theme }) => theme.typography.fontWeightBold};
    }

    .Value {
      font-weight: ${({ theme }) => theme.typography.fontWeightExtraBold};
      font-size: ${({ theme }) => theme.typography.h5.fontSize};
      text-align: right;
    }
  }
`

export const HighlightStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 2rem;
  margin-left: 1rem;
  color: ${({ theme }) => theme.palette.common.white};
  font-weight: ${({ theme }) => theme.typography.fontWeightExtraBold};
  font-size: ${({ theme }) => theme.typography.subtitle2.fontSize};
  font-family: 'Arboria';
  width: max-content;

  background: ${({ theme }) => theme.palette.secondary.main};
  border-radius: 20rem;
`
