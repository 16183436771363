import { useFeedback } from '@eduplaytion/numetry-ui-kit'
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react'
import { useCookies } from 'react-cookie'
import { AuthContextTypes, FeideFetchStatusTypes } from './Auth.context.types'

export const AuthContext = createContext<AuthContextTypes>(
  {} as AuthContextTypes
)

export const useAuth = () => useContext(AuthContext)

export const AuthContextWrapper: React.FC = ({ children }) => {
  const [user, setUser] = useState<any>()
  const [cookies, , removeCookie] = useCookies()
  const [feideFetchStatus, setFeideFetchStatus] =
    useState<FeideFetchStatusTypes>(null)

  // TODO: return to null
  const [hasCookie, setHasCookie] = useState<boolean | null>(false)
  const { alert } = useFeedback()

  const logoutQuery = () => null
  const doLogout = useCallback(async () => {
    // TODO:
    logoutQuery()
  }, [logoutQuery])

  const userInfoQuery = () => null

  const checkCookie = useCallback(async () => {
    // TODO:
    userInfoQuery()
  }, [userInfoQuery])

  const auth = useMemo(
    () => ({
      user,
      setUser,
      feideFetchStatus,
      hasCookie,
      checkCookie,
      doLogout
    }),
    [
      setUser,
      user,
      feideFetchStatus,
      setFeideFetchStatus,
      doLogout,
      hasCookie,
      cookies,
      checkCookie
    ]
  )

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}
