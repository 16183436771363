import { apiParent } from 'services'
import { ParentPayloadTypes, ParentResponseTypes } from './Parent.types'

export const postParent = async ({
  state,
  payload,
  pk_vid
}: ParentPayloadTypes) => {
  const { data: response } = await apiParent.post<ParentResponseTypes>(
    `/parent?state=${state}${pk_vid ? `&pk_vid=${pk_vid}` : ''}`,
    payload
  )

  return response
}

export default {
  post: postParent
}
