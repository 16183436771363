import { CheckCircleTwoTone } from '@mui/icons-material'
import styled, { css } from 'styled-components'
import { from } from '../../theme'
import { CardPropTypes } from './Card.types'

export const CardContainerStyles = styled.div<{
  outlined?: CardPropTypes['outlined']
}>`
  background: ${({ theme }) => theme.palette.common.white};
  border-radius: 0.8rem;
  border: 0.1rem solid ${({ theme }) => theme.palette.grey[100]};
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  height: max-content;

  &:hover,
  &:focus-within {
    box-shadow: 0 0.3rem 2rem ${({ theme }) => theme.palette.grey[50]};
  }

  .MuiSkeleton-root {
    transform: initial;
    border-radius: 0;
  }

  ${({ outlined = false, theme }) =>
    outlined &&
    css`
      outline: 0.3rem solid ${theme.palette.secondary.main};
    `}
`

export const ImageContainerStyles = styled.div`
  img {
    display: none;
  }

  ${from.sm} {
    img {
      display: initial;
      min-width: 27rem;
      min-height: 14.4rem;
      width: 100%;
      height: 100%;
    }
  }
`
export const TextContainerStyles = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 0.6rem;
  padding: 1.6rem;

  .MuiTypography-body2 {
    display: flex;
    align-items: center;
    gap: 0.6rem;
    img {
      border-radius: 50%;
      width: 2.5rem;
      height: 2.5rem;
    }

    ${from.sm} {
      img {
        display: none;
      }
    }
  }
`
export const ContentStyles = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
`

export const CheckCircleTwoToneStyles = styled(CheckCircleTwoTone)`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: ${({ theme }) => theme.palette.common.white};
  border-radius: 50%;
`

export const FooterStyles = styled.div`
  padding: 0.6rem;
`

export const DescriptionStyles = styled.div`
  overflow: hidden;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  word-break: break-all;
  box-orient: vertical;
  -webkit-box-orient: vertical;

  p {
    hyphens: manual;
    word-break: keep-all;
  }
`
