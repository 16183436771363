import React, { useRef } from 'react'
import { Box, Divider, useMediaQuery, useTheme } from '@mui/material'

import { ROUTES } from '@router'
import { useOverflow } from '@hooks'
import { NumetrySkoleLogoSVG } from '@assets'

import { FCWithoutChildren } from '@types'
import {
  LanguageSelectorComponent,
  LeftNavigationComponent,
  UserSettingsComponent
} from './components'

import {
  HeaderButtonsStyles,
  LayoutHeaderStyles,
  LogoLinkStyles
} from './Header.styles'
import { HeaderPropTypes } from './Header.types'

export const HeaderComponent: FCWithoutChildren<HeaderPropTypes> = ({
  items,
  headerButtonsAdornment,
  user
}) => {
  const headerRef = useRef<HTMLDivElement | null>(null)
  const isMobileScreen = useMediaQuery(useTheme().breakpoints.down('md'))
  const isHeaderOverflown = useOverflow(headerRef)

  return (
    <LayoutHeaderStyles id='header' ref={headerRef}>
      <Box display='flex' alignItems='center' gap={3}>
        {isMobileScreen && items && (
          <LeftNavigationComponent
            items={items}
            isHeaderOverflow={isHeaderOverflown}
          />
        )}
        <LogoLinkStyles to={ROUTES.BASE_ROOT}>
          <img src={NumetrySkoleLogoSVG} alt='Numetry Logo' />
        </LogoLinkStyles>
      </Box>

      <HeaderButtonsStyles isHeaderOverflow={isHeaderOverflown}>
        {headerButtonsAdornment}
        <LanguageSelectorComponent />
        <Divider orientation='vertical' flexItem />
        <UserSettingsComponent user={user} />
      </HeaderButtonsStyles>
    </LayoutHeaderStyles>
  )
}

export * from './Header.types'
