import { useStep } from 'hooks'
import React, { createContext, useContext, useMemo } from 'react'
import {
  StepperContextTypes,
  StepperContextWrapperPropTypes
} from './Stepper.context.types'

const StepperContext = createContext({} as StepperContextTypes)

export const useStepper = () => useContext(StepperContext)

export const StepperContextWrapper: React.FC<
  StepperContextWrapperPropTypes
> = ({ children, useStepProps }) => {
  const stepper = useStep(useStepProps)

  const ctxValue = useMemo(
    () => ({
      stepper
    }),
    [stepper]
  )

  return (
    <StepperContext.Provider value={ctxValue}>
      {children(stepper)}
    </StepperContext.Provider>
  )
}
