/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
type WindowWithDataLayer = Window & {
  dataLayer: Record<string, any>[]
}

declare const window: WindowWithDataLayer

export const useGtmDL = () => {
  const push = (
    event: string,
    data?: Record<string, string | number | boolean>
  ) => {
    if (!window?.dataLayer) {
      console.error('GTM not found')
      return
    }

    window.dataLayer.push({
      event,
      ...data
    })
  }

  return {
    push,
    dataLayer: window.dataLayer
  }
}
