import React, { ReactChild, ReactChildren } from 'react'
import { RecoilRoot } from 'recoil'
import {
  LayoutContextWrapper,
  LanguageContextWrapper,
  FeedbackContextWrapper,
  LANGUAGES_DATA
} from '@eduplaytion/numetry-ui-kit'
import { i18n } from 'i18n'
import { DevModeHandler } from 'hooks'
import { AuthContextWrapper } from '.'

interface GlobalContextProps {
  children: ReactChild | ReactChildren
}

export const GlobalContextWrapper = ({ children }: GlobalContextProps) => (
  <RecoilRoot>
    <LayoutContextWrapper>
      <LanguageContextWrapper
        i18nProviderProps={i18n}
        languages={LANGUAGES_DATA}
      >
        <AuthContextWrapper>
          <FeedbackContextWrapper>
            {process.env.REACT_APP_ENV === 'development' ? (
              <DevModeHandler>{children}</DevModeHandler>
            ) : (
              children
            )}
          </FeedbackContextWrapper>
        </AuthContextWrapper>
      </LanguageContextWrapper>
    </LayoutContextWrapper>
  </RecoilRoot>
)
