import { AxiosError } from 'axios'
import { useMutation } from 'react-query'
import { ApiResponseTypes, ParentServices } from 'services'
import { useFeedback as useLayoutFeedback } from '@eduplaytion/numetry-ui-kit'

export const useParentFeedback = () => {
  const { alert } = useLayoutFeedback()

  const feedBackMutation = useMutation(ParentServices.feedBack.post, {
    onError(error: AxiosError<ApiResponseTypes<unknown>>) {
      alert.showError(error.response?.data.message || error.message)
    }
  })

  return feedBackMutation
}
