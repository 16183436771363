import { Button } from '@eduplaytion/numetry-ui-kit'
import { Box } from '@mui/material'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useAccountPage } from 'views/account/Account'
import { formTabs } from '../Account.tab.instance'
import { EditionPanelStyles, ButtonGroupStyles } from '../Account.tab.styles'

export const EditPanel = () => {
  const { t } = useTranslation()
  const { formik, isEdition, editPanel, setEditing } = useAccountPage()
  const tabs = useMemo(() => formTabs(formik, t), [formik, t])

  return (
    <EditionPanelStyles
      isOpen={isEdition}
      border={({ palette }) => `0.1rem solid ${palette.grey.A700}`}
    >
      <Box>
        <AnimatePresence exitBeforeEnter>
          <motion.div
            key={editPanel}
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.25 }}
          >
            {tabs[editPanel]}
          </motion.div>
        </AnimatePresence>

        <ButtonGroupStyles>
          <Button
            onClick={() => {
              setEditing((cur) => !cur)
              formik.handleSubmit()
            }}
            disabled={!isEdition}
            variant='contained'
            color='primary'
          >
            {t('pages.account.accountOverview.form.save')}
          </Button>
          <Button
            onClick={() => setEditing((cur) => !cur)}
            disabled={!isEdition}
          >
            {t('general.actions.cancel')}
          </Button>
        </ButtonGroupStyles>
      </Box>
    </EditionPanelStyles>
  )
}
