/* eslint-disable react/no-unstable-nested-components */
import {
  DisplayHeaderComponent,
  SearchComponent,
  TableComponent
} from '@eduplaytion/numetry-ui-kit'
import { Box } from '@mui/material'
import { format } from 'date-fns'
import { useCreateTable } from 'hooks'
import React from 'react'
import {
  InvoiceTableContainerStyles,
  InvoiceTableHeaderStyles
} from './Invoice.tab.styles'

type InvoiceType = {
  id: number
  date: string
  plan: string
  total: number
}
const invoiceList: InvoiceType[] = [
  {
    id: 1,
    date: new Date().toISOString(),
    plan: 'Månedlig - 89,00 x 2',
    total: 178
  },
  {
    id: 2,
    date: new Date().toISOString(),
    plan: 'Månedlig - 89,00 x 2',
    total: 2480
  }
]
export const InvoiceTabComponent = () => {
  const table = useCreateTable<InvoiceType>()

  const columns = [
    table.accessor('date', {
      id: 'date',
      header: () => <DisplayHeaderComponent title='#!Date' />,
      cell: (props) => format(new Date(props.getValue()), 'dd/MM/yyyy')
    }),
    table.accessor('plan', {
      id: 'plan',
      header: () => <DisplayHeaderComponent title='#!Plan' />,
      cell: (props) => props.getValue()
    }),
    table.accessor('total', {
      id: 'total',
      header: () => <DisplayHeaderComponent title='#!Total' />,
      cell: (props) =>
        props.getValue().toLocaleString('en-US', { minimumFractionDigits: 2 })
    })
  ]
  return (
    <InvoiceTableContainerStyles>
      <InvoiceTableHeaderStyles>
        <Box width='30%'>
          <SearchComponent name='invoiceSearch' />
        </Box>
      </InvoiceTableHeaderStyles>
      <TableComponent data={invoiceList} columns={columns} />
    </InvoiceTableContainerStyles>
  )
}
