import { Divider, DividerProps } from '@mui/material'
import styled from 'styled-components'
import { from, to } from '@eduplaytion/numetry-ui-kit'

export const LoginFormContainerStyles = styled.div`
  position: relative;
  z-index: 100;

  max-width: 90%;
  width: 38rem;
  ${to.md} {
    top: 9%;
  }
`

export const CharactersStyles = styled.img`
  position: absolute;
  top: -17vh;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
  height: 17vh;
  width: auto;
  max-height: 21rem;
  ${from.md} {
    display: none;
  }
`

export const LoginDividerStyles = styled(Divider)<DividerProps>`
  ::before,
  ::after {
    top: 0;
    border-top: 0.1rem solid ${({ theme }) => theme.palette.royalBlue.light};
  }

  .MuiDivider-wrapper {
    color: ${({ theme }) => theme.palette.info.main};
    font-size: ${({ theme }) => theme.typography.subtitle1.fontSize};
    font-weight: ${({ theme }) => theme.typography.fontWeightBold};
    text-transform: lowercase;
  }
`

export const LoginFormStyles = styled.div`
  background-color: ${({ theme }) => theme.palette.common.white};

  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;

  .MuiButton-root {
    width: 100%;
    font-size: ${({ theme }) => theme.typography.subtitle1.fontSize};
  }
`
