import React, { useCallback, useEffect, useState } from 'react'
import {
  Button,
  LocaleBaseType,
  useFeedback,
  useLanguage
} from '@eduplaytion/numetry-ui-kit'
import { HotKeys } from 'react-hotkeys'
import { Stack, Typography } from '@mui/material'
import locales from 'i18n/locales'
import { useRecoilState } from 'recoil'
import { devMode } from 'atoms/devMode'
import { convertToPO } from './utils/convertToPO'
import { DevModeOverlayStyles } from './DevMode.styles'

export const DevModeHandler: React.FC = ({ children }) => {
  const { changeLanguage, language } = useLanguage()
  const [target, setTarget] = useState<{ x: number; y: number }>()
  const [isEnabled, setEnabled] = useRecoilState(devMode)

  const { alert, dialog } = useFeedback()

  const handleDevMode = () => {
    if (isEnabled) {
      setEnabled(false)
      sessionStorage.removeItem('DEV_MODE')
      alert.showError('Exiting dev-mode')
      changeLanguage(language.iso639)
      return
    }

    setEnabled(true)
    changeLanguage(language.iso639)
    sessionStorage.setItem('DEV_MODE', 'true')
    alert.showSuccess('Entering dev-mode')
  }

  const handleTranslationInspection = () => {
    if (target) {
      const targetElement = document.elementFromPoint(
        target?.x,
        target.y
      ) as Element & { style?: string }

      if (!targetElement) return

      targetElement.childNodes.forEach((n) => {
        const node = n as Element

        if (node.localName === 'span') {
          // eslint-disable-next-line no-useless-escape
          const regex = /<span[^>]*?title=(["\'])?((?:.(?!\1|>))*.?)\1?/gm
          const translationKey = regex
            .exec(String(node.outerHTML))
            ?.find(
              (where) => !where.includes('span') && where.split('.').length > 1
            )

          if (!translationKey) return

          targetElement.style = `border: 2px solid red; border-radius: 5px`

          setTimeout(() => {
            const confirmationBox = window.confirm(
              `Would you like to open the selected term on POEditor's website? \n\n Term key: ${translationKey}`
            )

            if (confirmationBox) {
              window.open(
                `${process.env.REACT_APP_POEDITOR_DEFAULT_LIST_URL}&search=${translationKey}`,
                '_blank'
              )
            }
            targetElement.style = undefined
          }, 100)
        }
      })
    }
  }

  const handleMouseMove = useCallback((event: MouseEvent) => {
    setTarget({
      x: event.clientX,
      y: event.clientY
    })
  }, [])

  const translationExportMenu = () => {
    const link = document.createElement('a')
    const handleExport = () => {
      Object.keys(locales).forEach((key) => {
        const dataStr = `data:text/json;charset=utf-8,${encodeURIComponent(
          JSON.stringify(
            locales[key as keyof typeof locales] as LocaleBaseType,
            null,
            2
          )
        )}`
        link.href = dataStr
        link.download = `${key}.json`
        link.click()
      })
    }

    const handleExportAsPoEditor = () => {
      console.log(Object.keys(locales))
      Object.keys(locales).forEach((key) => {
        const dataStr = `data:text/json;charset=utf-8,${encodeURIComponent(
          JSON.stringify(
            convertToPO(locales[key as keyof typeof locales]),
            null,
            2
          )
        )}`
        link.href = dataStr
        link.download = `${key} - POEditor Converted.json`
        link.click()
      })
    }

    dialog.show({
      content: {
        template: 'response',
        variant: 'noHeader',
        content: (
          <Stack>
            <Button onClick={() => handleExport()}>
              Export Internal Locales (React i18n format)
            </Button>
            <Button onClick={() => handleExportAsPoEditor()}>
              Export Internal Locales (POEditor format)
            </Button>
          </Stack>
        )
      }
    })
  }

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove)

    return () => {
      document.removeEventListener('mousemove', handleMouseMove)
    }
  }, [handleMouseMove])

  const getHandlers = () => {
    return {
      TOGGLE_DEV_MODE: () => handleDevMode(),
      ...(isEnabled
        ? {
            INSPECT_TEXT: () => handleTranslationInspection(),
            TRANSLATION_EXPORT_MENU: () => translationExportMenu()
          }
        : {})
    }
  }

  return (
    <HotKeys
      keyMap={{
        TOGGLE_DEV_MODE: 'alt+shift+d',
        INSPECT_TEXT: 'alt+v',
        TRANSLATION_EXPORT_MENU: 'alt+shift+v'
      }}
      handlers={getHandlers()}
      allowChanges
    >
      {children}
      {isEnabled && (
        <DevModeOverlayStyles>
          <Typography>development build</Typography>
        </DevModeOverlayStyles>
      )}
    </HotKeys>
  )
}
