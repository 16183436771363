import { Radio, RadioProps, Typography } from '@mui/material'
import React from 'react'
import { ItemStyles } from './Item.styles'

export const ItemComponent: React.FC<RadioProps & { text: string }> = ({
  text,
  ...rest
}) => (
  <ItemStyles onClick={rest.onClick}>
    <Radio color='primary' {...rest} />
    <Typography>{text}</Typography>
  </ItemStyles>
)
