import { FormGroupComponent } from 'components'
import { Input } from '@eduplaytion/numetry-ui-kit'
import React from 'react'
import { useCreateStep } from '../../context/CreateStep.context'
import { FormContainerStyles } from './Connect.step.styles'

export const ConnectStepComponent = () => {
  const {
    formik: { values, ...formik }
  } = useCreateStep()
  return (
    <FormContainerStyles>
      <FormGroupComponent
        variant='row'
        title='Username'
        content={
          <Input
            name='user.username'
            onChange={formik.handleChange}
            value={values?.user?.username}
            placeholder='Create an username'
          />
        }
      />
      <FormGroupComponent
        variant='row'
        title='Password'
        noDivisor
        content={
          <Input
            type='password'
            name='user.password'
            onChange={formik.handleChange}
            value={values?.user?.password}
            placeholder='Create a password'
          />
        }
      />
    </FormContainerStyles>
  )
}
