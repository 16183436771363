import { DialogActions } from '@mui/material'
import styled from 'styled-components'

export const DialogFooterStyles = styled(DialogActions)`
  padding: 0;
  box-shadow: 0 -0.4rem 2rem ${({ theme }) => theme.palette.common.black}0F;
  padding: 1.6rem 2.4rem;

  div {
    width: 100%;
  }
`
