import { FormControlLabel, FormControlLabelProps } from '@mui/material'
import styled from 'styled-components'
import { COLORS } from '@styles'

export const ControlLabelStyles = styled(
  FormControlLabel
)<FormControlLabelProps>`
  .MuiFormControlLabel-label {
    font-size: 1.2rem;
    margin-left: 0.8rem;
    color: ${COLORS.blackAlt};
  }
`
