import styled, { css } from 'styled-components'

export const DatePickerContainerStyles = styled.div<{ fullWidth?: boolean }>`
  width: 100%;

  .MuiInputBase-root {
    width: 100%;
  }

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      .MuiFormControl-root {
        width: 100%;
      }
    `}
`
