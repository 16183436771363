import { IconButton, styled } from '@mui/material'
import { COLORS } from '@styles'

export const AsideMoodItemStyles = styled(IconButton)<{ isActive: boolean }>`
  color: ${COLORS.dark};

  .MuiSvgIcon-root {
    font-size: 3rem;
  }

  :hover {
    color: ${COLORS.secondary};
  }

  ${({ isActive }) =>
    isActive &&
    `
      color: ${COLORS.secondary};
    `};
`
