import React, { useRef } from 'react'
import { useActionState } from '@hooks'
import { FCWithChildren } from '@types'
import { RadioButtonStyles } from './Radio.styles'
import { RadioPropTypes } from './Radio.types'

export const RadioComponent: FCWithChildren<RadioPropTypes> = ({
  error,
  success,
  hovered,
  focused,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const { hasError, hasFocus, hasHover, hasSuccess } = useActionState(
    { error, success, hovered, focused },
    inputRef
  )

  return (
    <RadioButtonStyles
      inputRef={inputRef}
      error={hasError}
      focused={hasFocus}
      hovered={hasHover}
      success={hasSuccess}
      {...rest}
    />
  )
}

export * from './Radio.types'
