import { Breakpoint, Dialog } from '@mui/material'
import styled, { css } from 'styled-components'
import { DialogPropTypes } from './Dialog.types'

export const DialogStyles = styled(Dialog)<DialogPropTypes>`
  :empty {
    display: flex;
  }

  .MuiDialog-container {
    &:focus-within,
    &:focus {
      outline: none !important;
    }
  }

  ${({ fullScreen, height, theme }) => {
    const dialogHeight: { [key in Breakpoint | 'min' | 'max']: string } = {
      min: 'min-content',
      max: 'max-content',
      xs: '45rem',
      xl: '65vh',
      sm: '75vh',
      md: '85vh',
      lg: '95vh'
    }

    if (fullScreen) return ``

    return css`
      z-index: ${theme.zIndex.modal};
      .MuiPaper-root {
        height: ${dialogHeight[height || 'min']};
      }
    `
  }}
`
