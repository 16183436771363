import React from 'react'
import { useTranslation } from 'react-i18next'
import { PaginationItem, Typography } from '@mui/material'

import { PaginationStyles } from './Pagination.styles'
import { PaginationPropTypes } from './Pagination.types'

export const PaginationComponent: React.FC<PaginationPropTypes> = ({
  ...rest
}) => {
  const { t } = useTranslation()

  const previousText = () => (
    <Typography>{t('components.table.pagination.previous')}</Typography>
  )

  const nextText = () => (
    <Typography>{t('components.table.pagination.next')}</Typography>
  )

  return (
    <PaginationStyles
      {...rest}
      variant='text'
      shape='rounded'
      color='secondary'
      renderItem={(item) => (
        <PaginationItem
          {...item}
          components={{ previous: previousText, next: nextText }}
        />
      )}
    />
  )
}
