import { apiParent, ApiResponseTypes } from 'services'
import { KidAccountTypes, LinkParentToKidPayloadTypes } from './Kid.types'

export const postParentToKid = async (payload: LinkParentToKidPayloadTypes) => {
  const { data: response } = await apiParent.post<
    ApiResponseTypes<KidAccountTypes>
  >('/v2/parent/kid', payload)

  return response.data
}

export default {
  post: postParentToKid
}
