import { css } from 'styled-components'

import muliFont200 from '../../assets/fonts/muli/Muli-ExtraLight.woff'
import muliFont300 from '../../assets/fonts/muli/Muli-Light.woff'
import muliFont400 from '../../assets/fonts/muli/Muli-Regular.woff'
import muliFont600 from '../../assets/fonts/muli/Muli-SemiBold.woff'
import muliFont700 from '../../assets/fonts/muli/Muli-Bold.woff'
import muliFont800 from '../../assets/fonts/muli/Muli-ExtraBold.woff'
import muliFont900 from '../../assets/fonts/muli/Muli-Black.woff'

import arboriaFont100 from '../../assets/fonts/arboria/Arboria-Thin.ttf'
import arboriaFont300 from '../../assets/fonts/arboria/Arboria-Light.ttf'
import arboriaFont400 from '../../assets/fonts/arboria/Arboria-Book.ttf'
import arboriaFont500 from '../../assets/fonts/arboria/Arboria-Medium.ttf'
import arboriaFont700 from '../../assets/fonts/arboria/Arboria-Bold.ttf'
import arboriaFont900 from '../../assets/fonts/arboria/Arboria-Black.ttf'

import rajdhaniFont300 from '../../assets/fonts/rajdhani/Rajdhani-Light.ttf'
import rajdhaniFont400 from '../../assets/fonts/rajdhani/Rajdhani-Regular.ttf'
import rajdhaniFont500 from '../../assets/fonts/rajdhani/Rajdhani-Medium.ttf'
import rajdhaniFont600 from '../../assets/fonts/rajdhani/Rajdhani-SemiBold.ttf'
import rajdhaniFont700 from '../../assets/fonts/rajdhani/Rajdhani-Bold.ttf'

import veneraFont900 from '../../assets/fonts/venera/Venera-Black.otf'

export const FONT_WEIGHTS = {
  thin: 100,
  extraThin: 200,
  light: 300,
  normal: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  extraBold: 800,
  black: 900
} as const

export type FontWeightTypes = typeof FONT_WEIGHTS
export type FontWeightKeyTypes = keyof FontWeightTypes

type NumericFontWeights<T> = {
  [K in keyof T]: T[K] extends number ? T[K] : never
}[keyof T]

type FontWeightValueTypes = NumericFontWeights<FontWeightTypes>

type FontFamilyTypes = {
  name: 'Arboria' | 'Muli' | 'Rajdhani' | 'Venera'
  weights: {
    [Key in FontWeightValueTypes]?: string
  }
  format: 'truetype' | 'woff' | 'opentype'
}

export const FONT_FAMILIES: FontFamilyTypes[] = [
  {
    name: 'Muli',
    weights: {
      [FONT_WEIGHTS.extraThin]: muliFont200,
      [FONT_WEIGHTS.light]: muliFont300,
      [FONT_WEIGHTS.normal]: muliFont400,
      [FONT_WEIGHTS.semiBold]: muliFont600,
      [FONT_WEIGHTS.bold]: muliFont700,
      [FONT_WEIGHTS.extraBold]: muliFont800,
      [FONT_WEIGHTS.black]: muliFont900
    },
    format: 'woff'
  },
  {
    name: 'Arboria',
    weights: {
      [FONT_WEIGHTS.thin]: arboriaFont100,
      [FONT_WEIGHTS.light]: arboriaFont300,
      [FONT_WEIGHTS.normal]: arboriaFont400,
      [FONT_WEIGHTS.medium]: arboriaFont500,
      [FONT_WEIGHTS.bold]: arboriaFont700,
      [FONT_WEIGHTS.black]: arboriaFont900
    },
    format: 'truetype'
  },
  {
    name: 'Rajdhani',
    weights: {
      [FONT_WEIGHTS.light]: rajdhaniFont300,
      [FONT_WEIGHTS.normal]: rajdhaniFont400,
      [FONT_WEIGHTS.medium]: rajdhaniFont500,
      [FONT_WEIGHTS.semiBold]: rajdhaniFont600,
      [FONT_WEIGHTS.bold]: rajdhaniFont700
    },
    format: 'truetype'
  },
  {
    name: 'Venera',
    weights: {
      [FONT_WEIGHTS.black]: veneraFont900
    },
    format: 'opentype'
  }
]

interface FontFaceOptionTypes {
  fontWeight: FontWeightKeyTypes
}

const generateFontFace = (
  name: FontFamilyTypes['name'],
  options: FontFaceOptionTypes
) => {
  const font = FONT_FAMILIES.find((font) => font.name === name)

  if (!font) throw new Error('Font not found')

  const fontName = font.name
  const fontWeight = FONT_WEIGHTS[options.fontWeight]
  const fontPath = font.weights[fontWeight]
  const fontFormat = font.format

  return css`
    @font-face {
      font-family: ${fontName};
      font-weight: ${fontWeight};
      src: local(${fontName}), url(${fontPath}) format(${fontFormat});
      font-style: normal;
      font-display: swap;
    }
  `
}

export const genericTypography = () => css`
  /* Muli */
  ${generateFontFace('Muli', {
    fontWeight: 'extraThin'
  })}
  ${generateFontFace('Muli', {
    fontWeight: 'light'
  })}
  ${generateFontFace('Muli', {
    fontWeight: 'normal'
  })}
  ${generateFontFace('Muli', {
    fontWeight: 'semiBold'
  })}
    ${generateFontFace('Muli', {
    fontWeight: 'bold'
  })}
  ${generateFontFace('Muli', {
    fontWeight: 'extraBold'
  })}
  ${generateFontFace('Muli', {
    fontWeight: 'black'
  })}

  /* Arboria */
  ${generateFontFace('Arboria', {
    fontWeight: 'thin'
  })}
  ${generateFontFace('Arboria', {
    fontWeight: 'light'
  })}
  ${generateFontFace('Arboria', {
    fontWeight: 'normal'
  })}  
  ${generateFontFace('Arboria', {
    fontWeight: 'medium'
  })}
  ${generateFontFace('Arboria', {
    fontWeight: 'bold'
  })}
  ${generateFontFace('Arboria', {
    fontWeight: 'black'
  })}

  /* Rajdhani */
  ${generateFontFace('Rajdhani', {
    fontWeight: 'light'
  })}
  ${generateFontFace('Rajdhani', {
    fontWeight: 'normal'
  })}
  ${generateFontFace('Rajdhani', {
    fontWeight: 'medium'
  })}
  ${generateFontFace('Rajdhani', {
    fontWeight: 'semiBold'
  })}
  ${generateFontFace('Rajdhani', {
    fontWeight: 'bold'
  })}

  /* Venera */
  ${generateFontFace('Venera', {
    fontWeight: 'black'
  })}
`
