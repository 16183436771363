import parentMethods from './Parent'
import parentCreateKidMethods from './kid/Parent.createKid'

import { KidServices } from './kid'
import { DoItLaterServices } from './doItLater'
import { FeedbackServices } from './feedBack'

export const ParentServices = {
  ...parentMethods,
  kid: KidServices,
  createKid: {
    ...parentCreateKidMethods
  },
  feedBack: FeedbackServices,
  doItLater: DoItLaterServices
}

export * from './Parent.types'
