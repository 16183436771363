import styled from 'styled-components'

export const PasswordSectionStyles = styled.div`
  display: flex;
  flex-direction: column;

  .MuiInputBase-root {
    padding: 1rem;
    margin: 0 0 2rem;
  }
  .MuiLink-root {
    font-size: ${({ theme }) => theme.typography.subtitle2.fontSize};
    color: ${({ theme }) => theme.palette.info.main};
    text-decoration-color: ${({ theme }) => theme.palette.info.main};
    margin-left: 2rem;
    margin-top: -1.5rem;
    margin-bottom: 2rem;
  }
`
