import React, { useRef } from 'react'
import { Typography, IconButton, Tooltip } from '@mui/material'
import { HelpOutlineOutlined } from '@mui/icons-material'

import { PopperComponent } from '@components'

import { FCWithoutChildren } from '@types'
import { DisplayHeaderContainerStyles } from './DisplayHeader.styles'
import { DisplayHeaderPropTypes } from './DisplayHeader.types'

export const DisplayHeaderComponent: FCWithoutChildren<
  DisplayHeaderPropTypes
> = ({ title, showHelperIcon }) => {
  const titleRef = useRef<HTMLParagraphElement>(null)

  const isEllipsisActive = () => {
    const offsetWidth = titleRef.current?.offsetWidth || 0
    const scrollWidth = titleRef.current?.scrollWidth || 0

    return offsetWidth < scrollWidth
  }

  return (
    <Tooltip
      title={title}
      placement='top'
      disableHoverListener={!isEllipsisActive()}
      arrow
    >
      <DisplayHeaderContainerStyles direction='row'>
        <Typography noWrap ref={titleRef}>
          {title}
        </Typography>
        {showHelperIcon && (
          <PopperComponent {...showHelperIcon}>
            <IconButton color='info'>
              {showHelperIcon.helperIcon || <HelpOutlineOutlined />}
            </IconButton>
          </PopperComponent>
        )}
      </DisplayHeaderContainerStyles>
    </Tooltip>
  )
}

export * from './DisplayHeader.types'
