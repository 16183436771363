import { Stack } from '@mui/material'
import React, { useEffect } from 'react'
import { ReactComponent as Vippslogo } from 'assets/images/paymentMethods/vipps.svg'
import { useStepper } from '@eduplaytion/numetry-ui-kit'
import { useCreateStep } from '../../context/CreateStep.context'
import { PaymentMethodComponent } from './components/payment/Payment'

export const PaymentStepComponent = () => {
  const { formik } = useCreateStep()

  const { setBlock } = useStepper()

  useEffect(() => {
    if (formik.values.paymentMethod) setBlock(true)
  }, [formik.values.paymentMethod])

  return (
    <Stack spacing={2}>
      <PaymentMethodComponent
        checked={formik.values.paymentMethod === 'vipps'}
        onClick={() => formik.setFieldValue('paymentMethod', 'vipps')}
      >
        <Vippslogo />
      </PaymentMethodComponent>
      <PaymentMethodComponent
        checked={formik.values.paymentMethod === 'Betalingskort'}
        onClick={() => formik.setFieldValue('paymentMethod', 'Betalingskort')}
      >
        Betalingskort
      </PaymentMethodComponent>
    </Stack>
  )
}
