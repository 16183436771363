import { Typography } from '@mui/material'
import React, { SyntheticEvent, useState } from 'react'
import { FCWithChildren } from '@types'

import {
  TabBarHeaderStyles,
  TabBarStyles,
  TabRenderElementStyles,
  TabStyles
} from './TabBar.styles'
import { TabsPropTypes } from './TabBar.types'

export const TabBarComponent: FCWithChildren<TabsPropTypes> = ({
  tabs,
  isStickedOnTop,
  tabsProps,
  onTabIndexChange
}) => {
  const [value, setValue] = useState(0)

  const handleChange = (_event: SyntheticEvent, newValue: number) => {
    setValue(newValue)
    if (onTabIndexChange) onTabIndexChange(newValue)
  }

  return (
    <TabBarStyles>
      <TabBarHeaderStyles
        value={value}
        onChange={handleChange}
        indicatorColor='primary'
        textColor='primary'
        variant={tabsProps?.variant || 'scrollable'}
        scrollButtons='auto'
        allowScrollButtonsMobile
        isStickedOnTop={isStickedOnTop}
        {...tabsProps}
      >
        {tabs.map(
          ({ label, disabled, hideElement }, index) =>
            !hideElement && (
              <TabStyles
                key={`tab-${label}`}
                label={<Typography>{label}</Typography>}
                id={`tab-${index}`}
                tabIndex={0}
                aria-controls={`tabpanel-${index}`}
                disabled={disabled}
              />
            )
        )}
      </TabBarHeaderStyles>

      {tabs.map(({ element, label }, index) => (
        <TabRenderElementStyles
          key={`tabpanel-${label}`}
          role='tabpanel'
          hidden={value !== index}
          id={`tabpanel-${index}`}
          aria-labelledby={`tab-${index}`}
        >
          {value === index && element}
        </TabRenderElementStyles>
      ))}
    </TabBarStyles>
  )
}
