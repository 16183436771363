import { DialogContent } from '@mui/material'
import styled from 'styled-components'

export const DialogBodyStyles = styled(DialogContent)`
  position: relative;
  padding: 0;
  display: flex;
  flex-direction: column;
  scrollbar-gutter: stable;
`
