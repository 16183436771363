import { apiParent } from 'services'

export const getVerifyFreeTrial = async (parentEmail: string) => {
  const response = await apiParent.get<unknown>(
    `/subscription/freeTrial/${parentEmail}`
  )

  return response.status === 200
}

export default {
  get: getVerifyFreeTrial
}
