import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import React from 'react'
import {
  FormGroupItemBodyStyles,
  FormGroupItemStyles
} from './FormGroup.styles'
import { FormGroupItemPropTypes } from './FormGroup.types'
import { RowTemplateComponent } from './templates/Row'
import { ColumnTemplateComponent } from './templates/Column'

export const FormGroupComponent: React.FC<FormGroupItemPropTypes> = ({
  title,
  icon,
  content,
  contentInline,
  wrap,
  footer,
  autoSizing,
  alignContent,
  noDivisor,
  divisorColor,
  padding,
  iconSize,
  alignItems,
  rightAdornment = null,
  name,
  variant = 'row',
  autoGrow
}) => {
  return (
    <FormGroupItemStyles
      alignItems={alignItems}
      container
      name={name}
      flexWrap='wrap'
      padding={padding}
      divisorColor={divisorColor}
      noDivisor={noDivisor}
      variant={variant}
      className='FormGroupItem-root'
      autoGrow={autoGrow}
    >
      {content && (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          {variant === 'row' ? (
            <RowTemplateComponent
              iconSize={iconSize}
              icon={icon}
              title={title}
              contentInline={contentInline}
              content={content}
              autoSizing={autoSizing}
              wrap={wrap}
              alignContent={alignContent}
              rightAdornment={rightAdornment}
            />
          ) : (
            <ColumnTemplateComponent
              iconSize={iconSize}
              icon={icon}
              title={title}
              contentInline={contentInline}
              content={content}
              autoSizing={autoSizing}
              wrap={wrap}
              alignContent={alignContent}
              rightAdornment={rightAdornment}
            />
          )}
        </LocalizationProvider>
      )}
      {footer && (
        <FormGroupItemBodyStyles item xs={12}>
          {footer}
        </FormGroupItemBodyStyles>
      )}
    </FormGroupItemStyles>
  )
}
