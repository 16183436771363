import { DOMAttributes } from 'react'
import { FormHelperTextProps } from '@mui/material'

export interface OptionTypes {
  label: string
  value: string
}

export type OnChangePropTypes = OptionTypes & { name: string }

export type SelectActionTypes = {
  success?: boolean
  focused?: boolean
  hovered?: boolean
  error?: boolean
}
export type SelectModeTypes = 'default' | 'status' | 'reports'

export interface SelectPropTypes
  extends Omit<DOMAttributes<HTMLDivElement>, 'onChange'>,
    SelectActionTypes {
  name: string
  placeholder: string
  activePlaceholder?: string
  options: OptionTypes[]
  onChange: (target: OnChangePropTypes) => void
  defaultOption?: OptionTypes
  onClearClick?: DOMAttributes<HTMLButtonElement>['onClick']
  helperText?: string
  enableSearch?: boolean
  searchPlaceholder?: string
  enableArrowMark?: boolean
  mode?: SelectModeTypes
  fullWidth?: boolean
  disabled?: boolean
  value?: unknown
}

export type SelectHelperTypes = FormHelperTextProps & SelectActionTypes

export enum StatusTypes {
  ACTIVE = 'active',
  PLANNED = 'planned',
  COMPLETED = 'completed'
}
