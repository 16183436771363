import { Box } from '@mui/material'
import styled from 'styled-components'

export const ResponseModalStyles = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .MuiContainer-root {
    padding: 0;
  }
`

export const FooterContainerStyles = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 0.1rem solid ${({ theme }) => theme.palette.grey[100]};
  padding: 2rem 3rem;
`
