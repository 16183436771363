import { Icon, Typography } from '@mui/material'
import React from 'react'
import {
  FormGroupContentStyles,
  FormGroupItemBodyStyles,
  FormGroupItemTitleStyles,
  IconHolderStyles
} from '../FormGroup.styles'
import { FormGroupItemPropTypes } from '../FormGroup.types'

export const RowTemplateComponent: React.FC<FormGroupItemPropTypes> = ({
  iconSize,
  icon,
  title,
  contentInline,
  content,
  autoSizing,
  wrap,
  alignContent,
  rightAdornment
}) => {
  const getLgSize = () => {
    if (autoSizing) return 'auto'
    if (wrap) return 12
    return 4
  }
  return (
    <>
      <FormGroupItemTitleStyles
        item
        className='ItemTitle-root'
        xs={12}
        variant='row'
        lg={getLgSize()}
        iconSize={iconSize}
      >
        {(icon || title) && (
          <IconHolderStyles className='IconHolder-root'>
            {icon && <Icon color='primary' component={icon} />}
            {title && <Typography>{title}</Typography>}
          </IconHolderStyles>
        )}
        {contentInline && (
          <FormGroupItemBodyStyles item xs={12} lg={12}>
            {content}
          </FormGroupItemBodyStyles>
        )}
        <span>{rightAdornment}</span>
      </FormGroupItemTitleStyles>
      {!contentInline && (
        <FormGroupItemBodyStyles
          alignContent={alignContent}
          item
          xs={12}
          lg={autoSizing ?? 7}
        >
          <FormGroupContentStyles>{content}</FormGroupContentStyles>
        </FormGroupItemBodyStyles>
      )}
    </>
  )
}
