import React from 'react'
import { CssBaseline, responsiveFontSizes } from '@mui/material'
import { createTheme, ThemeOptions, ThemeProvider } from '@mui/material/styles'

import { FCWithChildren } from '@types'

import { palette } from './palette'
import { typography } from './typography'
import { breakpoints } from './breakpoints'
import { mixins } from './mixins'

import { MuiComponents as components } from './components'
import { StyledThemeWrapper } from './StyledTheme'

export const MuiTheme = {
  palette,
  components,
  typography,
  breakpoints,
  mixins
}

export const MuiThemeWrapper: FCWithChildren<{ theme?: ThemeOptions }> = ({
  children,
  theme = MuiTheme
}) => {
  let currentTheme = createTheme(theme)

  currentTheme = responsiveFontSizes(currentTheme)

  return (
    <ThemeProvider theme={currentTheme}>
      <StyledThemeWrapper>
        <CssBaseline />
        {children}
      </StyledThemeWrapper>
    </ThemeProvider>
  )
}
