import styled, { css } from 'styled-components'
import { Radio } from '@mui/material'
import { COLORS } from '@styles'
import { RadioPropTypes } from './Radio.types'

export const RadioButtonStyles = styled(Radio)<RadioPropTypes>`
  color: ${COLORS.grey}80;
  transition: all 0.2s;

  &.Mui-checked {
    color: ${(props) => props.color || COLORS.secondary};
    transition: all 0.2s;
  }

  ${({ error }) =>
    error &&
    css`
      color: ${COLORS.danger}80;
      &.Mui-checked {
        color: ${COLORS.danger};
      }
    `}

  ${({ success }) =>
    success &&
    css`
      color: ${COLORS.success}80;
      &.Mui-checked {
        color: ${COLORS.success};
      }
    `}
    
  ${({ focused }) =>
    focused &&
    css`
      color: ${COLORS.infoLight};
    `}
`
