import styled, { css } from 'styled-components'

export const LayoutStyles = styled.div<{ unstyled?: boolean }>`
  display: flex;

  flex-direction: column;

  ${({ unstyled }) =>
    !unstyled &&
    css`
      padding: 0 3rem;
    `}

  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`
