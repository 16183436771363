import { Switch } from '@mui/material'
import styled, { css } from 'styled-components'
import { COLORS, inputState } from '@styles'
import { SwitchPropTypes } from './Switch.types'

export const SwitchStyles = styled(Switch)<SwitchPropTypes>`
  padding: 0;
  margin: 0;
  width: 4.8rem;
  height: 2.6rem;

  .MuiButtonBase-root {
    .MuiSwitch-thumb {
      width: 1.8rem;
      height: 1.8rem;
      box-shadow: 0 0.3rem 0.6rem ${COLORS.black}29;
      color: ${COLORS.white};
      transition: all 0.2s;
    }

    .Mui-checked {
      .MuiSwitch-thumb {
        color: ${COLORS.white};
        transition: all 0.2s;
      }
    }
  }

  .MuiSwitch-track {
    border-radius: 1.6rem;
    opacity: 1 !important;
    border: 0.1rem solid ${({ theme }) => theme.palette.grey[100]};
    background-color: ${COLORS.lightGrey};
    transition: all 0.2s;
  }

  .MuiSwitch-switchBase {
    padding: 0.3rem;
    border: 0.1rem solid transparent;
    transition: all 0.2s;
  }

  .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: ${COLORS.info};
    transition: all 0.2s;
  }

  .MuiTouchRipple-root {
    display: none;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      .MuiSwitch-track {
        border-color: transparent;
      }
      .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
        background-color: ${COLORS.grey};
      }
    `};

  ${({ error }) =>
    error &&
    css`
      .MuiSwitch-track {
        border-color: ${COLORS.danger};
        background-color: ${COLORS.dangerLight}20;
      }
      .MuiButtonBase-root {
        .MuiSwitch-thumb {
          color: ${COLORS.danger};
        }
      }
      .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
        background-color: ${COLORS.dangerLight}70;
      }
    `};

  ${({ success }) =>
    success &&
    css`
      .MuiSwitch-track {
        border-color: ${COLORS.success};
        background-color: ${COLORS.successLight}20;
      }

      .MuiButtonBase-root {
        .MuiSwitch-thumb {
          color: ${COLORS.success};
        }
      }
      .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
        background-color: ${COLORS.successLight}70;
      }
    `};

  ${({ hovered }) =>
    hovered &&
    css`
      .MuiSwitch-track {
        background-color: ${COLORS.lighterGrey};
      }
    `};

  ${({ focused }) =>
    focused &&
    css`
      .MuiSwitch-track {
        background-color: ${COLORS.infoLight}40;
        ${inputState.focused(focused)}
      }
      .MuiButtonBase-root {
        .MuiSwitch-thumb {
          border: 0.1rem solid ${COLORS.infoLight};
        }
      }
    `}
`
