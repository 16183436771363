import React from 'react'
import { ConnectStepComponent } from './connect/Connect.step'
import { CreateStepComponent } from './create/Create.step'
import { KidDetailsComponent } from './kidDetails/KidDetails.step'
import { PaymentStepComponent } from './payment/Payment.step'
import { PlanStepComponent } from './plans/Plan.step'

export default {
  link: [
    {
      name: 'create',
      component: <CreateStepComponent />
    },
    {
      name: 'connect',
      component: <ConnectStepComponent />
    }
  ],
  create: [
    {
      name: 'create',
      component: <CreateStepComponent />
    },
    {
      name: 'kidDetails',
      component: <KidDetailsComponent />
    },
    {
      name: 'plan',
      component: <PlanStepComponent />
    },
    {
      name: 'payment',
      component: <PaymentStepComponent />
    }
  ]
}
