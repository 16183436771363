import { ROUTES, RouteTypes } from 'router'

import {
  matchRoutes,
  RouteMatch,
  RouteObject,
  useLocation
} from 'react-router-dom'

export type UseCurrentPathTypes = {
  viewName: RouteTypes
}

const routes = Object.values(ROUTES).map(
  (route): RouteObject => ({ path: route })
)

export const useCurrentPath = (): UseCurrentPathTypes => {
  const location = useLocation()
  const [{ route }] = (matchRoutes(routes, location) ||
    []) as RouteMatch<RouteTypes>[]

  return {
    viewName: (route?.path || 'default') as RouteTypes
  }
}
