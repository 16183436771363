import { AuthLocaleTypes } from '../locales.types'

const authLocaleNo: AuthLocaleTypes = {
  login: 'Logg Inn',
  logout: 'Logg ut',
  signup: 'Registrer deg',
  signout: 'Logg ut',
  withFeide: 'Logg inn med Feide',
  email: 'E-post',
  emailPlaceholder: 'døme@email.com',
  password: 'Passord',
  forgotYourPassword: 'Glemt passordet?',
  enterYourEmail: 'Skriv inn email adressen din',
  sendPasswordResetLink: 'Send tilsending av tilbakestillingslenke for passord'
}

export default authLocaleNo
