import { ErrorBoundary } from '@sentry/react'
import { useAuth } from 'context'
import { AnimatePresence } from 'framer-motion'
import {
  DashboardLayout,
  PopUpComponent,
  useFeedback,
  useLayout
} from '@eduplaytion/numetry-ui-kit'
import React, { Suspense, useEffect } from 'react'
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes
} from 'react-router-dom'
import {
  BackdropComponent,
  renderLoginRoutes,
  renderLogoutRoutes,
  renderAccountRoutes,
  renderCheckoutRoutes,
  renderKidsRoutes,
  ROUTES as PATH_ROUTES,
  renderOneNokRoute
} from 'router'

import { getNavItems } from './utils/getNavItems'

export const Router: React.FC = () => {
  const { BASE_ROOT, UNKNOWN, AUTH, CHECKOUT, LOGIN, ACCOUNT } = PATH_ROUTES

  const { isPageLoading } = useLayout()

  const { hasCookie, checkCookie } = useAuth()
  const { dialog } = useFeedback()

  return (
    <AnimatePresence>
      {dialog.handlers.isOpen && (
        <PopUpComponent
          {...dialog.content}
          open
          onClose={dialog.handlers.onClose}
          zIndexPriority='high'
        />
      )}
      <Suspense fallback={<BackdropComponent open />}>
        <ErrorBoundary>
          <BackdropComponent open={isPageLoading} />
          <BrowserRouter>
            <Routes>
              {/* {hasCookie === false && (
                <>
                  <Route index element={<Navigate to={`${AUTH}/${LOGIN}`} />} />
                  <Route path={UNKNOWN} element={<Navigate to={BASE_ROOT} />} />
                </>
              )}
              <Route path={AUTH}>
                <Route index element={<Navigate to={LOGIN} />} />
                {hasCookie === false && renderLoginRoutes()}
                {renderLogoutRoutes()}
              </Route> */}
              <Route path={BASE_ROOT}>
                <Route index element={<Navigate to={CHECKOUT} />} />
                {renderOneNokRoute()}
                {renderCheckoutRoutes()}
                <Route
                  element={
                    <DashboardLayout navItems={getNavItems()}>
                      <Outlet />
                    </DashboardLayout>
                  }
                >
                  {renderAccountRoutes()}
                  {renderKidsRoutes()}
                </Route>
              </Route>
            </Routes>
          </BrowserRouter>
        </ErrorBoundary>
      </Suspense>
    </AnimatePresence>
  )
}
