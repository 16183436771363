import { NavigationLocaleTypes } from '../locales.types'

const navigationLocaleNo: NavigationLocaleTypes = {
  mainMenu: {
    title: 'Hovudmeny',
    titleMobile: 'Meir'
  },
  account: {
    title: 'Account'
  },
  kids: {
    title: 'Kids'
  },
  menu: {
    title: 'Meny'
  },
  createAssignment: {
    title: {
      normal: 'Lag ei oppgåve',
      edit: 'Redigering av {{ name }} oppgave'
    },
    titleMobile: 'Matteoppdrag',
    subtitle:
      'Velg nye oppdrag å legge til i oppgaven. Finn ut mer om hvert spill ved å klikke på <"Mer info"> på kortet. Du kan også begynne å lage en oppgave ved å velge spill nedenfor.',
    alternativeSubtitle: null
  },
  assignments: {
    title: 'Oppgåve',
    newTitle: 'Lag ei oppgåve',
    newSubtitle:
      'Vel ulike matteoppdrag og modusar som skal vere med i oppgåva. For å lære meir om kvart oppdrag, klikk på <More Info>.'
  },
  library: {
    title: 'Bibliotek',
    subTitle: 'Her kan du se våre tilgjengelige spill og lese mer om dem'
  },
  reports: {
    title: 'Rapportar'
  },
  offlineContent: {
    title: 'Ressursar',
    titleMobile: 'Ressursar'
  },
  students: {
    title: 'Elevar',
    newTitle: 'Mine elevar'
  },
  license: {
    title: 'Lisensar',
    tabs: {
      manage: {
        title: 'Administrer lisensar'
      },
      buy: {
        title: 'Bestill lisensar'
      }
    }
  },
  helpAndSupport: {
    title: 'Brukarstøtte'
  },
  settings: {
    title: 'Kontoinnstillingar'
  },
  customerOverview: {
    title: 'Kundeoversikt'
  },
  feedback: {
    title: 'Gi oss ris og ros',
    popover: {
      title: 'Hva synes du om Numetry Skole?',
      input: {
        placeholder: 'Utdyp gjerne'
      },
      moods: {
        bad: 'Dårlig',
        dissatisfied: 'Misfornøyd',
        neutral: 'Nøytral',
        satisfied: 'Fornøyd',
        verySatisfied: 'Veldig fornøyd'
      },
      button: {
        text: 'Send inn'
      }
    }
  }
}

export default navigationLocaleNo
