import React, { lazy } from 'react'
import { Route } from 'react-router-dom'
import { ROUTES } from 'router'
import {
  EditionContextWrapper,
  CreateStepContextProvider
} from 'views/kids/subViews'

const Kids = lazy(() => import('views/kids/Kids'))

export const renderKidsRoutes = () => {
  const { KIDS } = ROUTES
  return (
    <Route
      path={KIDS}
      element={
        <CreateStepContextProvider>
          <EditionContextWrapper>
            <Kids />
          </EditionContextWrapper>
        </CreateStepContextProvider>
      }
    />
  )
}
