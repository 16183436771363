import { Typography } from '@mui/material'
import { HighlightTextComponent } from 'components'
import React from 'react'
import { LayoutHeaderStyles } from './LayoutHeader.styles'
import { LayoutHeaderPropTypes } from './LayoutHeader.types'

export const LayoutHeaderComponent: React.FC<LayoutHeaderPropTypes> = ({
  title,
  subtitle
}) => {
  const markedText = subtitle?.substring(
    subtitle.indexOf('<') + 1,
    subtitle.lastIndexOf('>')
  )

  return (
    <LayoutHeaderStyles>
      <Typography component='h2'>{title}</Typography>
      {subtitle && (
        <Typography>
          {HighlightTextComponent({
            text: subtitle,
            highlight: markedText
          })}
        </Typography>
      )}
    </LayoutHeaderStyles>
  )
}

export * from './LayoutHeader.types'
