import { Radio, RadioProps } from '@mui/material'
import React from 'react'
import { PaymentMethodStyles } from './Payment.styles'

export const PaymentMethodComponent: React.FC<RadioProps> = ({
  children,
  ...rest
}) => (
  <PaymentMethodStyles onClick={rest.onClick}>
    <Radio color='primary' {...rest} />
    {children}
  </PaymentMethodStyles>
)
