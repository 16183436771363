import { AccountPageContextWrapper } from 'views/account/Account'
import React, { lazy } from 'react'
import { Route } from 'react-router-dom'
import { ContextRoute, ROUTES } from 'router'

const Account = lazy(() => import('views/account/Account'))

export const renderAccountRoutes = () => {
  const { ACCOUNT } = ROUTES
  return (
    <Route
      path={ACCOUNT}
      element={<ContextRoute wrapper={AccountPageContextWrapper} />}
    >
      <Route index element={<Account />} />
    </Route>
  )
}
