import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { withProfiler } from '@sentry/react'
import { CookiesProvider } from 'react-cookie'
import { SnackbarProvider } from 'notistack'
import { MotionConfig } from 'framer-motion'

import 'i18n/i18n'

import { Router } from 'router/Router'

import { GlobalContextWrapper } from 'context'
import { MuiThemeWrapper, GlobalStyles } from '@eduplaytion/numetry-ui-kit'

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false, retry: false } }
})

const App: React.FC = () => {
  return (
    <React.StrictMode>
      <CookiesProvider>
        <QueryClientProvider client={queryClient}>
          <SnackbarProvider
            maxSnack={5}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            autoHideDuration={6000}
          >
            <MotionConfig
              transition={{
                type: 'spring',
                damping: 10,
                mass: 0.75,
                stiffness: 100,
                duration: 0.35
              }}
            >
              <GlobalContextWrapper>
                <MuiThemeWrapper>
                  <>
                    <GlobalStyles />
                    <Router />
                  </>
                </MuiThemeWrapper>
              </GlobalContextWrapper>
            </MotionConfig>
          </SnackbarProvider>
        </QueryClientProvider>
      </CookiesProvider>
    </React.StrictMode>
  )
}

export default withProfiler(App)
