import React from 'react'
import { css, CssBaseline } from '@mui/material'
import { createTheme, ThemeOptions, ThemeProvider } from '@mui/material/styles'
import { FCWithChildren } from '@types'
import { COLORS } from '../tools/colors'

export const OldMuiTheme: ThemeOptions = {
  palette: {
    primary: {
      main: COLORS.primary,
      light: COLORS.primaryLight,
      dark: COLORS.primaryDark
    },
    secondary: {
      main: COLORS.secondary,
      light: COLORS.secondaryLight,
      dark: COLORS.secondaryDark
    },
    info: {
      main: COLORS.info,
      light: COLORS.infoLight,
      dark: COLORS.infoDark
    },
    success: {
      main: COLORS.success,
      light: COLORS.successLight,
      dark: COLORS.successDark
    },
    warning: {
      main: COLORS.warning,
      light: COLORS.warningLight,
      dark: COLORS.warningDark
    },
    error: {
      main: COLORS.danger,
      light: COLORS.dangerLight,
      dark: COLORS.dangerDark
    }
  },
  typography: {
    fontFamily: ['Muli', 'Rajdhani', 'Venera', 'sans-serif'].join(','),
    htmlFontSize: 10
  },
  components: {
    MuiPaper: {
      defaultProps: {
        style: {
          boxShadow: `0 0.6rem 2rem ${COLORS.black}15`
        }
      }
    },
    MuiSelect: {
      defaultProps: {
        MenuProps: {
          PaperProps: {
            style: {
              boxShadow: `0 0.6rem 2rem ${COLORS.black}15`,
              marginTop: 1
            }
          }
        }
      }
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true
      },
      styleOverrides: {
        root: {
          color: COLORS.dark,
          boxShadow: 'none',
          textTransform: 'none',
          paddingLeft: '2.4rem',
          paddingRight: '2.4rem',
          position: 'relative',
          ':hover': css`
            box-shadow: none;
          `
        }
      },
      variants: [
        {
          props: { variant: 'outlined' },
          style: { color: COLORS.dark }
        },
        {
          props: {
            variant: 'outlined',
            color: 'primary'
          },
          style: {
            color: COLORS.secondary,
            ':hover': css`
              color: ${COLORS.white};
              background-color: ${COLORS.secondary};
            `,
            ':active': css`
              color: ${COLORS.white};
              background-color: ${COLORS.secondary};
            `
          }
        },
        {
          props: {
            variant: 'outlined',
            color: 'secondary'
          },
          style: {
            color: COLORS.greyishBlue,
            ':hover': css`
              color: ${COLORS.white};
              background-color: ${COLORS.secondaryLight};
            `,
            ':active': css`
              color: ${COLORS.white};
              background-color: ${COLORS.secondaryDark};
            `
          }
        },
        {
          props: { variant: 'text' },
          style: {
            ':hover': css`
              color: ${COLORS.white};
            `
          }
        },
        {
          props: { variant: 'text', color: 'info' },
          style: {
            color: COLORS.info
          }
        },
        {
          props: { variant: 'text', color: 'error' },
          style: {
            color: COLORS.danger
          }
        },
        {
          props: { variant: 'contained', color: 'primary' },
          style: {
            color: COLORS.white
          }
        },
        {
          props: { variant: 'contained', color: 'info' },
          style: {
            color: COLORS.white
          }
        }
      ]
    }
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1200,
      xl: 1440
    }
  }
}

export const OldMuiThemeWrapper: FCWithChildren = ({ children }) => {
  const theme = createTheme(OldMuiTheme)

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}
