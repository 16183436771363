import { motion } from 'framer-motion'
import styled from 'styled-components'
import { SecretDisplayStylesPropTypes } from './SecretDisplay.types'

export const SecretDisplayStyles = styled(
  motion.div
)<SecretDisplayStylesPropTypes>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .MuiIconButton-root {
    margin-left: 0.25rem;
  }

  p,
  span {
    font-weight: ${({ theme }) => theme.typography.fontWeightExtraBold};
  }
  p {
    visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  }
  span {
    position: absolute;
    font-size: ${({ theme }) => theme.typography.body1.fontSize};
    visibility: ${({ isVisible }) => (!isVisible ? 'visible' : 'hidden')};
  }
  svg {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`
