import { useEffect, useState } from 'react'
import { useWindowSize } from '..'

const getDeviceFromUserAgent = () =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )

export const useDevice = () => {
  const initialIsMobileDevice = getDeviceFromUserAgent()
  const [isMobileDevice, setIsMobileDevice] = useState(initialIsMobileDevice)

  const windowSize = useWindowSize()

  const isIpad =
    /Macintosh/i.test(navigator.userAgent) &&
    navigator.maxTouchPoints &&
    navigator.maxTouchPoints > 1

  useEffect(() => {
    if (isIpad) {
      setIsMobileDevice(true)
    } else {
      setIsMobileDevice(getDeviceFromUserAgent())
    }
  }, [windowSize.width, isIpad])

  return { isMobileDevice, isDesktopDevice: !isMobileDevice }
}
