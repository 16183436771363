import React from 'react'

import { FCWithChildren } from '@types'
import { BottomNavigationPropTypes } from './BottomNavigation.types'
import { BottomNavigationStyles } from './BottomNavigation.styles'
import { BottomNavigationItemComponent } from './components'

export const BottomNavigationComponent: FCWithChildren<
  BottomNavigationPropTypes
> = ({ items }) => {
  const filteredItems = items.filter(
    (item) => !item.hideElement && item.showInBottomNavigation
  )

  return (
    <BottomNavigationStyles>
      {filteredItems.map((item) => (
        <BottomNavigationItemComponent key={item.id} item={item} />
      ))}
    </BottomNavigationStyles>
  )
}
