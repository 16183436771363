import { QueryObserverOptions, useQuery } from 'react-query'
import { AxiosError } from 'axios'
import { useFeedback } from '@eduplaytion/numetry-ui-kit'

import { useParamsSelector } from 'hooks'
import {
  ApiResponseTypes,
  SubscriptionPlanServices,
  SubscriptionPlanTypes
} from 'services'
import { UseQueryOptionsTypes } from '../queries.types'

export const useSubscriptionPlansQuery = (
  options: UseQueryOptionsTypes &
    QueryObserverOptions<SubscriptionPlanTypes[]> = {}
) => {
  const { useParams, promotionCode } = options
  const params = useParamsSelector()

  const queryParams = useParams ? params.objParams : {}

  const { alert } = useFeedback()

  const { data, ...query } = useQuery(
    ['/subscriptionPlans', queryParams, promotionCode],
    () =>
      SubscriptionPlanServices.promotionCode.id.get({
        params: {
          currency: queryParams?.currency,
          language: queryParams?.language,
          promotionCode: promotionCode?.toLowerCase() || 'none'
        }
      }),
    {
      onError(error: AxiosError<ApiResponseTypes<unknown>>) {
        alert.showError(error.response?.data?.message || error.message)
      },
      onSuccess: options.onSuccess
    }
  )

  return {
    data: data || [],
    ...query
  }
}
