import { useQuery } from 'react-query'
import { getVerifyFreeTrial } from 'services'

export const useVerifyFreeTrial = (parentEmail: string) => {
  const verifyFreeTrial = useQuery(['/verifyFreeTrial', parentEmail], () =>
    getVerifyFreeTrial(parentEmail)
  )

  return verifyFreeTrial
}
