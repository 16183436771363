import React from 'react'
import { useTheme } from '@mui/material'
import { ThemeProvider } from 'styled-components'

import { FCWithChildren } from '@types'

export const StyledThemeWrapper: FCWithChildren = ({ children }) => {
  const theme = useTheme()

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}
