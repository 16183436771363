import { Theme } from '@mui/material'

export const palette: Theme['palette'] = {
  common: {
    white: '#FFFFFF',
    black: '#000000'
  },
  primary: {
    50: '#EDE2F7',
    100: '#DCC6F0',
    200: '#CBAAE9',
    300: '#B98DE2',
    400: '#A871DA',
    light: '#8538CC',
    600: '#6B23BE',
    main: '#6300BE',
    800: '#5800A8',
    dark: '#4D0093',
    contrastText: '#FFFFFF'
  },
  secondary: {
    A100: '#F5F9FE',
    A200: '#F8F8FE',
    A400: '#DDEBFD',
    A700: '#CADDF5',
    50: '#E5F0FB',
    100: '#CBE1F8',
    200: '#B1D2F4',
    300: '#97C3F1',
    400: '#63A5EA',
    light: '#4996E6',
    600: '#2F87E3',
    main: '#1373D6',
    800: '#136BC7',
    dark: '#115EAE',
    contrastText: '#FFFFFF'
  },
  success: {
    50: '#C2FFBE',
    100: '#9FEE98',
    200: '#8CDD85',
    300: '#79CD72',
    400: '#66BC5F',
    light: '#54AB4C',
    600: '#419B39',
    main: '#2E8A26',
    800: '#1B7913',
    dark: '#096900',
    contrastText: '#FFFFFF'
  },
  error: {
    50: '#FFD7DF',
    100: '#FFC3CF',
    200: '#FFAFBF',
    300: '#FF9CAF',
    400: '#FF889F',
    light: '#FF748F',
    600: '#FF607F',
    main: '#FF4D6F',
    800: '#E24462',
    dark: '#C63B56',
    contrastText: '#FFFFFF'
  },
  warning: {
    50: '#FDEEE2',
    100: '#FBDEC6',
    200: '#F9CEAA',
    300: '#F5AD72',
    400: '#F39D56',
    light: '#FF9800',
    600: '#EF7C1E',
    main: '#ED6C02',
    800: '#E95D00',
    dark: '#E65100',
    contrastText: '#FFFFFF'
  },
  text: {
    primary: '#282828',
    secondary: '#717171',
    disabled: '#8D8D8D'
  },
  grey: {
    A100: '#FEFEFD',
    A200: '#F8F7F5',
    A400: '#F5F5F5',
    A700: '#DDDDDD',
    50: '#E2E2E2',
    100: '#E2E2E2',
    200: '#C6C6C6',
    300: '#AAAAAA',
    400: '#8D8D8D',
    600: '#555555',
    800: '#1C1C1C',
    light: '#717171',
    main: '#383838',
    dark: '#000000',
    contrastText: '#FFFFFF'
  },
  yellow: {
    50: '#fefee8',
    100: '#ffffc6',
    200: '#fffb88',
    300: '#fff244',
    400: '#fee211',
    500: '#eec904',
    600: '#cd9c01',
    700: '#a47004',
    800: '#87570c',
    900: '#734710',
    main: '#fee211',
    dark: '#734710',
    light: '#fffb88',
    A100: '#ecaa04',
    A200: '#cb8801',
    A400: '#a46704',
    A700: '#a46704',
    contrastText: '#FFFFFF'
  },
  royalBlue: {
    A100: '#787cff',
    A200: '#575cff',
    A400: '#363cff',
    A700: '#141cff',
    50: '#0008f2',
    100: '#0007d1',
    200: '#0006b0',
    300: '#00058f',
    400: '#00046e',
    dark: '#00034f'
  },
  info: {
    contrastText: '#FFFFFF',
    A100: '#f4faff',
    A200: '#cce5ff',
    A400: '#7abdff',
    A700: '#51a8ff',
    50: '#2994ff',
    100: '#007fff',
    200: '#0773e0',
    300: '#0d67c2',
    400: '#115ba6',
    500: '#134f8b',
    600: '#154372',
    700: '#14375a',
    800: '#132b44',
    main: '#007FFF',
    light: '#8CC6FF',
    dark: '#0060BF'
  },
  background: {
    default: '#F8F7F5',
    paper: '#FFFFFF'
  }
}
