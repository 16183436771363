import { GeneralLocaleTypes } from '../locales.types'

const generalLocaleBo: GeneralLocaleTypes = {
  minute: 'minutt',
  minutes: 'minutter',
  min: 'min',
  showingOf: 'Viser {{ visibleRows }} av {{ totalDataRows }}',
  showLess: 'Vis mindre',
  showMore: 'Vis mer',
  fileSelect: 'Velg en fil',
  userNotFound: 'Bruker ikke funnet',
  noMoreResults: 'Ingen flere resultater',
  teacher: 'Lærer',
  feideFetchStatus: {
    title: 'Feide-synkronisering...',
    fetching: 'Oppdaterer database med Feide-informasjon',
    error: 'Det oppstod en feil under forsøk på å hente informasjon fra feide',
    success: 'Studentoppdatering fra Feide var vellykket'
  },
  filters: {
    text: {
      filterBy: 'Filtrer etter:',
      filterResults: 'Filtrerings resultater',
      clearFilters: 'Fjern filtre',
      selectItem: 'Velg et {{ topic }}'
    },
    status: {
      title: 'Status',
      item: {
        allAssignments: 'alle oppgaver',
        progress: 'aktiv',
        upcoming: 'planlagt',
        finished: 'inaktiv'
      }
    },
    topics: {
      title: 'Temaer'
    },
    assigned: {
      title: 'Tildelt',
      item: {
        students: 'elever',
        groups: 'grupper'
      }
    },
    planets: {
      title: 'Planeter'
    },
    skills: {
      title: 'Ferdigheter'
    }
  },
  imageAlt: {
    login: {
      gameCharacters: 'Spillkarakterer'
    }
  },
  status: {
    error: {
      somethingWentWrong: 'Noe gikk galt',
      required: 'Denne oppføringen er obligatorisk',
      shouldBeAnEmail: 'Skal be om en e-post',
      emailDoesntMatch: 'E-post stemmer ikke',
      passwordDoesntMatch: 'Passordet stemmer ikke',
      notFound: {
        title: 'Oops! <br/> Vi finner ikke siden du leter etter.',
        description:
          'Dette kan skyldes at siden ikke finnes lenger, eller at innholdet har blitt flyttet. <customLink>Gå til forsiden</customLink> eller velg en annen side fra hovedmenyen.'
      },
      cannotUndoThisAction:
        'Du kan <strong>ikke</strong> angre denne handlingen.'
    }
  },
  state: {
    nrOfModesSelected_0: 'Ingen moduser valgt',
    nrOfModesSelected: '{{ count }} Modus valgt',
    nrOfModesSelected_plural: '{{ count }} moduser valgt',
    selected: 'Valgt',
    notSelected: 'Ikke valgt',
    selectedNumber: '{{ selectedNumber }} valgt',
    removed: 'Fjernet',
    deleted: 'Slettet',
    modified: 'Modifisert',
    started: 'Påbegynt',
    ended: 'Avsluttet',
    comingSoon: 'Kommer snart',
    quitEditing: 'Forlat siden?',
    searchResultsFor: 'Søkeresultater for {{ value }}',
    loading: 'Laster...',
    studentAdded: 'Elev lagt til',
    ok: 'Ok'
  },
  selectAll: 'Velg alle',
  actions: {
    addAndClose: 'Legg til og lukk',
    addAndContinue: 'Legg til og fortsett',
    addNewTopic: 'Legg til et oppdrag',
    edit: 'Rediger',
    delete: 'Slett',
    remove: 'Fjern',
    send: 'Sende',
    next: 'Neste',
    back: 'Tilbake',
    save: 'Lagre {{ addOn }}',
    update: 'Oppdater {{ addOn }}',
    select: 'Velg',
    confirm: 'Bekreft',
    cancel: 'Avbryt',
    report: 'Se rapport',
    editParentsName: 'Rediger foresattes navn',
    moveToGroup: 'Flytt til en gruppe',
    revoke: 'Fjern',
    moreOptions: 'Flere valg',
    saveCustomer: 'Redd kunde',
    close: 'Lukk',
    return: 'Gå tilbake',
    submit: 'Send inn',
    add: 'Legg til',
    view: 'Åpne',
    createGroup: 'Lag en gruppe',
    saveGroup: 'Lagre gruppe',
    saveChanges: 'Lagre endringer',
    goToArticle: 'Gå til artikkelen',
    keepEditing: 'Rediger videre',
    quit: 'Ja, forlat',
    show: 'Vis',
    hide: 'Skjul',
    unHide: 'Gjenopprett',
    previous: 'Førre'
  },
  user: {
    accountSettings: 'Kontoinnstillinger'
  },
  errors: {
    assignmentTitleIsRequired: 'Vennligst skriv inn et oppgavenavn',
    invalidDate: 'Ugyldig dato',
    invalidTime: 'Ugyldig tid',
    shouldNotBeEmpty: 'Skal ikke være tom',
    shouldNotBeEmptyAddon: '{{ key }} skal ikke være tom',
    unexpectedError: 'Uventet feil',
    notSaved: 'Dine endringer vil <strong>ikke</strong> bli lagret.'
  },
  loadMoreResults: 'Last inn flere resultater',
  entry_one: {
    one: 'Entry',
    other: 'Entries'
  },
  validation: {
    required: 'Dette er et obligatorisk felt'
  }
}

export default generalLocaleBo
