import { ButtonBase } from '@mui/material'
import styled, { css } from 'styled-components'

export const BottomNavigationItemStyles = styled(ButtonBase)<{
  isActive?: boolean
}>`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  align-items: center;
  text-align: center;
  padding: 1.2rem;
  width: 100%;

  color: ${({ theme }) => theme.palette.text.primary};

  ${({ isActive }) =>
    isActive &&
    css`
      color: ${({ theme }) => theme.palette.primary.main};
    `}
`
