import { RefObject, useEffect, useState } from 'react'

interface useIntersectionObserverPropTypes extends IntersectionObserverInit {
  freezeOnceVisible?: boolean
}

export const useIntersectionObserver = <T extends Element = Element>(
  elementRef: RefObject<T>,
  {
    threshold = 0,
    root = null,
    rootMargin = '0%'
  }: useIntersectionObserverPropTypes
) => {
  const [entry, setEntry] = useState<IntersectionObserverEntry>()

  const updateEntry = ([entryKey]: IntersectionObserverEntry[]): void => {
    setEntry(entryKey)
  }

  useEffect(() => {
    const node = elementRef?.current
    const hasIOSupport = !!window.IntersectionObserver

    if (!hasIOSupport || !node) return () => null

    const observerParams = { threshold, root, rootMargin }
    const observer = new IntersectionObserver(updateEntry, observerParams)

    observer.observe(node)

    return () => observer.disconnect()
  }, [elementRef, JSON.stringify(threshold), root, rootMargin])

  return entry
}

export default useIntersectionObserver
