import { apiParent } from 'services'
import { FeedbackPayloadTypes } from './Feedback.types'

export const postParentFeedback = async (payload: FeedbackPayloadTypes) => {
  const { data: response } = await apiParent.post(
    `/v2/parent/parentFeedback`,
    payload
  )

  return response.data
}

export default {
  post: postParentFeedback
}
