import styled from 'styled-components'

export const AsideStyles = styled.aside`
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 100%;

  position: fixed;
  z-index: ${({ theme }) => theme.zIndex.speedDial};

  height: -webkit-fill-available;
  background-color: ${({ theme }) => theme.palette.common.white};

  overflow: auto;

  transition: all 0.2s ease-in-out;
`
