import { RefObject, useEffect, useState } from 'react'

export const useFocus = <T extends HTMLElement>(ref: RefObject<T>) => {
  const [isElementFocused, setIsElementFocused] = useState(false)

  const node = ref.current
  const hasNode = node && 'addEventListener' in node

  const onFocus = () => setIsElementFocused(true)
  const onBlur = () => setIsElementFocused(false)

  useEffect(() => {
    if (hasNode) {
      node.addEventListener('focus', onFocus)
      node.addEventListener('blur', onBlur)

      return () => {
        node.removeEventListener('focus', onFocus)
        node.removeEventListener('blur', onBlur)
      }
    }

    return undefined
  }, [node])

  useEffect(() => {
    if (
      hasNode &&
      document.hasFocus() &&
      node.contains(document.activeElement)
    ) {
      setIsElementFocused(true)
    }
  }, [isElementFocused])

  return { isElementFocused }
}
