import { LinearProgress } from '@mui/material'
import styled from 'styled-components'
import { ProgressPropTypes } from './Progress.types'

export const ProgressWrapperStyles = styled.div`
  width: 100%;
  height: 100%;
  max-width: 11rem;

  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const ProgressStyles = styled(LinearProgress)`
  height: 50%;
  min-height: 2.5rem;
  width: 100%;
  border-radius: 100rem;

  background-color: ${({ theme }) => theme.palette.royalBlue.light};
  outline: 0.1rem solid ${({ theme }) => theme.palette.royalBlue.light};

  .MuiLinearProgress-bar {
    border-radius: 100rem;
    background-color: ${({ theme }) => theme.palette.info.light};

    ${({ value, theme }) =>
      value === 100 &&
      `
        border: 0.1rem solid ${theme.palette.info.main};
    `}
  }
`

export const ProgressLabelStyles = styled.div<ProgressPropTypes>`
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  p {
    color: ${({ theme }) => theme.palette.royalBlue.dark};
    font-weight: ${({ theme }) => theme.typography.fontWeightBold};
    font-size: ${({ theme }) => theme.typography.body1.fontSize};
    ${({ value, theme }) =>
      value === 100 && `color: ${theme.palette.info.main}`};
  }
`
