import { from } from '@theme'
import styled from 'styled-components'

export const EmptyContainerStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 2rem;
  flex: 1;
  align-self: center;
`

export const EmptyContentStyles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  align-items: center;
  width: 100%;

  div {
    text-align: center;
    justify-content: space-between;
  }

  svg {
    max-height: 12rem;
    max-width: 12rem;
  }

  ${from.lg} {
    svg {
      max-height: 22rem;
      max-width: 22rem;
    }
  }
`
