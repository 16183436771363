import styled from 'styled-components'
import { from } from '../../../theme'

export const LayoutStyles = styled.div`
  display: flex;
  flex-direction: column;
`

export const LayoutContentStyles = styled.div`
  position: relative;
  padding-top: 8.1rem;

  display: flex;
  flex-direction: column;

  margin-bottom: 7.1rem;

  ${from.md} {
    margin-bottom: 0;
  }
`

export const LayoutMainStyles = styled.main`
  display: flex;

  width: 100%;

  ${from.md} {
    padding-left: 6.7rem;
  }
`
