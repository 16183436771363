import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

// TODO: add i18n
export const EditionFormSchema = () => {
  const { t } = useTranslation()

  return useMemo(
    () =>
      yup.object({
        username: yup.string(),
        currentPassword: yup.string().when('newPassword', {
          is: true,
          then: yup.string().notRequired(),
          otherwise: yup.string().required()
        }),
        newPassword: yup.string(),
        passwordConfirmation: yup.string().when('newPassword', {
          is: true,
          then: yup.string().notRequired(),
          otherwise: yup
            .string()
            .oneOf(
              [yup.ref('newPassword')],
              t('general.status.error.passwordDoesntMatch')
            )
            .required(t('general.status.error.required'))
        })
      }),
    [t]
  )
}
