import { useFormik } from 'formik'
import React, { createContext, useContext, useMemo, useState } from 'react'
import { EditionFormSchema } from './EditionModal.context.scheme'
import {
  EditFormTypes,
  EditionModalContextTypes
} from './EditionModal.context.types'

const EditionModalContext = createContext({} as EditionModalContextTypes)

export const useEdition = () => useContext(EditionModalContext)

export const EditionContextWrapper: React.FC = ({ children }) => {
  const formik = useFormik({
    initialValues: {} as EditFormTypes,
    onSubmit: (values) => console.log(values),
    validationSchema: EditionFormSchema(),
    validateOnChange: true
  })

  const [current, setCurrentView] = useState<'info' | 'password'>('info')

  const ctxValue: EditionModalContextTypes = useMemo(
    () => ({
      formik,
      current,
      setCurrentView
    }),
    [current, setCurrentView, formik]
  )
  return (
    <EditionModalContext.Provider value={ctxValue}>
      {children}
    </EditionModalContext.Provider>
  )
}
