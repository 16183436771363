import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { LayoutHeaderComponent } from './components'

import { LayoutStyles } from './Layout.styles'
import { LayoutPropTypes } from './Layout.types'

export const LayoutComponent: React.FC<LayoutPropTypes> = ({
  children,
  header,
  unstyled
}) => {
  const location = useLocation()

  useEffect(() => {
    const concatenatedTitle = header?.title ? `- ${header.title}` : ''
    document.title = `Teacher Panel ${concatenatedTitle}`
  }, [location])

  if (unstyled) return <LayoutStyles unstyled>{children}</LayoutStyles>

  return (
    <LayoutStyles>
      {header && <LayoutHeaderComponent {...header} />}
      {children}
    </LayoutStyles>
  )
}

export * from './Layout.types'
