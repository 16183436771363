import { from } from '@eduplaytion/numetry-ui-kit'
import { Grid } from '@mui/material'
import styled, { css } from 'styled-components'
import {
  FormGroupItemBodyStylesPropTypes,
  FormGroupItemStylesPropTypes,
  FormGroupItemTitleStylesPropTypes
} from './FormGroup.types'

export const FormGroupItemStyles = styled(Grid)<FormGroupItemStylesPropTypes>`
  display: flex;
  justify-content: space-between;

  flex-wrap: wrap;
  padding: ${({ padding }) => padding || '1.8'}rem;
  border-bottom: 0.1rem solid
    ${({ noDivisor, divisorColor, theme }) => {
      if (noDivisor) {
        return 'transparent'
      }

      if (divisorColor) {
        return divisorColor.toString()
      }

      return theme.palette.grey.A700
    }};

  ${({ alignItems }) =>
    alignItems === 'start' &&
    css`
      align-items: flex-start;
    `}

  ${({ autoGrow }) =>
    autoGrow &&
    css`
      flex: 1;
    `}


  ${from.lg} {
    ${({ flexWrap }) => !flexWrap && 'flex-wrap: nowrap;'}
  }
`
export const FormGroupItemTitleStyles = styled(
  Grid
)<FormGroupItemTitleStylesPropTypes>`
  display: flex;
  align-items: center;
  height: max-content;
  margin-right: 1rem;
  margin-bottom: 1rem;
  position: relative;

  ${({ noMargin }) =>
    noMargin &&
    css`
      margin: 0;
    `}

  ${({ variant }) => {
    if (variant === 'column') {
      return css`
        p {
          color: ${({ theme }) => theme.palette.royalBlue.dark};
          font-size: ${({ theme }) => theme.typography.subtitle2.fontSize};
        }
      `
    }

    return css`
      p {
        color: ${({ theme }) => theme.palette.common.black};
        font-size: ${({ theme }) => theme.typography.body1.fontSize};
      }
    `
  }}
  flex-wrap: wrap;

  ${from.lg} {
    margin-bottom: 0;
  }
  svg {
    margin-right: 1rem;
    width: ${({ iconSize }) => iconSize || 2.4}rem;
    height: ${({ iconSize }) => iconSize || 2.4}rem;
  }
`

export const FormGroupItemBodyStyles = styled(
  Grid
)<FormGroupItemBodyStylesPropTypes>`
  ${({ alignContent }) =>
    alignContent &&
    `
    text-align: ${alignContent};
    `}

  .MuiFormControl-root {
    flex: 1;
    width: 100%;
    max-width: 100;
  }
`

export const FormContainerStyles = styled.div`
  background-color: ${({ theme }) => theme.palette.common.white};
  transition: all 0.25s ease;

  .MuiButtonBase-root div {
    p {
      font-size: ${({ theme }) => theme.typography.subtitle1.fontSize};
      font-weight: ${({ theme }) => theme.typography.fontWeightBold};
    }
    :first-of-type {
      p {
        text-align: left;
        color: ${({ theme }) => theme.palette.primary.main};
      }
    }
    :last-of-type {
      p {
        text-align: right;
        color: ${({ theme }) => theme.palette.info.main};
      }
    }
  }
`

export const FormGroupContentStyles = styled.div<{
  placing?: 'start' | 'end'
  absolute?: boolean
}>`
  display: flex;
  width: 100%;
  justify-content: ${({ placing }) => `flex-${placing}`};

  position: ${({ absolute }) => (absolute ? 'absolute' : 'unset')};
`

export const IconHolderStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    height: 3.5rem;
    width: 3.5rem;
  }
`
