import React, { useEffect } from 'react'
import { Stack } from '@mui/material'
import { useStepper } from '@eduplaytion/numetry-ui-kit'
import { ItemComponent } from './components/item/Item'
import { useCreateStep } from '../../context/CreateStep.context'

export const CreateStepComponent = () => {
  const { formik } = useCreateStep()
  const { setBlock } = useStepper()

  useEffect(() => {
    if (formik.values.type !== undefined) {
      setBlock(true)
    }
  }, [formik.values.type])

  return (
    <Stack spacing={2}>
      <ItemComponent
        checked={formik.values.type === 'create'}
        onClick={() => formik.setFieldValue('type', 'create')}
        text='Create a new user and buy a subscription for your child.'
      />
      <ItemComponent
        checked={formik.values.type === 'link'}
        onClick={() => formik.setFieldValue('type', 'link')}
        text='Connect an existing game user to a subscription.'
      />
    </Stack>
  )
}
