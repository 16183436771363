import { Icon, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import {
  FormGroupItemBodyStyles,
  FormGroupItemTitleStyles,
  FormGroupContentStyles,
  IconHolderStyles
} from '../FormGroup.styles'
import { FormGroupItemPropTypes } from '../FormGroup.types'

export const ColumnTemplateComponent: React.FC<FormGroupItemPropTypes> = ({
  iconSize,
  icon,
  title,
  content,
  rightAdornment = null
}) => {
  const hasRightElement = Boolean(rightAdornment)

  return (
    <FormGroupItemTitleStyles
      item
      className='ItemTitle-root'
      iconSize={iconSize}
      variant='column'
      noMargin={!hasRightElement}
      lg
      xs
    >
      <IconHolderStyles>
        {icon && <Icon color='primary' component={icon} />}
      </IconHolderStyles>
      <Box flexDirection='row' width={hasRightElement ? 'unset' : '100%'}>
        {(icon || title) && (
          <Box display='flex' flexDirection='row'>
            {title && <Typography>{title}</Typography>}
          </Box>
        )}
        <FormGroupItemBodyStyles item xs={12} lg={12}>
          <FormGroupContentStyles>
            <FormGroupContentStyles placing='start'>
              {content}
            </FormGroupContentStyles>
          </FormGroupContentStyles>
        </FormGroupItemBodyStyles>
      </Box>
      {hasRightElement && (
        <FormGroupContentStyles absolute placing='end'>
          {rightAdornment}
        </FormGroupContentStyles>
      )}
    </FormGroupItemTitleStyles>
  )
}
